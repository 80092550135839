
import React, { useEffect, useRef, useState } from "react";
import Loader from "../assets/loader-animation.gif";
import Foff from "../assets/FoF-Logo.png";
import * as PIXI from "pixi.js"
import { gsap } from 'gsap';

import { toast, ToastContainer } from "react-toastify";
import {
  Application,
  Assets,
  Color,
  Container,
  Texture,
  Sprite,
  Graphics,
  Text,
  TextStyle,
  BlurFilter,
  FillGradient,
  Rectangle,
  AnimatedSprite,
  BitmapText,
} from "pixi.js";
import axios from "axios";
import GameHistoryTable from "../Pages/GameHistoryTable";
import SliderItems from "./Payout Table Slider/SliderItems";
import SessionExpired from "./SessionExpired";
import { useNavigate } from "react-router-dom";
import LoadFile from "./LoadFile.json"
// import * as TWEEN from '@tweenjs/tween.js'
import Preloader from "./Preloader";
import Swal from "sweetalert2";

const Slot = () => {
  const pixiContainer = useRef(null);
  const [isHtmlVisible, setHtmlVisible] = useState(false);
  const [isPayoutTable, setPayoutTable] = useState(false);
  const [isSessionExpired, setSessionExpired] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isStickySpinn, setStickySpinn] = useState(false);

  const navigate = useNavigate();
  const playerId = localStorage.getItem("playerId");
  const token = localStorage.getItem("token");
  const MinorContainerSticky = new Container();
  const MajorContainerSticky = new Container();
  const GrandContainerSticky = new Container();
  const MiniContainerSticky = new Container();

  useEffect(() => {


    (async () => {

      // Create a new application
      const app = new Application();

      // Initialize the application
      await app.init({ resizeTo: window });

      // Append the application canvas to the document body
      document.body.appendChild(app.canvas);

      // Load the textures
      await Assets.load(LoadFile.loadImages);



      Assets.addBundle('fonts', LoadFile.textStyle);
      await Assets.loadBundle('fonts').then(() => {
        // console.log('font Loaded');
      });

      var size = [window.innerWidth, window.innerHeight];
      var ratio = size[0] / size[1];
      let freeSpinCredits;
      let X1percent = size[0] / 100;
      let Y1percent = size[1] / 100;
      let freeSpinType = false;
      let freespinWinvalue = 0;
      let checkFIreIcon = 0;
      let count = 0;
      let stickySpinindex = [];
      let IsstickySpin = false;
      let linkPrizeAmount;
      let countTTSym;
      var subWinTextvalue;
      var testKeyVar;
      let IsspinReelAnimation = false;

      // let stickyCounter = 0
      let strickfordoblecallFunction = false;
      let pos = [
        { x: X1percent * 24, y: Y1percent * 26.5 },
        { x: X1percent * 24, y: Y1percent * 47 },
        { x: X1percent * 24, y: Y1percent * 67.5 },
        { x: X1percent * 36.5, y: Y1percent * 26.5 },
        { x: X1percent * 36.5, y: Y1percent * 47 },
        { x: X1percent * 36.5, y: Y1percent * 67.5 },
        { x: X1percent * 49, y: Y1percent * 26.5 },
        { x: X1percent * 49, y: Y1percent * 47 },
        { x: X1percent * 49, y: Y1percent * 67.5 },
        { x: X1percent * 61.5, y: Y1percent * 26.5 },
        { x: X1percent * 61.5, y: Y1percent * 47 },
        { x: X1percent * 61.5, y: Y1percent * 67.5 },
        { x: X1percent * 74, y: Y1percent * 26.5 },
        { x: X1percent * 74, y: Y1percent * 47 },
        { x: X1percent * 74, y: Y1percent * 67.5 },

      ]

      let stickybackground = new PIXI.Container();
      let reelIndex;
      let slotIndex;
      let IsStickeySpinWon = false;
      var StickeySpinWon;
      let remaningStickeySpin;
      let spaceFilledWtihFire;

      const REEL_WIDTH = app.screen.width / 8;
      const SYMBOL_SIZE = app.screen.width / 9;




      const stickyContainer = new Container();




      //loading Symbol List
      const slotTextures = [];
      const symbolList = LoadFile?.symbolsList;
      for (let i = 0; i < symbolList?.length; i++) {
        slotTextures.push(Texture.from(symbolList[i]));
      }

      slotTextures[0].name = "HH";
      slotTextures[1].name = "KK";
      slotTextures[2].name = "II";
      slotTextures[3].name = "FF";
      slotTextures[4].name = "WW";
      slotTextures[5].name = "CC";
      slotTextures[6].name = "DD";
      slotTextures[7].name = "GG";
      slotTextures[8].name = "SS";
      slotTextures[9].name = "JJ";
      slotTextures[10].name = "EE";
      slotTextures[11].name = "TT";


      let slotSpriteSheet = LoadFile?.slotSpriteSheet


      const linkHitSheet = await Assets.load("/assets/Cortinilla_LinkBeat.json");


      const sheetSheet = await Assets.load("/assets/SpriteSheet.json");
      const sheetSheetoverlay = await Assets.load("/assets/SpriteSheet2.json");
      const sheetSheet2 = await Assets.load("/assets/SunLoop_Sheet02.json");


      const spriteA00 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB00 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay00 = new AnimatedSprite(sheetSheetoverlay.animations.enemy);
      const spriteA01 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB01 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay01 = new AnimatedSprite(sheetSheetoverlay.animations.enemy);
      const spriteA02 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB02 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay02 = new AnimatedSprite(sheetSheetoverlay.animations.enemy);


      const spriteA10 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteAOverlay10 = new AnimatedSprite(sheetSheetoverlay.animations.enemy);
      const spriteB10 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteA11 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteAOverlay11 = new AnimatedSprite(sheetSheetoverlay.animations.enemy);
      const spriteB11 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteA12 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteAOverlay12 = new AnimatedSprite(sheetSheetoverlay.animations.enemy);
      const spriteB12 = new AnimatedSprite(sheetSheet2.animations.enemy);

      const spriteA20 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB20 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay20 = new AnimatedSprite(sheetSheetoverlay.animations.enemy);
      const spriteA21 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB21 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay21 = new AnimatedSprite(sheetSheetoverlay.animations.enemy);
      const spriteA22 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB22 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay22 = new AnimatedSprite(sheetSheetoverlay.animations.enemy);


      const spriteA30 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB30 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay30 = new AnimatedSprite(sheetSheetoverlay.animations.enemy);
      const spriteA31 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB31 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay31 = new AnimatedSprite(sheetSheetoverlay.animations.enemy);
      const spriteA32 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB32 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay32 = new AnimatedSprite(sheetSheetoverlay.animations.enemy);


      const spriteA40 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB40 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay40 = new AnimatedSprite(sheetSheetoverlay.animations.enemy);
      const spriteA41 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB41 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay41 = new AnimatedSprite(sheetSheetoverlay.animations.enemy);
      const spriteA42 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB42 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay42 = new AnimatedSprite(sheetSheetoverlay.animations.enemy);

      var sunFireDataObj;

      var SunFireText;
      var SunFireText1;
      var SunFireText2;
      var SunFireText3;
      var SunFireText4;
      var SunFireText5;
      var SunFireText6;
      var SunFireText7;
      var SunFireText8;
      var SunFireText9;
      var SunFireText10;
      var SunFireText11;
      var SunFireText12;
      var SunFireText13;
      var SunFireText14;

      function animateSpin(prizeData) {
        spriteB00.removeChild(SunFireText);
        SunFireText = new Text(`${prizeData}`, new TextStyle({ fontSize: 50, fill: 0xFFFFFF, fontFamily: "sans-serif", fontWeight: "bolder", }));

        spriteA00.anchor.set(0.5);
        spriteA00.animationSpeed = 1.5;
        spriteA00.loop = false;
        spriteA00.play();
        spriteB00.anchor.set(0.5);
        spriteB00.animationSpeed = 1.5;
        spriteB00.animationSpeed = 1.5;
        spriteA00.width = (window.innerWidth > 480) ? app.screen.width / 10 : X1percent * 12;
        spriteA00.height = (window.innerWidth > 480) ? app.screen.height / 5 : app.screen.height / 12;
        spriteB00.width = window.innerWidth > 480 ? app.screen.width / 4.45 : app.screen.width / 1;
        spriteB00.height = app.screen.height / 2.22;
        spriteB00.play();
        spriteAOverlay00.width = (window.innerWidth > 480) ? app.screen.width / 8.7 : X1percent * 14;
        spriteAOverlay00.height = (window.innerWidth > 480) ? app.screen.height / 5 : Y1percent * 8;
        spriteAOverlay00.anchor.set(0.5);
        spriteAOverlay00.animationSpeed = 0.5;
        spriteAOverlay00.play();
        spriteAOverlay00.loop = false;
        if (SunFireText.text !== "undefined" && prizeData > 0) spriteB00.addChild(SunFireText);
        SunFireText.anchor.set(0.5)
        SunFireText.position.set(4, 9)
        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay00.addChild(spriteB00);
          stickyContainer.addChild(spriteAOverlay00);
        } else {
          spriteA00.addChild(spriteB00);
          app.stage.addChildAt(spriteA00, 22);
        }

      }

      function animateSpin1(prizeData) {
        spriteB01.removeChild(SunFireText1);
        console.log('prizeData--1', prizeData);
        SunFireText1 = new Text(`${prizeData}`, new TextStyle({ fontSize: 50, fill: 0xFFFFFF, fontFamily: "sans-serif", fontWeight: "bolder", }));

        spriteA01.anchor.set(0.5);
        spriteA01.animationSpeed = 1.5;
        spriteA01.loop = false;
        spriteA01.play();
        spriteB01.anchor.set(0.5);
        spriteB01.animationSpeed = 1.5;
        spriteB01.animationSpeed = 1.5;
        spriteA01.width = (window.innerWidth > 480) ? app.screen.width / 10 : X1percent * 12;
        spriteA01.height = (window.innerWidth > 480) ? app.screen.height / 5 : app.screen.height / 12;
        spriteB01.width = window.innerWidth > 480 ? app.screen.width / 4.45 : app.screen.width / 1;
        spriteB01.height = app.screen.height / 2.22;
        spriteB01.play();
        spriteAOverlay01.width = window.innerWidth > 480 ? app.screen.width / 8.7 : X1percent * 14;
        spriteAOverlay01.height = window.innerWidth > 480 ? app.screen.height / 5 : Y1percent * 8;
        spriteAOverlay01.anchor.set(0.5);
        spriteAOverlay01.animationSpeed = 0.5;
        spriteAOverlay01.play();
        spriteAOverlay01.loop = false;
        if (SunFireText1.text !== "undefined" && prizeData > 0) spriteB01.addChild(SunFireText1);
        SunFireText1.anchor.set(0.5)
        SunFireText1.position.set(4, 9)



        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay01.addChild(spriteB01);
          stickyContainer.addChild(spriteAOverlay01);
        } else {
          spriteA01.addChild(spriteB01);
          app.stage.addChildAt(spriteA01, 22);
        }
      }

      function animateSpin2(prizeData) {
        spriteB02.removeChild(SunFireText2);
        console.log('prizeData--2', prizeData);
        SunFireText2 = new Text(`${prizeData}`, new TextStyle({ fontSize: 50, fill: 0xFFFFFF, fontFamily: "sans-serif", fontWeight: "bolder", }));

        spriteA02.anchor.set(0.5);
        spriteA02.animationSpeed = 1.5;
        spriteA02.loop = false;
        spriteA02.play();
        spriteB02.anchor.set(0.5);
        spriteB02.animationSpeed = 1.5;
        spriteB02.animationSpeed = 1.5;
        spriteA02.width = (window.innerWidth > 480) ? app.screen.width / 10 : X1percent * 12;
        spriteA02.height = (window.innerWidth > 480) ? app.screen.height / 5 : app.screen.height / 12;
        spriteB02.width = window.innerWidth > 480 ? app.screen.width / 4.45 : app.screen.width / 1;
        spriteB02.height = app.screen.height / 2.22;
        spriteB02.play();
        spriteAOverlay02.width = window.innerWidth > 480 ? app.screen.width / 8.7 : X1percent * 14;
        spriteAOverlay02.height = window.innerWidth > 480 ? app.screen.height / 5 : Y1percent * 8;
        spriteAOverlay02.anchor.set(0.5);
        spriteAOverlay02.animationSpeed = 0.5;
        spriteAOverlay02.play();
        spriteAOverlay02.loop = false;
        if (SunFireText2.text !== "undefined" && prizeData > 0) spriteB02.addChild(SunFireText2);
        SunFireText2.anchor.set(0.5)
        SunFireText2.position.set(4, 9)

        console.log(spaceFilledWtihFire)
        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay02.addChild(spriteB02);
          stickyContainer.addChild(spriteAOverlay02);
        } else {
          spriteA02.addChild(spriteB02);
          app.stage.addChildAt(spriteA02, 22);
        }

      }

      function animateSpin3(prizeData) {
        spriteB10.removeChild(SunFireText3);
        console.log('prizeData--3', prizeData);
        SunFireText3 = new Text(`${prizeData}`, new TextStyle({ fontSize: 50, fill: 0xFFFFFF, fontFamily: "sans-serif", fontWeight: "bolder", }));

        spriteA10.width = (window.innerWidth > 480) ? app.screen.width / 10 : X1percent * 12;
        spriteA10.height = (window.innerWidth > 480) ? app.screen.height / 5 : app.screen.height / 12;
        spriteAOverlay10.width = (window.innerWidth > 480) ? app.screen.width / 8.7 : X1percent * 14;
        spriteAOverlay10.height = (window.innerWidth > 480) ? app.screen.height / 5 : Y1percent * 8;
        spriteB10.width = window.innerWidth > 480 ? app.screen.width / 4.45 : app.screen.width / 1;
        spriteB10.height = app.screen.height / 2.22;
        spriteA10.anchor.set(0.5);
        spriteA10.animationSpeed = 1.5;
        spriteA10.loop = false;
        spriteA10.play();
        spriteB10.anchor.set(0.5);
        spriteB10.animationSpeed = 1.5;
        spriteB10.animationSpeed = 1.5;
        spriteB10.play();
        spriteAOverlay10.anchor.set(0.5);
        spriteAOverlay10.animationSpeed = 0.5;
        spriteAOverlay10.animationSpeed = 0.5;
        spriteAOverlay10.play();
        spriteAOverlay10.loop = false;

        if (SunFireText3.text !== "undefined" && prizeData > 0) spriteB10.addChild(SunFireText3);
        SunFireText3.anchor.set(0.5)
        SunFireText3.position.set(4, 9)


        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay10.addChild(spriteB10);
          stickyContainer.addChild(spriteAOverlay10);
        } else {
          spriteA10.addChild(spriteB10);
          app.stage.addChildAt(spriteA10, 22);
        }
      }

      function animateSpin4(prizeData) {
        spriteB11.removeChild(SunFireText4);
        console.log('prizeData--4', prizeData);
        SunFireText4 = new Text(`${prizeData}`, new TextStyle({ fontSize: 50, fill: 0xFFFFFF, fontFamily: "sans-serif", fontWeight: "bolder", }));

        spriteA11.width = (window.innerWidth > 480) ? app.screen.width / 10 : X1percent * 12;
        spriteA11.height = (window.innerWidth > 480) ? app.screen.height / 5 : app.screen.height / 12;
        spriteAOverlay11.width = (window.innerWidth > 480) ? app.screen.width / 8.7 : X1percent * 14;
        spriteAOverlay11.height = (window.innerWidth > 480) ? app.screen.height / 5 : Y1percent * 8;
        spriteAOverlay11.anchor.set(0.5);
        spriteAOverlay11.animationSpeed = 0.5;
        spriteAOverlay11.play();
        spriteAOverlay11.loop = false;
        spriteB11.width = window.innerWidth > 480 ? app.screen.width / 4.45 : app.screen.width / 1;
        spriteB11.height = app.screen.height / 2.22;
        spriteA11.anchor.set(0.5);
        spriteA11.animationSpeed = 1.5;
        spriteA11.loop = false;
        spriteA11.play();
        spriteB11.anchor.set(0.5);
        spriteB11.animationSpeed = 1.5;
        spriteB11.animationSpeed = 1.5;
        spriteB11.play();
        if (SunFireText4.text !== "undefined" && prizeData > 0) spriteB11.addChild(SunFireText4);
        SunFireText4.anchor.set(0.5)
        SunFireText4.position.set(4, 9)

        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay11.addChild(spriteB11);
          stickyContainer.addChild(spriteAOverlay11);
        } else {
          spriteA11.addChild(spriteB11);
          app.stage.addChildAt(spriteA11, 22);
        }
      }

      function animateSpin5(prizeData) {
        spriteB12.removeChild(SunFireText5);
        console.log('prizeData--5', prizeData);
        SunFireText5 = new Text(`${prizeData}`, new TextStyle({ fontSize: 50, fill: 0xFFFFFF, fontFamily: "sans-serif", fontWeight: "bolder", }));

        spriteA12.width = (window.innerWidth > 480) ? app.screen.width / 10 : X1percent * 12;
        spriteA12.height = (window.innerWidth > 480) ? app.screen.height / 5 : app.screen.height / 12;
        spriteB12.width = window.innerWidth > 480 ? app.screen.width / 4.45 : app.screen.width / 1;
        spriteB12.height = app.screen.height / 2.22;
        spriteA12.anchor.set(0.5);
        spriteA12.animationSpeed = 1.5;
        spriteA12.loop = false;
        spriteA12.play();
        spriteB12.anchor.set(0.5);
        spriteB12.animationSpeed = 1.5;
        spriteB12.animationSpeed = 1.5;
        spriteB12.play();
        spriteAOverlay12.width = (window.innerWidth > 480) ? app.screen.width / 8.7 : X1percent * 14;
        spriteAOverlay12.height = (window.innerWidth > 480) ? app.screen.height / 5 : Y1percent * 8;
        spriteAOverlay12.anchor.set(0.5);
        spriteAOverlay12.animationSpeed = 0.5;
        spriteAOverlay12.play();
        spriteAOverlay12.loop = false;
        if (SunFireText5.text !== "undefined" && prizeData > 0) spriteB12.addChild(SunFireText5);
        SunFireText5.anchor.set(0.5)
        SunFireText5.position.set(4, 9)

        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay12.addChild(spriteB12);
          stickyContainer.addChild(spriteAOverlay12);
        } else {
          spriteA12.addChild(spriteB12);
          app.stage.addChildAt(spriteA12, 22);
        }
      }

      function animateSpin6(prizeData) {
        spriteB20.removeChild(SunFireText6);
        console.log('prizeData--6', prizeData);
        SunFireText6 = new Text(`${prizeData}`, new TextStyle({ fontSize: 50, fill: 0xFFFFFF, fontFamily: "sans-serif", fontWeight: "bolder", }));

        spriteA20.width = (window.innerWidth > 480) ? app.screen.width / 10 : X1percent * 12;
        spriteA20.height = (window.innerWidth > 480) ? app.screen.height / 5 : app.screen.height / 12;
        spriteB20.width = window.innerWidth > 480 ? app.screen.width / 4.45 : app.screen.width / 1;
        spriteB20.height = app.screen.height / 2.22;
        spriteA20.anchor.set(0.5);
        spriteA20.animationSpeed = 1.5;
        spriteA20.loop = false;
        spriteA20.play();
        spriteB20.anchor.set(0.5);
        spriteB20.animationSpeed = 1.5;
        spriteB20.animationSpeed = 1.5;
        spriteB20.play();
        spriteAOverlay20.width = (window.innerWidth > 480) ? app.screen.width / 8.7 : X1percent * 14;
        spriteAOverlay20.height = (window.innerWidth > 480) ? app.screen.height / 5 : Y1percent * 8;
        spriteAOverlay20.anchor.set(0.5);
        spriteAOverlay20.animationSpeed = 0.5;
        spriteAOverlay20.play();
        spriteAOverlay20.loop = false;
        if (SunFireText6.text !== "undefined" && prizeData > 0) spriteB20.addChild(SunFireText6);
        SunFireText6.anchor.set(0.5)
        SunFireText6.position.set(4, 9)


        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay20.addChild(spriteB20);
          stickyContainer.addChild(spriteAOverlay20);
        } else {
          spriteA20.addChild(spriteB20);
          app.stage.addChildAt(spriteA20, 22);
        }
      }

      function animateSpin7(prizeData) {
        spriteB21.removeChild(SunFireText7);
        console.log('prizeData--7', prizeData);
        SunFireText7 = new Text(`${prizeData}`, new TextStyle({ fontSize: 50, fill: 0xFFFFFF, fontFamily: "sans-serif", fontWeight: "bolder", }));

        spriteA21.width = (window.innerWidth > 480) ? app.screen.width / 10 : X1percent * 12;
        spriteA21.height = (window.innerWidth > 480) ? app.screen.height / 5 : app.screen.height / 12;
        spriteB21.width = window.innerWidth > 480 ? app.screen.width / 4.45 : app.screen.width / 1;

        spriteB21.height = app.screen.height / 2.22;
        spriteA21.anchor.set(0.5);
        spriteA21.animationSpeed = 1.5;
        spriteA21.loop = false;
        spriteA21.play();
        spriteB21.anchor.set(0.5);
        spriteB21.animationSpeed = 1.5;
        spriteB21.animationSpeed = 1.5;
        spriteB21.play();
        spriteAOverlay21.width = (window.innerWidth > 480) ? app.screen.width / 8.7 : X1percent * 14;
        spriteAOverlay21.height = (window.innerWidth > 480) ? app.screen.height / 5 : Y1percent * 8;
        spriteAOverlay21.anchor.set(0.5);
        spriteAOverlay21.animationSpeed = 0.5;
        spriteAOverlay21.play();
        spriteAOverlay21.loop = false;
        if (SunFireText7.text !== "undefined" && prizeData > 0) spriteB21.addChild(SunFireText7);
        SunFireText7.anchor.set(0.5)
        SunFireText7.position.set(4, 9)


        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay21.addChild(spriteB21);
          stickyContainer.addChild(spriteAOverlay21);
        } else {
          spriteA21.addChild(spriteB21);
          app.stage.addChildAt(spriteA21, 22);
        }
      }

      function animateSpin8(prizeData) {
        spriteB22.removeChild(SunFireText8);
        SunFireText8 = new Text(`${prizeData}`, new TextStyle({ fontSize: 50, fill: 0xFFFFFF, fontFamily: "sans-serif", fontWeight: "bolder", }));

        spriteA22.width = (window.innerWidth > 480) ? app.screen.width / 10 : X1percent * 12;
        spriteA22.height = (window.innerWidth > 480) ? app.screen.height / 5 : app.screen.height / 12;
        spriteB22.width = window.innerWidth > 480 ? app.screen.width / 4.45 : app.screen.width / 1;
        spriteB22.height = app.screen.height / 2.22;
        spriteB22.height = app.screen.height / 2.22;
        spriteA22.anchor.set(0.5);
        spriteA22.animationSpeed = 1.5;
        spriteA22.loop = false;
        spriteA22.play();
        spriteB22.anchor.set(0.5);
        spriteB22.animationSpeed = 1.5;
        spriteB22.animationSpeed = 1.5;
        spriteB22.play();
        spriteAOverlay22.width = (window.innerWidth > 480) ? app.screen.width / 8.7 : X1percent * 14;
        spriteAOverlay22.height = (window.innerWidth > 480) ? app.screen.height / 5 : Y1percent * 8;
        spriteAOverlay22.anchor.set(0.5);
        spriteAOverlay22.animationSpeed = 0.5;
        spriteAOverlay22.play();
        spriteAOverlay22.loop = false;
        if (SunFireText8.text !== "undefined" && prizeData > 0) spriteB22.addChild(SunFireText8);
        SunFireText8.anchor.set(0.5)
        SunFireText8.position.set(4, 9)


        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay22.addChild(spriteB22);
          stickyContainer.addChild(spriteAOverlay22);
        } else {
          spriteA22.addChild(spriteB22);
          app.stage.addChildAt(spriteA22, 22);
        }
      }

      function animateSpin9(prizeData) {
        spriteB30.removeChild(SunFireText9);
        SunFireText9 = new Text(`${prizeData}`, new TextStyle({ fontSize: 50, fill: 0xFFFFFF, fontFamily: "sans-serif", fontWeight: "bolder", }));

        spriteA30.width = (window.innerWidth > 480) ? app.screen.width / 10 : X1percent * 12;
        spriteA30.height = (window.innerWidth > 480) ? app.screen.height / 5 : app.screen.height / 12;
        spriteB30.width = window.innerWidth > 480 ? app.screen.width / 4.45 : app.screen.width / 1;
        spriteB30.height = app.screen.height / 2.22;
        spriteA30.anchor.set(0.5);
        spriteA30.animationSpeed = 1.5;
        spriteA30.loop = false;
        spriteA30.play();
        spriteB30.anchor.set(0.5);
        spriteB30.animationSpeed = 1.5;
        spriteB30.animationSpeed = 1.5;
        spriteB30.play();
        spriteAOverlay30.width = (window.innerWidth > 480) ? app.screen.width / 8.7 : X1percent * 14;
        spriteAOverlay30.height = (window.innerWidth > 480) ? app.screen.height / 5 : Y1percent * 8;
        spriteAOverlay30.anchor.set(0.5);
        spriteAOverlay30.animationSpeed = 0.5;
        spriteAOverlay30.play();
        spriteAOverlay30.loop = false;
        if (SunFireText9.text !== "undefined" && prizeData > 0) spriteB30.addChild(SunFireText9);
        SunFireText9.anchor.set(0.5)
        SunFireText9.position.set(4, 9)

        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay30.addChild(spriteB30);
          stickyContainer.addChild(spriteAOverlay30);
        } else {
          spriteA30.addChild(spriteB30);
          app.stage.addChildAt(spriteA30, 22);
        }
      }

      function animateSpin10(prizeData) {
        spriteB31.removeChild(SunFireText10);
        spriteA31.width = (window.innerWidth > 480) ? app.screen.width / 10 : X1percent * 12;
        spriteA31.height = (window.innerWidth > 480) ? app.screen.height / 5 : app.screen.height / 12;
        spriteB31.width = window.innerWidth > 480 ? app.screen.width / 4.45 : app.screen.width / 1;
        spriteB31.height = app.screen.height / 2.22;
        spriteA31.anchor.set(0.5);
        spriteA31.animationSpeed = 1.5;
        spriteA31.loop = false;
        spriteA31.play();
        spriteB31.anchor.set(0.5);
        spriteB31.animationSpeed = 1.5;
        spriteB31.animationSpeed = 1.5;
        spriteAOverlay31.width = (window.innerWidth > 480) ? app.screen.width / 8.7 : X1percent * 14;
        spriteAOverlay31.height = (window.innerWidth > 480) ? app.screen.height / 5 : Y1percent * 8;
        spriteAOverlay31.anchor.set(0.5);
        spriteAOverlay31.animationSpeed = 0.5;
        spriteAOverlay31.play();
        spriteAOverlay31.loop = false;
        SunFireText10 = new Text(`${prizeData}`, new TextStyle({ fontSize: 50, fill: 0xFFFFFF, fontFamily: "sans-serif", fontWeight: "bolder", }));

        if (SunFireText10.text !== "undefined" && prizeData > 0) spriteB31.addChild(SunFireText10);
        SunFireText10.anchor.set(0.5)
        SunFireText10.position.set(4, 9)
        spriteB31.play();

        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay31.addChild(spriteB31);
          stickyContainer.addChild(spriteAOverlay31);
        } else {
          spriteA31.addChild(spriteB31);
          app.stage.addChildAt(spriteA31, 22);
        }
      }

      function animateSpin11(prizeData) {
        spriteB32.removeChild(SunFireText11);
        spriteA32.width = (window.innerWidth > 480) ? app.screen.width / 10 : X1percent * 12;
        spriteA32.height = (window.innerWidth > 480) ? app.screen.height / 5 : app.screen.height / 12;
        spriteB32.width = window.innerWidth > 480 ? app.screen.width / 4.45 : app.screen.width / 1;
        spriteB32.height = app.screen.height / 2.22;
        spriteA32.anchor.set(0.5);
        spriteA32.animationSpeed = 1.5;
        spriteA32.loop = false;
        spriteA32.play();
        spriteB32.anchor.set(0.5);
        spriteB32.animationSpeed = 1.5;
        spriteB32.animationSpeed = 1.5;
        spriteB32.play();
        spriteAOverlay32.width = (window.innerWidth > 480) ? app.screen.width / 8.7 : X1percent * 14;
        spriteAOverlay32.height = (window.innerWidth > 480) ? app.screen.height / 5 : Y1percent * 8;
        spriteAOverlay32.anchor.set(0.5);
        spriteAOverlay32.animationSpeed = 0.5;
        spriteAOverlay32.play();
        spriteAOverlay32.loop = false;
        SunFireText11 = new Text(`${prizeData}`, new TextStyle({ fontSize: 50, fill: 0xFFFFFF, fontFamily: "sans-serif", fontWeight: "bolder", }));

        if (SunFireText11.text !== "undefined" && prizeData > 0) spriteB32.addChild(SunFireText11);
        SunFireText11.anchor.set(0.5)
        SunFireText11.position.set(4, 9)

        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay32.addChild(spriteB32);
          stickyContainer.addChild(spriteAOverlay32);
        } else {
          spriteA32.addChild(spriteB32);
          app.stage.addChildAt(spriteA32, 22);
        }
      }

      function animateSpin12(prizeData) {
        spriteB40.removeChild(SunFireText12);
        spriteA40.width = (window.innerWidth > 480) ? app.screen.width / 10 : X1percent * 12;
        spriteA40.height = (window.innerWidth > 480) ? app.screen.height / 5 : app.screen.height / 12;
        spriteB40.width = window.innerWidth > 480 ? app.screen.width / 4.45 : app.screen.width / 1;
        spriteB40.height = app.screen.height / 2.22;
        spriteA40.anchor.set(0.5);
        spriteA40.animationSpeed = 1.5;
        spriteA40.loop = false;
        spriteA40.play();
        spriteB40.anchor.set(0.5);
        spriteB40.animationSpeed = 1.5;
        spriteB40.animationSpeed = 1.5;
        spriteB40.play();
        spriteAOverlay40.width = (window.innerWidth > 480) ? app.screen.width / 8.7 : X1percent * 14;
        spriteAOverlay40.height = (window.innerWidth > 480) ? app.screen.height / 5 : Y1percent * 8;
        spriteAOverlay40.anchor.set(0.5);
        spriteAOverlay40.animationSpeed = 0.5;
        spriteAOverlay40.play();
        spriteAOverlay40.loop = false;
        SunFireText12 = new Text(`${prizeData}`, new TextStyle({ fontSize: 50, fill: 0xFFFFFF, fontFamily: "sans-serif", fontWeight: "bolder", }));

        if (SunFireText12.text !== "undefined" && prizeData > 0) spriteB40.addChild(SunFireText12);
        SunFireText12.anchor.set(0.5)
        SunFireText12.position.set(4, 9)

        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay40.addChild(spriteB40);
          stickyContainer.addChild(spriteAOverlay40);
        } else {
          spriteA40.addChild(spriteB40);
          app.stage.addChildAt(spriteA40, 22);
        }
      }

      function animateSpin13(prizeData) {
        spriteB41.removeChild(SunFireText13);
        spriteA41.width = (window.innerWidth > 480) ? app.screen.width / 10 : X1percent * 12;
        spriteA41.height = (window.innerWidth > 480) ? app.screen.height / 5 : app.screen.height / 12;
        spriteB41.width = window.innerWidth > 480 ? app.screen.width / 4.45 : app.screen.width / 1;
        spriteB41.height = app.screen.height / 2.22;
        spriteA41.anchor.set(0.5);
        spriteA41.animationSpeed = 1.5;
        spriteA41.loop = false;
        spriteA41.play();
        spriteB41.anchor.set(0.5);
        spriteB41.animationSpeed = 1.5;
        spriteB41.animationSpeed = 1.5;
        spriteB41.play();
        spriteAOverlay41.width = (window.innerWidth > 480) ? app.screen.width / 8.7 : X1percent * 14;
        spriteAOverlay41.height = (window.innerWidth > 480) ? app.screen.height / 5 : Y1percent * 8;
        spriteAOverlay41.anchor.set(0.5);
        spriteAOverlay41.animationSpeed = 0.5;
        spriteAOverlay41.play();
        spriteAOverlay41.loop = false;
        SunFireText13 = new Text(`${prizeData}`, new TextStyle({ fontSize: 50, fill: 0xFFFFFF, fontFamily: "sans-serif", fontWeight: "bolder", }));

        if (SunFireText13.text !== "undefined" && prizeData > 0) spriteB41.addChild(SunFireText13);
        SunFireText13.anchor.set(0.5)
        SunFireText13.position.set(4, 9)

        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay41.addChild(spriteB41);
          stickyContainer.addChild(spriteAOverlay41);
        } else {
          spriteA41.addChild(spriteB41);
          app.stage.addChildAt(spriteA41, 22);
        }
      }

      function animateSpin14(prizeData) {
        spriteB42.removeChild(SunFireText14);
        spriteA42.width = (window.innerWidth > 480) ? app.screen.width / 10 : X1percent * 12;
        spriteA42.height = (window.innerWidth > 480) ? app.screen.height / 5 : app.screen.height / 12;
        spriteB42.width = window.innerWidth > 480 ? app.screen.width / 4.45 : app.screen.width / 1;
        spriteB42.height = app.screen.height / 2.22;
        spriteA42.anchor.set(0.5);
        spriteA42.animationSpeed = 1.5;
        spriteA42.loop = false;
        spriteA42.play();
        spriteB42.anchor.set(0.5);
        spriteB42.animationSpeed = 1.5;
        spriteB42.animationSpeed = 1.5;
        spriteB42.play();
        spriteAOverlay42.width = (window.innerWidth > 480) ? app.screen.width / 8.7 : X1percent * 14;
        spriteAOverlay42.height = (window.innerWidth > 480) ? app.screen.height / 5 : Y1percent * 8;
        spriteAOverlay42.anchor.set(0.5);
        spriteAOverlay42.animationSpeed = 0.5;
        spriteAOverlay42.play();
        spriteAOverlay42.loop = false;
        SunFireText14 = new Text(`${prizeData}`, new TextStyle({ fontSize: 50, fill: 0xFFFFFF, fontFamily: "sans-serif", fontWeight: "bolder", }));

        if (SunFireText14.text !== "undefined" && prizeData > 0) spriteB42.addChild(SunFireText14);
        SunFireText14.anchor.set(0.5)
        SunFireText14.position.set(4, 9)

        if (spaceFilledWtihFire >= 6) {
          app.stage.removeChild(spriteAOverlay42);
          spriteAOverlay42.addChild(spriteB42);
          stickyContainer.addChild(spriteAOverlay42);
        } else {
          spriteA42.addChild(spriteB42);
          app.stage.addChildAt(spriteA42, 22);
        }
      }






      // function animateSpin() {
      //   spriteA00.anchor.set(0.5);
      //   spriteA00.animationSpeed = 1.5;
      //   spriteA00.loop = false;
      //   spriteA00.play();
      //   spriteB00.anchor.set(0.5);
      //   spriteB00.animationSpeed = 1.5;
      //   spriteB00.animationSpeed = 1.5;
      //   spriteA00.width = app.screen.width / 10;
      //   spriteA00.height = app.screen.height / 5;
      //   spriteB00.width = app.screen.width / 5;
      //   spriteB00.height = app.screen.width / 5;
      //   spriteB00.play();
      //   spriteA00.addChild(spriteB00);
      //   app.stage.addChildAt(spriteA00, 22);
      // }

      // function animateSpin1() {
      //   spriteA01.anchor.set(0.5);
      //   spriteA01.animationSpeed = 1.5;
      //   spriteA01.loop = false;
      //   spriteA01.play();
      //   spriteB01.anchor.set(0.5);
      //   spriteB01.animationSpeed = 1.5;
      //   spriteB01.animationSpeed = 1.5;
      //   spriteA01.width = app.screen.width / 10;
      //   spriteA01.height = app.screen.height / 5;
      //   spriteB01.width = app.screen.width / 5;
      //   spriteB01.height = app.screen.width / 5;
      //   spriteB01.play();

      //   spriteA01.addChild(spriteB01);
      //   app.stage.addChildAt(spriteA01, 22);
      // }

      // function animateSpin2() {
      //   spriteA02.anchor.set(0.5);
      //   spriteA02.animationSpeed = 1.5;
      //   spriteA02.loop = false;
      //   spriteA02.play();
      //   spriteB02.anchor.set(0.5);
      //   spriteB02.animationSpeed = 1.5;
      //   spriteB02.animationSpeed = 1.5;
      //   spriteA02.width = app.screen.width / 10;
      //   spriteA02.height = app.screen.height / 5;
      //   spriteB02.width = app.screen.width / 5;
      //   spriteB02.height = app.screen.width / 5;
      //   spriteB02.play();

      //   spriteA02.addChild(spriteB02);
      //   app.stage.addChildAt(spriteA02, 22);
      // }

      // function animateSpin3() {
      //   spriteA10.width = app.screen.width / 10;
      //   spriteA10.height = app.screen.height / 5;
      //   spriteB10.width = app.screen.width / 5;
      //   spriteB10.height = app.screen.width / 5;
      //   spriteA10.anchor.set(0.5);
      //   spriteA10.animationSpeed = 1.5;
      //   spriteA10.loop = false;
      //   spriteA10.play();
      //   spriteB10.anchor.set(0.5);
      //   spriteB10.animationSpeed = 1.5;
      //   spriteB10.animationSpeed = 1.5;
      //   spriteB10.play();

      //   spriteA10.addChild(spriteB10);
      //   app.stage.addChildAt(spriteA10, 22);
      // }

      // function animateSpin4() {
      //   spriteA11.width = app.screen.width / 10;
      //   spriteA11.height = app.screen.height / 5;
      //   spriteB11.width = app.screen.width / 5;
      //   spriteB11.height = app.screen.width / 5;
      //   spriteA11.anchor.set(0.5);
      //   spriteA11.animationSpeed = 1.5;
      //   spriteA11.loop = false;
      //   spriteA11.play();
      //   spriteB11.anchor.set(0.5);
      //   spriteB11.animationSpeed = 1.5;
      //   spriteB11.animationSpeed = 1.5;
      //   spriteB11.play();

      //   spriteA11.addChild(spriteB11);
      //   app.stage.addChildAt(spriteA11, 22);
      // }

      // function animateSpin5() {
      //   spriteA12.width = app.screen.width / 10;
      //   spriteA12.height = app.screen.height / 5;
      //   spriteB12.width = app.screen.width / 5;
      //   spriteB12.height = app.screen.width / 5;
      //   spriteA12.anchor.set(0.5);
      //   spriteA12.animationSpeed = 1.5;
      //   spriteA12.loop = false;
      //   spriteA12.play();
      //   spriteB12.anchor.set(0.5);
      //   spriteB12.animationSpeed = 1.5;
      //   spriteB12.animationSpeed = 1.5;
      //   spriteB12.play();

      //   spriteA12.addChild(spriteB12);
      //   app.stage.addChildAt(spriteA12, 22);
      // }

      // function animateSpin6() {
      //   spriteA20.height = app.screen.height / 5;
      //   spriteA20.width = app.screen.width / 10;
      //   spriteB20.width = app.screen.width / 5;
      //   spriteB20.height = app.screen.width / 5;
      //   spriteA20.anchor.set(0.5);
      //   spriteA20.animationSpeed = 1.5;
      //   spriteA20.loop = false;
      //   spriteA20.play();
      //   spriteB20.anchor.set(0.5);
      //   spriteB20.animationSpeed = 1.5;
      //   spriteB20.animationSpeed = 1.5;
      //   spriteB20.play();

      //   spriteA20.addChild(spriteB20);
      //   app.stage.addChildAt(spriteA20, 22);
      // }

      // function animateSpin7() {
      //   spriteA21.width = app.screen.width / 10;
      //   spriteA21.height = app.screen.height / 5;
      //   spriteB21.width = app.screen.width / 5;
      //   spriteB21.height = app.screen.width / 5;
      //   spriteA21.anchor.set(0.5);
      //   spriteA21.animationSpeed = 1.5;
      //   spriteA21.loop = false;
      //   spriteA21.play();
      //   spriteB21.anchor.set(0.5);
      //   spriteB21.animationSpeed = 1.5;
      //   spriteB21.animationSpeed = 1.5;
      //   spriteB21.play();

      //   spriteA21.addChild(spriteB21);
      //   app.stage.addChildAt(spriteA21, 22);
      // }

      // function animateSpin8() {
      //   spriteA22.width = app.screen.width / 10;
      //   spriteA22.height = app.screen.height / 5;
      //   spriteB22.width = app.screen.width / 5;
      //   spriteB22.height = app.screen.width / 5;
      //   spriteA22.anchor.set(0.5);
      //   spriteA22.animationSpeed = 1.5;
      //   spriteA22.loop = false;
      //   spriteA22.play();
      //   spriteB22.anchor.set(0.5);
      //   spriteB22.animationSpeed = 1.5;
      //   spriteB22.animationSpeed = 1.5;
      //   spriteB22.play();

      //   spriteA22.addChild(spriteB22);
      //   app.stage.addChildAt(spriteA22, 22);
      // }

      // function animateSpin9() {
      //   spriteA30.width = app.screen.width / 10;
      //   spriteA30.height = app.screen.height / 5;
      //   spriteB30.width = app.screen.width / 5;
      //   spriteB30.height = app.screen.width / 5;
      //   spriteA30.anchor.set(0.5);
      //   spriteA30.animationSpeed = 1.5;
      //   spriteA30.loop = false;
      //   spriteA30.play();
      //   spriteB30.anchor.set(0.5);
      //   spriteB30.animationSpeed = 1.5;
      //   spriteB30.animationSpeed = 1.5;
      //   spriteB30.play();

      //   spriteA30.addChild(spriteB30);
      //   app.stage.addChildAt(spriteA30, 22);
      // }

      // function animateSpin10() {
      //   spriteA31.height = app.screen.height / 5;
      //   spriteA31.width = app.screen.width / 10;
      //   spriteB31.width = app.screen.width / 5;
      //   spriteB31.height = app.screen.width / 5;
      //   spriteA31.anchor.set(0.5);
      //   spriteA31.animationSpeed = 1.5;
      //   spriteA31.loop = false;
      //   spriteA31.play();
      //   spriteB31.anchor.set(0.5);
      //   spriteB31.animationSpeed = 1.5;
      //   spriteB31.animationSpeed = 1.5;
      //   spriteB31.play();

      //   spriteA31.addChild(spriteB31);
      //   app.stage.addChildAt(spriteA31, 22);
      // }

      // function animateSpin11() {
      //   spriteA32.width = app.screen.width / 10;
      //   spriteA32.height = app.screen.height / 5;
      //   spriteB32.width = app.screen.width / 5;
      //   spriteB32.height = app.screen.width / 5;
      //   spriteA32.anchor.set(0.5);
      //   spriteA32.animationSpeed = 1.5;
      //   spriteA32.loop = false;
      //   spriteA32.play();
      //   spriteB32.anchor.set(0.5);
      //   spriteB32.animationSpeed = 1.5;
      //   spriteB32.animationSpeed = 1.5;
      //   spriteB32.play();

      //   spriteA32.addChild(spriteB32);
      //   app.stage.addChildAt(spriteA32, 22);
      // }

      // function animateSpin12() {
      //   spriteA40.width = app.screen.width / 10;
      //   spriteA40.height = app.screen.height / 5;
      //   spriteB40.width = app.screen.width / 5;
      //   spriteB40.height = app.screen.width / 5;
      //   spriteA40.anchor.set(0.5);
      //   spriteA40.animationSpeed = 1.5;
      //   spriteA40.loop = false;
      //   spriteA40.play();
      //   spriteB40.anchor.set(0.5);
      //   spriteB40.animationSpeed = 1.5;
      //   spriteB40.animationSpeed = 1.5;
      //   spriteB40.play();

      //   spriteA40.addChild(spriteB40);
      //   app.stage.addChildAt(spriteA40, 22);
      // }

      // function animateSpin13() {
      //   spriteA41.width = app.screen.width / 10;
      //   spriteA41.height = app.screen.height / 5;
      //   spriteB41.width = app.screen.width / 5;
      //   spriteB41.height = app.screen.width / 5;
      //   spriteA41.anchor.set(0.5);
      //   spriteA41.animationSpeed = 1.5;
      //   spriteA41.loop = false;
      //   spriteA41.play();
      //   spriteB41.anchor.set(0.5);
      //   spriteB41.animationSpeed = 1.5;
      //   spriteB41.animationSpeed = 1.5;
      //   spriteB41.play();

      //   spriteA41.addChild(spriteB41);
      //   app.stage.addChildAt(spriteA41, 22);
      // }

      // function animateSpin14() {
      //   spriteA42.width = app.screen.width / 10;
      //   spriteA42.height = app.screen.height / 5;
      //   spriteB42.width = app.screen.width / 5;
      //   spriteB42.height = app.screen.width / 5;
      //   spriteA42.anchor.set(0.5);
      //   spriteA42.animationSpeed = 1.5;
      //   spriteA42.loop = false;
      //   spriteA42.play();
      //   spriteB42.anchor.set(0.5);
      //   spriteB42.animationSpeed = 1.5;
      //   spriteB42.animationSpeed = 1.5;
      //   spriteB42.play();

      //   spriteA42.addChild(spriteB42);
      //   app.stage.addChildAt(spriteA42, 22);
      // }



      //assets Loaded
      setTimeout(() => {
        setLoder(!loder);
      }, 1000);
      setProgress(100);


      var creditsVal;
      var winCount;
      var freeSpin;
      var currentfreeSpinGameLoad;
      var currentfreeSpin;
      var freeGamesWin;
      var winValue = 0;
      var winAmountShow = [];


      async function gameLoadDataApi() {
        try {
          const gameData = await axios?.post(
            `${process.env.REACT_APP_API_URL}/GamePlay/GameLoad`,
            {
              playerId: playerId,
              gameId: 0
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );
          // console.log(gameData, "gameData--gameData");

          return gameData?.data?.resultData;
        } catch (error) {
          if (error?.response?.status === 401) {
            localStorage.removeItem("playerId");
            localStorage.removeItem("token");
            sessionExpired()
          }

        }
      }

      function sessionExpired() {
        setSessionExpired(!isSessionExpired)
      }
      let data;

      try {
        data = await gameLoadDataApi();
        creditsVal = data?.playerBalance || 0;
        // currentfreeSpinGameLoad = data?.noOfRemaningFreeSpin;
        currentfreeSpinGameLoad = data?.noOfRemaningFreeSpin || 0
        // console.log(await gameLoadDataApi(), "gameData-");
      } catch (error) {
        // console.log(error);
      }
      // console.log(data, "gameData-1");



      //noOfRemaningFreeSpin
      let reelData = data?.defaultCombination?.split(",");
      // console.log(reelData, "reelData");

      var singlePaylinesNum = [];
      var paylinesNum = [];
      //  game spin api function
      var imgArrSlot = [];
      var startCount = 0;
      var payLineIntervel;

      var coinDenonimation = 1;
      // var betAmountValue = 50;

      var betVal = 50;
      var payLineAnimateArr;
      var winningSymbolArr;
      var remaingFreeSpin;
      var occurenceArr;
      var fireSymbolCount;
      function calculateOutput(value) {
        if (value >= 500) {
          return "10.00";
        } else if (value >= 250) {
          return "5.00";
        } else if (value >= 150) {
          return "3.00";
        } else if (value >= 100) {
          return "2.00";
        } else if (value >= 50) {
          return "1.00";
        }
      }
      async function gameSpinApi(spinType) {
        
        
        payLineTrueHandle()
        var betAmountNewVal = calculateOutput(betVal);
        try {
          const time = new Date("2015-03-25");
          console.log(time, 'time')
          // console.log(Date,'timeee')
          const dataApi = await axios?.post(
            `${process.env.REACT_APP_API_URL}/GamePlay/Bet/Bet`,
            {
              gameId: 0,
              playerId: playerId,
              denomination: (coinDenonimation / 100).toFixed(2),
              betAmount: betAmountNewVal,
              multiplier: 1,
              isfreeSpinWon: spinType,
              isStickySpinWon: IsStickeySpinWon,
               testStickey: testKeyVar
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );
          const time1 = new Date("2015-03-25");
          console.log(time1, 'time1')
          if(dataApi?.data?.resultData == null){
            Swal.fire({
              title: "Error!",
              text: "Server not responded, Try Again!",
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Retry!",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
               startPlay()
              }
            });
          }else if(dataApi?.data?.StatusCode == 0){
            Swal.fire({
              title: "Error!",
              text: dataApi?.data?.Message,
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Retry!",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
               startPlay()
              }
            });
          }
          imgArrSlot = [];
          StickeySpinWon = dataApi?.data?.resultData?.isStickeyspinWon;
          winCount = dataApi?.data?.resultData?.winValue || 0;
          freeSpin = dataApi?.data?.resultData?.remaningFreeSpinCount || 0;
          // freeSpin = IsstickySpin == true ? 0 : dataApi?.data?.resultData?.remaningFreeSpinCount || 0;
          freeGamesWin = dataApi?.data?.resultData?.isFreeSpinWon;
          currentfreeSpin = dataApi?.data?.resultData?.noOfFreeSpinWon || 0;
          reelIndex = dataApi?.data?.resultData?.reelIndex ? dataApi?.data?.resultData?.reelIndex : "";
          slotIndex = dataApi?.data?.resultData?.slotIndex ? dataApi?.data?.resultData?.slotIndex : "";
          spaceFilledWtihFire = dataApi?.data?.resultData?.spaceFilledWtihFire;
          remaningStickeySpin = dataApi?.data?.resultData?.remaningStickeySpin;
          remaingFreeSpin = dataApi?.data?.resultData?.remaningFreeSpinCount;
          if (count == 0) {
            remaningStickeySpin = 3;
            spaceFilledWtihFire = spaceFilledWtihFire;
            // linkPrizeAmount = [0,0,0,500,200,0,100,50,0,0,0,0,50,50,0]
          } else {
            remaningStickeySpin = dataApi?.data?.resultData?.remaningStickeySpin ? remaningStickeySpin : "";
            spaceFilledWtihFire = dataApi?.data?.resultData?.spaceFilledWtihFire ? spaceFilledWtihFire : "";
          }
          //addStickyNumber(reelIndex,slotIndex)
          console.log(freeSpin, 'sticky spin ')
          
          let dummuwiningCombination;

          // var prizeAmount = [50.0,100.0,50.0, 200.0,500.0,750.0]
          // var prizeType = ["RegularPrize,RegularPrize,RegularPrize,RegularPrize,RegularPrize,RegularPrize"]
          // var reelIndex = [1,2]
          // var slotIndex =  [0,1,2,0,1,2]

          // console.log(winCount, "dataNewApi");

          // // fireAnimation Layout
          //  let dummuwiningCombination;
          // if(count == 0){
          //   dummuwiningCombination = "TT,TT,TT,TT,TT,WW,TT,WW,CC,DD,DD,DD,WW,WW,II";
          //   currentfreeSpin = 5;
          // } 


          //  if(count == 0){
          //   dummuwiningCombination = "DD,WW,EE,TT,TT,GG,TT,TT,KK,GG,EE,DD,TT,TT,DD";
          //   linkPrizeAmount = [0,0,0,500,200,0,100,50,0,0,0,0,50,50,0]    
          // } 
          // if(count == 1){
          //   dummuwiningCombination = "CC,CC,CC,CC,CC,WW,HH,WW,TT,DD,JJ,DD,KK,EE,II";
          //   linkPrizeAmount = [0,0,0,0,0,0,0,0,50,0,0,0,0,0,0]    
          // }
          // if(count == 2){
          //   dummuwiningCombination = "CC,CC,CC,CC,CC,WW,HH,WW,EE,TT,JJ,DD,KK,EE,II";
          //   linkPrizeAmount = [0,0,0,0,0,0,0,0,0,750,0,0,0,0,0]               
          // }

          // if(count == 3){
          //   dummuwiningCombination = "CC,CC,CC,CC,CC,WW,HH,WW,EE,CC,JJ,DD,KK,EE,II";
          //   linkPrizeAmount = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]           
          // }
          // if(count == 4){
          //   dummuwiningCombination = "CC,CC,CC,CC,CC,WW,HH,WW,EE,CC,JJ,DD,KK,EE,II";
          //   linkPrizeAmount = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]           
          // }
          // if(count == 5){
          //   dummuwiningCombination = "TT,CC,CC,CC,CC,WW,HH,WW,EE,CC,JJ,DD,KK,EE,II";
          //   linkPrizeAmount = [200,0,0,0,0,0,0,0,0,0,0,0,0,0,0]           
          // }
          // if(count == 6){
          //   dummuwiningCombination = "CC,CC,CC,CC,CC,WW,HH,WW,EE,CC,JJ,DD,KK,EE,II";
          //   linkPrizeAmount = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]           
          // }
          // if(count == 7){
          //   dummuwiningCombination = "CC,CC,CC,CC,CC,WW,HH,WW,EE,CC,JJ,DD,KK,EE,II";
          //   linkPrizeAmount = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]           
          // }
          // if(count == 8){
          //   dummuwiningCombination = "CC,EE,WW,CC,CC,WW,HH,WW,EE,CC,JJ,DD,KK,EE,II";
          //   linkPrizeAmount = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]           
          // }

          count = count + 1;
          // let newSlotArr = dummuwiningCombination.split(",");

          sunFireDataObj = {
            "prizeAmount": dataApi?.data?.resultData?.prizeAmount || [],
            "prizeType": dataApi?.data?.resultData?.prizeType || [],
            "reelIndex": dataApi?.data?.resultData?.reelIndex || [],
            "slotIndex": dataApi?.data?.resultData?.slotIndex || []
          };



          linkPrizeAmount = dataApi?.data?.resultData?.linkPrizeAmount ? dataApi?.data?.resultData?.linkPrizeAmount.split(',') : dataApi?.data?.resultData?.prizeAmount;
          fireSymbolCount = dataApi?.data?.resultData?.winningCombination.split(',');

          countTTSym = fireSymbolCount.filter((symbol) => symbol === 'TT').length;
          console.log(countTTSym, 'countTTSym++')
          console.log(fireSymbolCount, 'fireSymbolCount++')

          //  linkPrizeAmount = dataApi?.data?.resultData?.linkPrizeAmount ? dataApi?.data?.resultData?.linkPrizeAmount : [0,200,50,0,100,1000,0,5000,500,0,50,50,0,200,50]    
          let newSlotArr = dataApi?.data?.resultData?.winningCombination?.split(",");
          //  if(count == 2){
          //   linkPrizeAmount = linkPrizeAmount.split(",");
          //  }

          // use for sticky spin API

          console.log(newSlotArr)

          console.log("IsStickeySpinWon", IsStickeySpinWon)


          creditsVal = dataApi?.data?.resultData?.playerCurrentBalance;
          winValue = (dataApi?.data?.resultData?.finalFreeSpinWinning > 0) ? dataApi?.data?.resultData?.finalFreeSpinWinning : dataApi?.data?.resultData?.winValue
          
          console.log(newSlotArr, creditsVal, "updatedCreditval");

          function getImgWithUrl(name) {
            return slotTextures.filter((el, i) => (el.name === name ? el : ""));
          }
          imgArrSlot = [];
          for (let i = 0; i < newSlotArr.length; i++) {
            imgArrSlot.push(getImgWithUrl(newSlotArr[i])[0]);
          }
          if (
            dataApi?.data?.resultData?.winValue > 0 &&
            dataApi?.data?.resultData?.winningAtPayLines !== " "
          ) {
            
            if((dataApi?.data?.resultData?.winningAtPayLines?.includes(',')) && dataApi?.data?.resultData?.winningAtPayLines?.length > 0){
              paylinesNum = dataApi?.data?.resultData?.winningAtPayLines?.split(",");
              winAmountShow = dataApi?.data?.resultData?.winningByPaylines?.split(",");
              // console.log(multipleLines, 'multipleLines', paylinesNum)
            }else if(dataApi?.data?.resultData?.winningAtPayLines?.length > 0 && dataApi?.data?.resultData?.winningAtPayLines?.length <= 2){
              const singleLine = dataApi?.data?.resultData?.winningAtPayLines;
              const singleWinning = dataApi?.data?.resultData?.winningByPaylines;
              winAmountShow = [];
              paylinesNum.push(singleLine)
              winAmountShow.push(singleWinning)
              console.log(singleLine, singleWinning, 'singleLine', paylinesNum)
            }
            // paylinesNum = (dataApi?.data?.resultData?.winningAtPayLines?.includes(',')) ? dataApi?.data?.resultData?.winningAtPayLines?.split(",") : Array.from(dataApi?.data?.resultData?.winningAtPayLines);
            let payLineAnimate = dataApi?.data?.resultData?.winningIndexes;
            // let payLineAnimate = ""
            payLineAnimateArr = payLineAnimate
              .split("/")
              .map((row) => row.split(" ").map(Number));
            // console.log(payLineAnimateArr, 'payLineAnimateArr--- payLineAnimateArr')
            winningSymbolArr =
              dataApi?.data?.resultData?.winingSymbol?.split(",");

            occurenceArr = dataApi?.data?.resultData?.symbolOccurrence.split(",")
            console.log(occurenceArr,'occurenceArr')
            playWinAudio()
            setTimeout(() => {
              singlePaylinesNum = [...singlePaylinesNum, paylinesNum];
            });
            setTimeout(()=>{
              if (freeGamesWin == true && dataApi?.data?.resultData?.winValue >= 0) {
                // if (currentfreeSpin > 0 && dataApi?.data?.resultData?.winValue > 0) {
                  SpinBtn.visible = false;
                  SpinBtn2.visible = false;
                  bottom.eventMode = "none";
                  bottom.cursor = "none";
                  setTimeout(() => {
                    noOfFreeSpinWonHandle()
                  }, 1000);
                // } else if (currentfreeSpin > 0) {
                } else if (freeGamesWin == true) {
                  bottom.eventMode = "none";
                  bottom.cursor = "none";
                  SpinBtn.visible = false;
                  SpinBtn2.visible = false;
                  noOfFreeSpinWonHandle()
                }
            },1000)
            setTimeout(() => {
              updateCredits(Math.floor(creditsVal / coinDenonimation));
              updateCreditsSubVal(creditsVal)

              UpdateWinValue(winValue);
            }, 3000);

            // setTimeout(() => {
            //   UpdateWinValue(winValue);
            // }, 1000);
            // if (dataApi?.data?.resultData?.symbolOccurrence !== "") {
            if (occurenceArr) {
              createLinesBunch_No(paylinesNum);
            }
            // console.log(paylinesNum, "paylinesNumpaylinesNum");
            // createLines1(); noOfFreeSpinWon

          } else {
          }


          // startPlay()
          return imgArrSlot;
        } catch (error) {
          console.error("Error:", error?.response?.data);
          Swal.fire({
            title: "Error!",
            text: "Server error, Try Again!",
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Retry!"
          }).then((result) => {
            if (result.isConfirmed) {
             startPlay()
            }
          });
        }
      }

      console.log(paylinesNum, 'paylinesNumssssssss')
      // console.log(data, "gameData-1");

      // *****************************  get sprite sheet function by name  *********************************//

      // let slotSpriteSheet = [
      //   "/assets/Exploradora_SpriteSheet.json", //CC
      //   "/assets/Torch_SpriteSheet.json", //FF
      //   "/assets/Skull_SpriteSheet.json", // WW
      //   "/assets/Quetzal_SpriteSheet.json", //EE
      //   "/assets/Piramide_SpriteSheet.json", // SS
      //   "/assets/Moneda_SpriteSheet.json", //GG
      //   "/assets/Gema_SpriteSheet.json" // DD
      // ];

      // Load all the sprite sheet JSON files
      Promise.all(slotSpriteSheet.map(url => Assets.load(url))).then(textures => {
        // console.log("All sprite sheets loaded successfully!");
      }).catch(error => {
        console.error("Error loading sprite sheets:", error);
      });



      let sheetSheet1
      let slotNameArr = ["CC", "FF", "WW", "EE", "SS", "GG", "DD"];

      function getSpriteSheet(name) {
        // return slotSpriteSheet[slotNameArr.indexOf(name)];
        return slotSpriteSheet[slotNameArr.indexOf(name)]
          ? slotSpriteSheet[slotNameArr.indexOf(name)]
          : "PP";
      }
      getSpriteSheet()

      //// 00000000000000000 4
      var removeAnimSprite = [];
      async function animateSpinSprite(img, cordinate) {
       let {xvalue, yvalue} = cordinate;
        let name = await getSpriteSheet(img);
        sheetSheet1 = await Assets.load(name);
        let spriteSheet = new AnimatedSprite(sheetSheet1.animations.enemy);
        spriteSheet.width = app.screen.width / 10;
        spriteSheet.height = window.innerWidth > 480 ? app.screen.height / 5 : app.screen.height / 12;
        spriteSheet.anchor.set(0.5);
        spriteSheet.animationSpeed = 1.5;
        spriteSheet.loop = true;
        spriteSheet.play();
        spriteSheet.y = app.screen.height / yvalue;
        spriteSheet.x = app.screen.width / xvalue;
        app.stage.addChildAt(spriteSheet, 30);
        removeAnimSprite.push(spriteSheet);
      }

      function removeAllSprites() {
        for (let sprite of removeAnimSprite) {
          app.stage.removeChild(sprite);
          sprite.destroy(); // Clean up resources
        }
        removeAnimSprite.length = 0; // Clear the array
      }

      //***************************************************************** */

      const BackgroundImg = new Sprite(
        Texture.from("/assets/MainBG/Dorado_Main_BG.png")
      );
      BackgroundImg.height = app.screen.height;
      BackgroundImg.width = app.screen.width;
      BackgroundImg.x = (app.screen.width - BackgroundImg.width) / 2;
      BackgroundImg.y = (app.screen.height - BackgroundImg.height) / 2;

      app.stage.addChild(BackgroundImg);

      const BackgroundImgmobile = new Sprite(
        Texture.from("/assets/Backgrounds/mobile_background.png")
      );
      BackgroundImgmobile.height = app.screen.height;
      BackgroundImgmobile.width = app.screen.width;
      BackgroundImgmobile.x = (app.screen.width - BackgroundImg.width) / 2;
      BackgroundImgmobile.y = (app.screen.height - BackgroundImg.height) / 2;
      app.stage.addChild(BackgroundImgmobile);
      BackgroundImgmobile.visible = false;

      const stickyBackgroundImg = new Sprite(
        Texture.from("/assets/Link/Fondo-Link-01.png")
      );
      stickyBackgroundImg.height = app.screen.height * 2;
      stickyBackgroundImg.width = app.screen.width;
      stickyBackgroundImg.x = (app.screen.width - BackgroundImg.width) / 2;
      stickyBackgroundImg.y = Y1percent * -80;
      stickyBackgroundImg.visible = false;
      app.stage.addChild(stickyBackgroundImg);

      // bet betVal

      //  const MarginY = 0;
      const MarginY = 0;
      // const MarginY = (app.screen.height - SYMBOL_SIZE * 3) / 2

      // Game Frame Image added here
      const GameFrame = new Sprite(
        Texture.from("/assets/Game/Dorado_frame.png")
      );
      GameFrame.height = app.screen.height / 1.5;
      GameFrame.width = app.screen.width / 1.55;
      GameFrame.x = (app.screen.width - GameFrame.width) / 2; // Center horizontally
      GameFrame.y = (app.screen.height - GameFrame.height) / 2; // Center horizontally
      app.stage.addChild(GameFrame);
      // console.log(GameFrame.width, "GameFrame.width");
      // console.log(GameFrame.height, "GameFrame.height");

      // sticky ganeframe
      const stickyGameFrame = new Sprite(
        Texture.from("/assets/Link/Marco-Link.png")
      );
      stickyGameFrame.height = app.screen.height / 1.4;
      stickyGameFrame.width = app.screen.width / 1.47;
      stickyGameFrame.x = (app.screen.width - GameFrame.width) / 2.2; // Center horizontally
      stickyGameFrame.y = (app.screen.height - GameFrame.height) / 2.5; // Center horizontally
      stickyGameFrame.visible = false;
      app.stage.addChild(stickyGameFrame);


      // Game Frame background
      const GameBG = new Sprite(
        Texture.from("/assets/MainBG/Dorado_background_Game.png")
      );
      GameBG.height = app.screen.height / 1.62;
      GameBG.width = ((app.screen.width - GameFrame.width) * 3.48) / 2;
      GameBG.x = (app.screen.width - GameBG.width) / 2;
      GameBG.y = (app.screen.height - GameBG.height) / 2;

      app.stage.addChild(GameBG);

      // Add play text
      const textStyle = new TextStyle({
        fontSize: betVal === 500 ? app.screen.width / 98 : app.screen.width / 54,
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: (window.innerWidth > 480) ? "bolder" : "normal",
        fill: "#fff",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440
      });



      // Add play text
      const coinDenomTextStyle = new TextStyle({
        fontSize: window.innerWidth > 1440 ? app.screen.width / 50 : app.screen.width / 54,
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: "bolder",
        fill: "#fff",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440
      });

      const top = new Graphics()
        .rect(0, 0, app.screen.width, MarginY)
        .fill({ color: 0x0 });
      const bottom = new Graphics()
        .rect(0, 200 * 3 + MarginY, app.screen.width, MarginY)
        .fill({ color: 0x0 });

      //  ******************* Game Top Section Code*********************

      ////***********   Minor text Section starts  *************
      //Minor Text Frame background Image imported here
      const MinorTextFrameBG = new Sprite(
        Texture.from("/assets/JackPotsLinks/Minor_Bg.png")
      );
      MinorTextFrameBG.height = app.screen.height / 11.5;
      MinorTextFrameBG.width = app.screen.width / 9.5;
      MinorTextFrameBG.x = (app.screen.width - MinorTextFrameBG.width) / 7;
      MinorTextFrameBG.y = (app.screen.height - MinorTextFrameBG.height) / 19;
      app.stage.addChild(MinorTextFrameBG);

      //Minor Text Frame imported here
      const MinorTextFrame = new Sprite(
        Texture.from("/assets/JackPotsLinks/Minor_Frame.png")
      );
      MinorTextFrame.height = app.screen.height / 9;
      MinorTextFrame.width = app.screen.width / 9;
      MinorTextFrame.x = (app.screen.width - MinorTextFrame.width) / 7;
      MinorTextFrame.y = (app.screen.height - MinorTextFrame.height) / 30;
      app.stage.addChild(MinorTextFrame);

      //Minor Text Png imported here
      const MinorTextImg = new Sprite(
        Texture.from("/assets/JackPotsLinks/Minor_TxT.png")
      );
      MinorTextImg.height = app.screen.height / 20;
      MinorTextImg.width = app.screen.width / 18;
      MinorTextImg.x = (app.screen.width - MinorTextImg.width) / 6.1;
      MinorTextImg.y = (app.screen.height - MinorTextImg.height) / 40;
      app.stage.addChild(MinorTextImg);

      // Minor Text added here
      const MinorText = new BitmapText(`$${betVal}.00`, textStyle);
      // MinorText.width = app.screen.width / 20;
      // console.log(window.innerWidth, 'moirorText')
      //MinorText.width = window.innerWidth > 1440 ? app.screen.width / 17.5 : (window.innerWidth > 1366 && window.innerWidth < 1440) ? app.screen.width / 35 : (window.innerWidth > 800 && window.innerWidth < 1367) ? app.screen.width / 28 : app.screen.width / 50;
      MinorText.width = window.innerWidth > 1440 ? app.screen.width / 17.5 : (window.innerWidth > 1366 && window.innerWidth < 1440) ? app.screen.width / 35 : (window.innerWidth > 932 && window.innerWidth < 1367) ? app.screen.width / 28 : (window.innerWidth >= 667 && window.innerWidth <= 932) ? app.screen.width / 20 : app.screen.width / 10;
      MinorText.x = (app.screen.width - MinorText.width) / 6.1;
      MinorText.y = (app.screen.height - MinorTextImg.height) / 12.5;
      top.addChild(MinorText);
      MinorText.visible = true;

      const stickyMinorText = new BitmapText(`$${betVal}.00`, textStyle);
      // MinorText.width = app.screen.width / 20;
      // console.log(window.innerWidth, 'moirorText')
      stickyMinorText.width = window.innerWidth > 1440 ? app.screen.width / 17.5 : (window.innerWidth > 1366 && window.innerWidth < 1440) ? app.screen.width / 35 : (window.innerWidth > 800 && window.innerWidth < 1367) ? app.screen.width / 28 : app.screen.width / 50;
      stickyMinorText.x = (app.screen.width - stickyMinorText.width) / 4.5;
      stickyMinorText.y = (app.screen.height - MinorTextImg.height) / 12.5;
      top.addChild(stickyMinorText);
      stickyMinorText.visible = false;

      ////***************   Grand text Section strats   ***************
      //Grand Text Frame background Image imported here
      const GrandTextFrameBG = new Sprite(
        Texture.from("/assets/JackPotsLinks/Grand_Bg.png")
      );
      GrandTextFrameBG.height = app.screen.height / 8.5;
      GrandTextFrameBG.width = app.screen.width / 5.8;
      GrandTextFrameBG.x = (app.screen.width - GrandTextFrameBG.width) / 2.65;
      GrandTextFrameBG.y = (app.screen.height - GrandTextFrameBG.height) / 25;
      app.stage.addChild(GrandTextFrameBG);

      //Grand Text Frame imported here
      const GrandTextFrame = new Sprite(
        Texture.from("/assets/JackPotsLinks/Grand_Frame.png")
      );
      GrandTextFrame.height = app.screen.height / 7.5;
      GrandTextFrame.width = app.screen.width / 5;
      GrandTextFrame.x = (app.screen.width - GrandTextFrame.width) / 2.7;
      GrandTextFrame.y = (app.screen.height - GrandTextFrame.height) / 30;
      app.stage.addChild(GrandTextFrame);

      //Grand Text Frame imported here
      const GrandTextImg = new Sprite(
        Texture.from("/assets/JackPotsLinks/Grand_TxT.png")
      );
      GrandTextImg.height = app.screen.height / 20;
      GrandTextImg.width = app.screen.width / 16;
      GrandTextImg.x = (app.screen.width - GrandTextImg.width) / 2.55;
      GrandTextImg.y = (app.screen.height - GrandTextImg.height) / 100;
      app.stage.addChild(GrandTextImg);

      // Add Grand text
      const GrandText = new BitmapText("$100,000.00", textStyle);
      GrandText.width = app.screen.width / 9;
      GrandText.x = window.innerWidth > 1600 ? (app.screen.width - GrandTextImg.width) / 2.7 : window.innerWidth > 1367 && window.innerWidth < 1599 ? (app.screen.width - GrandTextImg.width) / 2.75 : (app.screen.width - GrandTextImg.width) / 2.7;
      GrandText.y = (app.screen.height - MinorTextImg.height) / 12.5;
      top.addChild(GrandText);
      app.stage.addChild(top);

      ////***************   Major text Section starts   ***************
      //Major Text Frame background Image imported here
      const MajorTextFrameBG = new Sprite(
        Texture.from("/assets/JackPotsLinks/Major_Bg.png")
      );
      MajorTextFrameBG.height = app.screen.height / 8.5;
      MajorTextFrameBG.width = app.screen.width / 5.21;
      MajorTextFrameBG.x = (app.screen.width - MajorTextFrameBG.width) / 1.609;
      MajorTextFrameBG.y = (app.screen.height - MajorTextFrameBG.height) / 25;
      app.stage.addChild(MajorTextFrameBG);

      //Grand Text Frame imported here
      const MajorTextFrame = new Sprite(
        Texture.from("/assets/JackPotsLinks/Major_Frame.png")
      );
      MajorTextFrame.height = app.screen.height / 7.4;
      MajorTextFrame.width = app.screen.width / 5;
      MajorTextFrame.x = (app.screen.width - MajorTextFrame.width) / 1.6;
      MajorTextFrame.y = (app.screen.height - MajorTextFrame.height) / 40;
      app.stage.addChild(MajorTextFrame);

      //Major Text Frame imported here
      const MajorTextImg = new Sprite(
        Texture.from("/assets/JackPotsLinks/Major_TxT.png")
      );
      MajorTextImg.height = app.screen.height / 18;
      MajorTextImg.width = app.screen.width / 15;
      MajorTextImg.x = (app.screen.width - MajorTextImg.width) / 1.65;
      MajorTextImg.y = (app.screen.height - MajorTextImg.height) / 100;
      app.stage.addChild(MajorTextImg);

      // Add Major text
      const MajorText = new BitmapText("$10,000.00", textStyle);
      MajorText.width = app.screen.width / 8;
      MajorText.x = window.innerWidth > 1440 ? (app.screen.width - MajorTextImg.width) / 1.72 : (app.screen.width - MajorTextImg.width) / 1.75;
      MajorText.y = (app.screen.height - MinorTextImg.height) / 12.5;
      top.addChild(MajorText);
      app.stage.addChild(top);

      ////***************   Mini text section starts   ***************
      //Mini Text Frame background Image imported here
      const MiniTextFrameBG = new Sprite(
        Texture.from("/assets/JackPotsLinks/Mini_Bg.png")
      );
      MiniTextFrameBG.height = app.screen.height / 11.5;
      MiniTextFrameBG.width = app.screen.width / 11.5;
      MiniTextFrameBG.x = (app.screen.width - MiniTextFrameBG.width) / 1.186;
      MiniTextFrameBG.y = (app.screen.height - MiniTextFrameBG.height) / 19;
      app.stage.addChild(MiniTextFrameBG);

      //Mini Text Frame imported here
      const MiniTextFrame = new Sprite(
        Texture.from("/assets/JackPotsLinks/Mini_Frame.png")
      );
      MiniTextFrame.height = app.screen.height / 9;
      MiniTextFrame.width = app.screen.width / 9;
      MiniTextFrame.x = (app.screen.width - MiniTextFrame.width) / 1.18;
      MiniTextFrame.y = (app.screen.height - MiniTextFrame.height) / 30;
      app.stage.addChild(MiniTextFrame);

      //Mini Text Png imported here
      const MiniTextImg = new Sprite(
        Texture.from("/assets/JackPotsLinks/Mini_TxT.png")
      );
      MiniTextImg.height = app.screen.height / 20;
      MiniTextImg.width = app.screen.width / 18;
      MiniTextImg.x = (app.screen.width - MiniTextImg.width) / 1.2;
      MiniTextImg.y = (app.screen.height - MiniTextImg.height) / 40;
      app.stage.addChild(MiniTextImg);

      // Add Mini text
      const MiniText = new BitmapText(`$${10}.00`, textStyle);
      // MiniText.width = app.screen.width / 20;
      //MiniText.width = window.innerWidth > 1440 ? app.screen.width / 17.5 : (window.innerWidth > 1366 && window.innerWidth < 1440) ? app.screen.width / 35 : (window.innerWidth > 800 && window.innerWidth < 1367) ? app.screen.width / 30 : app.screen.width / 50;
      MiniText.width = window.innerWidth > 1440 ? app.screen.width / 17.5 : (window.innerWidth > 1366 && window.innerWidth < 1440) ? app.screen.width / 35 : (window.innerWidth > 932 && window.innerWidth < 1367) ? app.screen.width / 30 : (window.innerWidth >= 667 && window.innerWidth <= 932) ? app.screen.width / 20 : app.screen.width / 10;
      // MinorText.x = (app.screen.width - MinorText.width) / 6.15;
      MiniText.x = (app.screen.width - GrandTextImg.width) / 1.185;
      MiniText.y = (app.screen.height - MinorTextImg.height) / 12.5;
      top.addChild(MiniText);
      MiniText.visible = true;

      //Mini Text Frame background Image imported here
      const ExploradoraSimbolo = new Sprite(
        Texture.from("/assets/Slots/Slot_Sprite/Exploradora_Simbolo.png")
      );
      ExploradoraSimbolo.height = Y1percent * 30;
      ExploradoraSimbolo.width = X1percent * 50;
      ExploradoraSimbolo.x = X1percent * 25;
      ExploradoraSimbolo.y = Y1percent * 3;
      ExploradoraSimbolo.visible = false;
      app.stage.addChild(ExploradoraSimbolo);


      const stickyMiniText = new BitmapText(`$${10}.00`, textStyle);
      // MinorText.width = app.screen.width / 20;
      // console.log(window.innerWidth, 'moirorText')
      stickyMiniText.width = window.innerWidth > 1440 ? app.screen.width / 17.5 : (window.innerWidth > 1366 && window.innerWidth < 1440) ? app.screen.width / 35 : (window.innerWidth > 800 && window.innerWidth < 1367) ? app.screen.width / 28 : app.screen.width / 50;
      stickyMiniText.x = (app.screen.width - stickyMiniText.width) / 1.295;
      stickyMiniText.y = (app.screen.height - MinorTextImg.height) / 12.5;
      top.addChild(stickyMiniText);
      stickyMiniText.visible = false;



      // sticky spin buttons***********
      ////***********   Minor text Section starts  *************
      //Minor Text Frame background Image imported here


      const stickySpinBg = new Sprite(Texture.from('/assets/MainBG/Dorado_Sticky_background.png'));
      stickySpinBg.height = app.screen.height;
      stickySpinBg.width = app.screen.width;
      stickySpinBg.visible = false;
      stickySpinBg.x = 0;
      stickySpinBg.y = 0;

      const MinorStickyTextFrameBG = new Sprite(
        Texture.from("/assets/Link/Minor/Minor-Jackpot-Base.png")
      );
      MinorStickyTextFrameBG.height = Y1percent * 10.9;
      MinorStickyTextFrameBG.width = X1percent * 11.5;
      MinorStickyTextFrameBG.x = X1percent * 17.5;
      MinorStickyTextFrameBG.y = Y1percent * 3.5;
      MinorStickyTextFrameBG.visible = false;
      app.stage.addChild(MinorStickyTextFrameBG);

      //Minor Text Frame imported here
      const MinorStickyTextFrame = new Sprite(
        Texture.from("/assets/Link/Minor/Minor-Jackpot-Contorno.png")
      );
      MinorStickyTextFrame.height = Y1percent * 11.2;
      MinorStickyTextFrame.width = X1percent * 11.5;
      MinorStickyTextFrame.x = X1percent * 17.5;
      MinorStickyTextFrame.y = Y1percent * 3.5;
      MinorStickyTextFrame.visible = false;
      app.stage.addChild(MinorStickyTextFrame);

      //Minor Text Png imported here
      const MinorStickyTextImg = new Sprite(
        Texture.from("/assets/Link/Minor/Minor-Jackpot-Nombre.png")
      );
      MinorStickyTextImg.height = app.screen.height / 18;
      MinorStickyTextImg.width = app.screen.width / 17;
      MinorStickyTextImg.x = (app.screen.width - MinorTextImg.width) / 4.7;
      MinorStickyTextImg.y = (app.screen.height - MinorTextImg.height) / 40;
      MinorStickyTextImg.visible = false;
      app.stage.addChild(MinorStickyTextImg);

      //Minor Text Png imported here
      const MinorStickyFrameDesign = new Sprite(
        Texture.from("/assets/Link/Minor/Minor-Jackpot-Fuego.png")
      );
      MinorStickyFrameDesign.height = Y1percent * 10.5;
      MinorStickyFrameDesign.width = app.screen.width / 17;
      MinorStickyFrameDesign.x = X1percent * 17;
      MinorStickyFrameDesign.y = Y1percent * 4;
      MinorStickyFrameDesign.visible = false;
      app.stage.addChild(MinorStickyFrameDesign);


      ////***************   Grand text Section strats   ***************



      const linkHitImg = new AnimatedSprite(linkHitSheet.animations.enemy);
      const linkHitImg2 = new AnimatedSprite(linkHitSheet.animations.enemy);

      function playLinkHitAnimation() {
        linkHitImg.animationSpeed = 0.5;
        linkHitImg.loop = false;
        // app.stage.removeChild(subWinTextvalue)
        // subWinTextvalue.alpha=0
        linkHitImg.play();
        linkHitImg.width = app.screen.width;
        linkHitImg.height = app.screen.height;

        // app.stage.addChild(linkHitImg)

        setTimeout(() => {
          LinkHitScream.play()
        }, 1000)
        setTimeout(() => {
          LinkHitMusic.play()
          LinkHitMusic.loop = true;
          LinkHitRing.play()
          LinkHitRing2.play()
        }, 1800)
      }

      async function switchNormalSpin() {


        linkHitImg2.animationSpeed = 0.5;
        linkHitImg2.loop = false;
        linkHitImg2.play();
        linkHitImg2.width = app.screen.width;
        linkHitImg2.height = app.screen.height;
        app.stage.addChild(linkHitImg2)
        LinkHitMusic.pause()



        app.stage.removeChild(stickyContainer)
        for (let i = 0; i < 5; i++) {
          for (let j = 0; j < 3; j++) {
            reels[i].container.children[j].visible = true;
          }
        }
        data = await gameLoadDataApi();
        creditsVal = data?.playerBalance || 0;
        currentfreeSpinGameLoad = data?.noOfRemaningFreeSpin || 0
      }





      //Grand Text Frame background Image imported here
      const GrandstickyTextFrameBG = new Sprite(
        Texture.from("/assets/Link/Grand/Grand-Jackpot-Base.png")
      );
      GrandstickyTextFrameBG.height = Y1percent * 14.5;
      GrandstickyTextFrameBG.width = X1percent * 20;
      GrandstickyTextFrameBG.x = X1percent * 29.5;
      GrandstickyTextFrameBG.y = Y1percent * 2.5;
      GrandstickyTextFrameBG.visible = false;
      app.stage.addChild(GrandstickyTextFrameBG);

      //Grand Text Frame imported here
      const GrandstickyTextFrame = new Sprite(
        Texture.from("/assets/Link/Grand/Grand-Jackpot-Contorno.png")
      );
      GrandstickyTextFrame.height = app.screen.height / 7.5;
      GrandstickyTextFrame.width = app.screen.width / 5;
      GrandstickyTextFrame.x = (app.screen.width - GrandstickyTextFrame.width) / 2.7;
      GrandstickyTextFrame.y = (app.screen.height - GrandstickyTextFrame.height) / 30;
      GrandstickyTextFrame.visible = false;
      app.stage.addChild(GrandstickyTextFrame);

      //Grand Text Frame imported here
      const GrandstickyTextImg = new Sprite(
        Texture.from("/assets/Link/Grand/Grand-Jackpot-Nombre.png")
      );
      GrandstickyTextImg.height = app.screen.height / 10;
      GrandstickyTextImg.width = app.screen.width / 8;
      GrandstickyTextImg.x = X1percent * 34;
      GrandstickyTextImg.y = Y1percent * -2;
      GrandstickyTextImg.visible = false;
      app.stage.addChild(GrandstickyTextImg);

      //Grand Text Frame imported here
      const GrandStickyFrameDesign = new Sprite(
        Texture.from("/assets/Link/Grand/Grand-Jackpot-Fuego.png")
      );
      GrandStickyFrameDesign.height = Y1percent * 13;
      GrandStickyFrameDesign.width = X1percent * 20.3;
      GrandStickyFrameDesign.x = X1percent * 29.5;
      GrandStickyFrameDesign.y = Y1percent * 2.7;
      GrandStickyFrameDesign.visible = false;
      app.stage.addChild(GrandStickyFrameDesign);

      ////***************   Major text Section starts   ***************
      //Major Text Frame background Image imported here
      const MajorstickyTextFrameBG = new Sprite(
        Texture.from("/assets/Link/Major/Major-Jackpot-Base.png")
      );
      MajorstickyTextFrameBG.height = X1percent * 6;
      MajorstickyTextFrameBG.width = Y1percent * 40;
      MajorstickyTextFrameBG.x = X1percent * 50;
      MajorstickyTextFrameBG.y = Y1percent * 3.5;
      MajorstickyTextFrameBG.visible = false;
      app.stage.addChild(MajorstickyTextFrameBG);

      //Grand Text Frame imported here
      const MajorstickyTextFrame = new Sprite(
        Texture.from("/assets/Link/Major/Major-Jackpot-Contorno.png")
      );
      MajorstickyTextFrame.height = app.screen.height / 8;
      MajorstickyTextFrame.width = app.screen.width / 5;
      MajorstickyTextFrame.x = (app.screen.width - MajorstickyTextFrame.width) / 1.6;
      MajorstickyTextFrame.y = Y1percent * 3.5;
      MajorstickyTextFrame.visible = false;
      app.stage.addChild(MajorstickyTextFrame);

      //Major Text Frame imported here
      const MajorstickyTextImg = new Sprite(
        Texture.from("/assets/Link/Major/Major-Jackpot-Nombre.png")
      );
      MajorstickyTextImg.height = Y1percent * 8;
      MajorstickyTextImg.width = X1percent * 10;
      MajorstickyTextImg.x = X1percent * 55;
      MajorstickyTextImg.y = Y1percent * -1.3;
      MajorstickyTextImg.visible = false;
      app.stage.addChild(MajorstickyTextImg);

      //Major Text Frame imported here
      const MajorStickyFrameDesign = new Sprite(
        Texture.from("/assets/Link/Major/Major-Jackpot-Fuego.png")
      );
      MajorStickyFrameDesign.height = Y1percent * 13;
      MajorStickyFrameDesign.width = X1percent * 20;
      MajorStickyFrameDesign.x = X1percent * 50;
      MajorStickyFrameDesign.y = Y1percent * 3.2;
      MajorStickyFrameDesign.visible = false;
      app.stage.addChild(MajorStickyFrameDesign);

      ////***************   Mini text section starts   ***************
      //Mini Text Frame background Image imported here
      const MinistickyTextFrameBG = new Sprite(
        Texture.from("/assets/Link/Mini/Minir-Jackpot-Base.png")
      );
      MinistickyTextFrameBG.height = Y1percent * 11;
      MinistickyTextFrameBG.width = X1percent * 11;
      MinistickyTextFrameBG.x = X1percent * 70.5;
      MinistickyTextFrameBG.y = Y1percent * 3.9;
      MinistickyTextFrameBG.visible = false;
      app.stage.addChild(MinistickyTextFrameBG);

      //Mini Text Frame imported here
      const MinistickyTextFrame = new Sprite(
        Texture.from("/assets/Link/Mini/Minir-Jackpot-Contorno.png")
      );
      MinistickyTextFrame.height = app.screen.height / 9;
      MinistickyTextFrame.width = app.screen.width / 9.2;
      MinistickyTextFrame.x = X1percent * 70.5;
      MinistickyTextFrame.y = Y1percent * 3.9;
      MinistickyTextFrame.visible = false;
      app.stage.addChild(MinistickyTextFrame);

      //Mini Text Png imported here
      const MinistickyTextImg = new Sprite(
        Texture.from("/assets/Link/Mini/Mini-Jackpot-Nombre.png")
      );
      MinistickyTextImg.height = app.screen.height / 20;
      MinistickyTextImg.width = X1percent * 5;
      MinistickyTextImg.x = (app.screen.width - MinistickyTextImg.width) / 1.3;
      MinistickyTextImg.y = (app.screen.height - MinistickyTextImg.height) / 40;
      MinistickyTextImg.visible = false;
      app.stage.addChild(MinistickyTextImg);

      //Mini Text Frame imported here
      const MiniStickyFrameDesign = new Sprite(
        Texture.from("/assets/Link/Mini/Mini-Jackpot-Fuego.png")
      );
      MiniStickyFrameDesign.height = Y1percent * 11;
      MiniStickyFrameDesign.width = X1percent * 5;
      MiniStickyFrameDesign.x = X1percent * 76.5;
      MiniStickyFrameDesign.y = Y1percent * 3.9;
      MiniStickyFrameDesign.visible = false;
      app.stage.addChild(MiniStickyFrameDesign);


      //sticky remianing  count text

      // Add play text
      const textStyleremaining = new TextStyle({
        fontSize: window.innerWidth > 480 ? app.screen.width / 54 : app.screen.width / 15,
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: (window.innerWidth > 480) ? "bolder" : "normal",
        fill: "#fff",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440
      });


      let remainingStickyCountainer = new Container()
      const stickyspinoverlayFrame = new Sprite(Texture.from("/assets/Link/Total-Win-Base.png"));
      stickyspinoverlayFrame.width = X1percent * 31
      stickyspinoverlayFrame.height = Y1percent * 8;
      stickyspinoverlayFrame.x = X1percent * 35
      stickyspinoverlayFrame.y = Y1percent * 2
      stickyspinoverlayFrame.visible = true;

      const stickyremainingText = new BitmapText(`Remaining Count`, textStyleremaining);
      stickyremainingText.x = X1percent * 40;
      stickyremainingText.y = Y1percent * 14.2;
      stickyremainingText.visible = true;

      const stickyremainingCount = new BitmapText(`0`, textStyleremaining);
      stickyremainingCount.x = X1percent * 56;
      stickyremainingCount.y = Y1percent * 14.2;
      stickyremainingCount.visible = true;


      // Add play text
      const textStyles = new TextStyle({
        fontSize: betVal === 500 ? app.screen.width / 70 : app.screen.width / 40,
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: "bolder",
        fill: "#fff",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440
      });

      const Stickywin = new BitmapText(`0`, textStyles);
      Stickywin.x = X1percent * 49;
      Stickywin.y = window.innerWidth > 480 ? Y1percent * 9.5 : Y1percent * 8.5;
      Stickywin.anchor.set(0.5)
      Stickywin.scale.set((window.innerWidth > 480) ? 1 : 2.5)
      console.log('Stickywin', typeof (Number(Stickywin.text)))
      Stickywin.visible = false;

      const stickyspinBGFrame = new Sprite(Texture.from("/assets/Link/Total-Win-Contorno.png"));
      stickyspinBGFrame.width = X1percent * 30
      stickyspinBGFrame.height = Y1percent * 8;
      stickyspinBGFrame.x = X1percent * 35
      stickyspinBGFrame.y = Y1percent * 13
      stickyspinBGFrame.visible = true;

      const stickyspinTotalwinImg = new Sprite(Texture.from("/assets/Link/Total-Win-Text.png"));
      stickyspinTotalwinImg.width = X1percent * 20
      stickyspinTotalwinImg.height = Y1percent * 6;
      stickyspinTotalwinImg.x = X1percent * 40
      stickyspinTotalwinImg.y = Y1percent
      stickyspinTotalwinImg.visible = false;

      remainingStickyCountainer.visible = false;
      remainingStickyCountainer.addChild(stickyspinoverlayFrame)
      remainingStickyCountainer.addChild(stickyspinBGFrame)
      remainingStickyCountainer.addChild(stickyremainingText)
      remainingStickyCountainer.addChild(stickyremainingCount)
      remainingStickyCountainer.addChild(stickyspinTotalwinImg)
      remainingStickyCountainer.addChild(Stickywin)
      // remainingStickyCountainer.y=-100
      top.addChild(remainingStickyCountainer);
      // app.stage.addChild(remainingStickyCountainer);
      app.stage.addChild(stickyContainer)
      // sticky space filed container 

      let stickySpaceFilledCountContainer = new Container();

      const stickySpinBootomText = new Text(window.innerWidth > 480 ? `Spaces \nFilled` : `Spaces Filled`, {
        fontFamily: "Arial , roboto, sans-serif",
        fontSize: window.innerWidth > 480 ? Y1percent * 3.8 : Y1percent * 2.8,
        fill: "#fff",
        align: 'left',
        wordWrap: true, // Add this line
        wordWrapWidth: X1percent * 10 // Adjust the width to your liking
      });
      // stickySpinBootomText.width =  X1percent * 10;
      // stickySpinBootomText.height = Y1percent * 8;
      stickySpinBootomText.x = X1percent * 75
      stickySpinBootomText.y = Y1percent * 88
      stickySpinBootomText.visible = true

      const stickyspinBottomCount = new Sprite(Texture.from("/assets/Backgrounds/Hud_tutoriales.png"));
      stickyspinBottomCount.width = X1percent * 8;
      stickyspinBottomCount.height = X1percent * 8;
      stickyspinBottomCount.x = X1percent * 85;
      stickyspinBottomCount.y = Y1percent * 85;
      stickyspinBottomCount.visible = true

      const textStylespacefilled = new TextStyle({
        fontSize: window.innerWidth > 480 ? Y1percent * 5 : Y1percent * 3.5,
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: "bolder",
        fill: "#fff",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440
      });
      const stickySpinBootomTextCount = new BitmapText(`${spaceFilledWtihFire}`, textStylespacefilled);
      //const stickySpinBootomTextCount = new BitmapText(`0`, textStylespacefilled);
      stickySpinBootomTextCount.x = stickySpinBootomTextCount.text.length == 2 ? X1percent * 87.5 : X1percent * 88.2;
      stickySpinBootomTextCount.y = Y1percent * 89.2;
      stickySpinBootomTextCount.visible = true;
      stickySpaceFilledCountContainer.addChild(stickySpinBootomText)
      stickySpaceFilledCountContainer.addChild(stickyspinBottomCount)
      stickySpaceFilledCountContainer.addChild(stickySpinBootomTextCount)
      stickySpaceFilledCountContainer.visible = false;
      app.stage.addChild(stickySpaceFilledCountContainer)

      //  ******************* Game Bottom Section Code*********************

      // ********* Win Image imported
      const WinBg = new Sprite(Texture.from("/assets/Backgrounds/Hud_Won.png"));
      WinBg.height = app.screen.height / 7;
      WinBg.width = app.screen.width / 5;
      WinBg.x = (app.screen.width - WinBg.width) / 2;
      WinBg.y = (app.screen.height - WinBg.height) / 1;
      app.stage.addChild(WinBg);

      //bottom section text style starts
      const betTextStyle = new TextStyle({
        fontSize: app.screen.width / 55,
        fontWeight: "normal",
        align: "center",
        fill: "#ffffff",
        fontFamily: "Arial , roboto, sans-serif",
        stroke: window.innerWidth > 480 ? { color: "#000", width: 4 } : { color: "#000", width: 2 },
        wordWrap: true,
        wordWrapWidth: 440
      });


      const betSubTextStyle = new TextStyle({
        align: "center",
        fontSize: app.screen.width / 80,
        fontWeight: "normal",
        fill: "#00b8b7",
        fontFamily: "Arial , roboto, sans-serif",
        stroke: window.innerWidth > 480 ? { color: "#000", width: 2 } : { color: "#000", width: 1 },
        wordWrap: true,
        wordWrapWidth: 440
      });


      // ********* Credit Image imported
      const CreditsBg = new Sprite(
        Texture.from("/assets/Backgrounds/Hud_credits.png")
      );
      CreditsBg.height = app.screen.height / 7;
      CreditsBg.width = app.screen.width / 7;
      CreditsBg.x = (app.screen.width - CreditsBg.width) / 3.4;
      CreditsBg.y = (app.screen.height - CreditsBg.height) / 1;
      app.stage.addChild(CreditsBg);

      // Circular Image/Denomination text added
      const CreditText = new BitmapText("CREDITS", betTextStyle);
      CreditText.x = (app.screen.width - CreditText.width) / 3.25;
      CreditText.y = (app.screen.height - CreditsBg.height) / 1.02;
      app.stage.addChild(CreditText);

      // Credit text code starts here
      // let creditsVal = 0;

      // var creditsValue = new Text(`10,000,160`, betTextStyle);
      var creditsValue = new BitmapText(`${creditsVal.toLocaleString()}`, betTextStyle);
      creditsValue.x = creditsValue.text.length === 3 ? X1percent * 30.8 : creditsValue.text.length === 12 ? X1percent * 27 : creditsValue.text.length === 5 ? X1percent * 30 : creditsValue.text.length === 6 ? X1percent * 29.5 : creditsValue.text.length === 7 ? X1percent * 28.8 : creditsValue.text.length === 8 ? X1percent * 29 : creditsValue.text.length === 9 ? X1percent * 28.3 : creditsValue.text.length === 11 ? X1percent * 27.5 : creditsValue.text.length === 10 ? X1percent * 27.5 : creditsValue.text.length === 2 ? X1percent * 31.5 : X1percent * 32;
      creditsValue.y = (app.screen.height - CreditsBg.height) / 0.97;
      app.stage.addChild(creditsValue);

      var creditsInput = parseFloat(creditsVal / 100);
      let creditsOutput =
        creditsInput % 1 === 0
          ? creditsInput.toFixed(2).replace(/\.0$/, "")
          : creditsInput.toFixed(2);
      //creditsSubValue
      const creditsSubValue = new BitmapText(`$${creditsOutput}`, betSubTextStyle);
      creditsSubValue.x = (app.screen.width - creditsSubValue.width) / 3.19;
      creditsSubValue.y = (app.screen.height - CreditsBg.height) / 0.92;
      app.stage.addChild(creditsSubValue);

      function updateCredits(creditsVal) {
        app.stage.removeChild(creditsValue);
        let creditsValueQ = new BitmapText(`${creditsVal.toLocaleString()}`, betTextStyle);
        if (window.innerWidth > 480) {
          creditsValueQ.x = (creditsVal == 0 ? GameFrame.width - CreditText.width / 1.2 : GameFrame.width - CreditText.width) / 2.01;
          creditsValueQ.y = (app.screen.height - CreditsBg.height) / 0.97;
        } else {
          creditsValueQ.x = creditsValueQ.text.length === 3 ? X1percent * 10 : creditsValueQ.text.length === 12 ? 10 : creditsValueQ.text.length === 5 ? X1percent * 10 : creditsValueQ.text.length === 6 ? X1percent * 12 : creditsValueQ.text.length === 7 ? X1percent * 10 : creditsValueQ.text.length === 8 ? X1percent * 10 : creditsValueQ.text.length === 9 ? X1percent * 10 : creditsValueQ.text.length === 11 ? X1percent * 10 : creditsValueQ.text.length === 10 ? X1percent * 10 : creditsValueQ.text.length === 2 ? X1percent * 15 : X1percent * 15;
          creditsValueQ.y = Y1percent * 72;
          creditsValueQ.height = Y1percent * 3.5;
        }
        app.stage.addChild(creditsValueQ);
        creditsValue = creditsValueQ;
        console.log(creditsValue.text, creditsValueQ.text)
        responsiveTextMiniMinor()
      }
      function updateCreditsSubVal() {
        let input = parseFloat(creditsVal / 100);
        let output =
          input % 1 === 0
            ? input.toFixed(2).replace(/\.0$/, "")
            : input.toFixed(2);
        creditsSubValue.text = `$${output}`;
        if (window.innerWidth > 480) creditsValue.x = creditsValue.text.length === 3 ? X1percent * 30.8 : creditsValue.text.length === 12 ? X1percent * 27 : creditsValue.text.length === 5 ? X1percent * 30 : creditsValue.text.length === 6 ? X1percent * 29.5 : creditsValue.text.length === 7 ? X1percent * 28.8 : creditsValue.text.length === 8 ? X1percent * 29 : creditsValue.text.length === 9 ? X1percent * 28.3 : creditsValue.text.length === 11 ? X1percent * 27.5 : creditsValue.text.length === 10 ? X1percent * 27.5 : creditsValue.text.length === 2 ? X1percent * 31.5 : X1percent * 32;

      }
      //credits text added

      // ********* Bet Image imported/added
      const BetBg = new Sprite(Texture.from("/assets/Backgrounds/Hud_Bet.png"));
      BetBg.height = app.screen.height / 7;
      BetBg.width = app.screen.width / 7;
      BetBg.x = (app.screen.width - BetBg.width) / 1.42;
      BetBg.y = (app.screen.height - BetBg.height) / 1;
      app.stage.addChild(BetBg);

      // Bet text added
      const BetText = new BitmapText("BET", betTextStyle);
      BetText.x = (app.screen.width - BetText.width) / 1.46;
      BetText.y = (app.screen.height - BetBg.height) / 1.02;
      app.stage.addChild(BetText);

      // Bet text added
      const BetValue = new BitmapText(`${betVal}`, betTextStyle);
      BetValue.x = (app.screen.width - BetText.width) / 1.455;
      BetValue.y = (app.screen.height - BetBg.height) / 0.97;
      app.stage.addChild(BetValue);

      // Bet text added
      let input = parseFloat(betVal / 100);
      let BetSuboutput =
        input % 1 === 0
          ? input.toFixed(2).replace(/\.0$/, "")
          : input.toFixed(2);
      //BetSubValue
      const BetSubValue = new BitmapText(`$${BetSuboutput}`, betSubTextStyle);
      BetSubValue.x = (app.screen.width - BetText.width) / 1.466;

      // BetSubValue.x = (BetSubValue.lenght==9) (app.screen.width - BetText.width) / 1.465;
      BetSubValue.y = (app.screen.height - BetBg.height) / 0.92;
      app.stage.addChild(BetSubValue);

      const winTextStyle = new TextStyle({
        fontSize: app.screen.width / 45,
        fontWeight: 400,
        fontFamily: "Arial , roboto, sans-serif",
        fill: "#ffff00",
        stroke: window.innerWidth > 480 ? { color: "#000", width: 3 } : { color: "#000", width: 2 },
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 4
        },
        wordWrap: true,
        wordWrapWidth: 440
      });


      var winTextValStyle = new TextStyle({
        fontSize: app.screen.width / 45,
        fontWeight: 400,
        fontFamily: "Arial , roboto, sans-serif",
        fill: "#fff",
        stroke: window.innerWidth > 480 ? { color: "#000", width: 5 } : { color: "#000", width: 2 },
        dropShadow: window.innerWidth > 480 ? {color: 0x000000, angle: Math.PI / 6, blur: 4, distance: 6 } : { color: 0x000000, angle: Math.PI / 6, blur: 1, distance: 2},
        wordWrap: true,
        wordWrapWidth: 440
      });



      // Win text added
      const WinText = new BitmapText("WIN", winTextStyle);
      WinText.x = (app.screen.width - WinText.width) / 2;
      WinText.y = (app.screen.height - BetBg.height) / 1.03;
      app.stage.addChild(WinText);

      // Win text value added
      var WinTextvalue = new BitmapText(`${winValue}`, winTextValStyle);
      WinTextvalue.x = winValue.text === 0 ? (app.screen.width - WinText.width) / 1.95 : (app.screen.width - WinText.width) / 1.96;
      WinTextvalue.y = (app.screen.height - BetBg.height) / 0.97;
      app.stage.addChild(WinTextvalue);

      // let updateWinVal = 0;

      // function UpdateWinValue(value) {
      //   // console.log(winValue, 'winValue=====winValue')
      //   if (value === 0) {
      //     showWinValue(value)
      //   } else {
      //     for (let i = 1; i <= value; i++) {
      //       setTimeout(() => {
      //         // console.log(i);
      //         showWinValue(i)
      //       }, i * 5); // Delay increases with each iteration UpdateWinValue
      //     }
      //   }
      // }




      function UpdateWinValue(value) {
        subWinTextvalue.x = X1percent * 47.5;
        if (freeSpinType == true) {
          // if(paylinesNum == ''){
          //   removingfunction()
          // }
          let freespinInterval = setInterval(() => {
            
            if (value > 0) {
              if (freespinWinvalue == value) {
                clearInterval(freespinInterval)
                if (freeSpin < 1) {
                  if (freespinWinvalue >= 0) {
                    setTimeout(() => {
                      freeSpinType = false;
                      freeSpinPopupContainer.visible = true;
                      freeSpinCredits.text = `${freespinWinvalue}`
                      bottom.eventMode = "none";
                      bottom.cursor = "none";
                    }, 100)
                  }

                  else {
                    freeSpinPopupContainer.visible = true;
                    bottom.eventMode = "static";
                    bottom.cursor = "pointer";
                    SpinBtn.visible = false;
                    SpinBtn2.visible = false;
                  }
                } else if (freeSpin == 0 && winValue == 0) {
                  setTimeout(() => {
                    freeSpinPopupContainer.visible = true;
                    bottom.eventMode = "static";
                    bottom.cursor = "pointer";
                    SpinBtn.visible = true;
                    SpinBtn2.visible = true;
                    // alert("Your total Winning is : " + freespinWinvalue)
                  }, 1000)
                }
              } else {
                freespinWinvalue = freespinWinvalue + 1
                showWinValue(freespinWinvalue)
              }
            }
          }, 20)
        } else {
          //reset free spin value 
          freespinWinvalue = 0;
          if (value === 0) {
            showWinValue(value)
          } else {
            showWinValue(value)
            // for (let i = 1; i <= value; i++) {
            //   setTimeout(() => {
            //     // console.log(i);
            //     showWinValue(i)
            //   }, i * 1); // Delay increases with each iteration UpdateWinValue

            //   // if(i == value && stickyBackgroundImg.visible == true){
            //   //   setTimeout(() => {
            //   //     backToPreviousLayout();
            //   //   }, 1500);
            //   // }
            // }
          }
        }



      }


      var subWinTextvalue = new BitmapText(`$${'0.00'}`, betSubTextStyle);
      subWinTextvalue.x = X1percent * 47.5;
      subWinTextvalue.y = (app.screen.height - BetBg.height) / 0.91;
      app.stage.addChild(subWinTextvalue);

      // function showWinValue(winVal) {
      //   app.stage.removeChild(WinTextvalue);
      //   app.stage.removeChild(subWinTextvalue);
      //   var WinTextvalue = new BitmapText(`${winVal}`, winTextValStyle);
      //   WinTextvalue.x = (app.screen.width - WinText.width) / 1.95;
      //   WinTextvalue.y = (app.screen.height - BetBg.height) / 0.97;
      //   app.stage.addChild(WinTextvalue);
      //   WinTextvalue = WinTextvalue;
      //   if (winVal > 0) {
      //     let subwin = parseFloat(winVal / 100);
      //     var subwinVal =
      //       subwin % 1 === 0
      //         ? subwin.toFixed(2).replace(/\.0$/, "")
      //         : subwin.toFixed(2);
      //      subWinTextvalue = new BitmapText(`$${subwinVal}`, betSubTextStyle);
      //     subWinTextvalue.x = (app.screen.width - WinText.width) / 1.95;
      //     subWinTextvalue.y = (app.screen.height - BetBg.height) / 0.91;
      //     app.stage.addChild(subWinTextvalue);
      //     subWinTextvalue = subWinTextvalue
      //   } else {
      //     subWinTextvalue = new BitmapText(`$${'0.00'}`, betSubTextStyle);
      //     subWinTextvalue.x = (app.screen.width - WinText.width) / 1.95;
      //     subWinTextvalue.y = (app.screen.height - BetBg.height) / 0.91;
      //     app.stage.addChild(subWinTextvalue);
      //     subWinTextvalue = subWinTextvalue;
      //     subWinTextvalue.x = X1percent * 47.5;
      //   }
      //  }


      function showWinValue(winVal) {
        // app.stage.removeChild(WinTextvalue);
        // app.stage.removeChild(subWinTextvalue);
        WinTextvalue.text = `${winVal}`;
        // var WinTextvalue = new BitmapText(`${winVal}`, winTextValStyle);
       // WinTextvalue.x = (window.innerWidth > 480) ? (app.screen.width - WinText.width) / 1.95 : WinTextvalue === 0 ? X1percent * 52.8 : X1percent * 52;
       // WinTextvalue.y = (window.innerWidth > 480) ? (app.screen.height - BetBg.height) / 0.97 : Y1percent * 72;;
        // app.stage.addChild(WinTextvalue);
        // WinTextvalue = WinTextvalue;
        if (winVal > 0) {
          let subwin = parseFloat(winVal / 100);
          var subwinVal =
            subwin % 1 === 0
              ? subwin.toFixed(2).replace(/\.0$/, "")
              : subwin.toFixed(2);
          subWinTextvalue.text = `$${subwinVal}`
          //subWinTextvalue.x = (app.screen.width - WinText.width) / 1.95;
          subWinTextvalue.x = X1percent * 47.5;
          subWinTextvalue.y = (app.screen.height - BetBg.height) / 0.91;
          // app.stage.addChild(subWinTextvalue);
          // subWinTextvalue = subWinTextvalue
        } else {
          subWinTextvalue.text = `$${'0.00'}`
          //subWinTextvalue.x = (app.screen.width - WinText.width) / 1.95;
          subWinTextvalue.y = (app.screen.height - BetBg.height) / 0.91;
          // app.stage.addChild(subWinTextvalue);
          // subWinTextvalue = subWinTextvalue;
          subWinTextvalue.x = X1percent * 47.5;
        }

        if (window.innerWidth <= 480) {
          // WinTextvalue.height = Y1percent * 3.5;
          // WinTextvalue.width = X1percent * 10;
              if(WinTextvalue.text.length == 5){
                WinTextvalue.x = X1percent * 46
              }
              else if(WinTextvalue.text.length == 4){
                WinTextvalue.x = X1percent * 48
              }
              else if(WinTextvalue.text.length == 3){
                WinTextvalue.x = X1percent * 49
              }
              else{
                WinTextvalue.x = X1percent * 52.5;
              }
              
          // WinTextvalue.y = Y1percent * 72;
          // WinTextvalue.style.stroke = { color: "#000", width: 2 }
          // WinTextvalue.style.dropShadow = { color: 0x000000, angle: Math.PI / 6, blur: 1, distance: 2 }

          subWinTextvalue.height = Y1percent * 3.2;
          subWinTextvalue.width = X1percent * 12;
          subWinTextvalue.x = X1percent * 50;
          subWinTextvalue.y = Y1percent * 75;
        }else{
          if(WinTextvalue.text.length == 5){
            WinTextvalue.x = X1percent * 46.5
          }
          else if(WinTextvalue.text.length == 4){
            WinTextvalue.x = X1percent * 47.5
          }
          else if(WinTextvalue.text.length == 3){
            WinTextvalue.x = X1percent * 48
          }
          else if(WinTextvalue.text.length == 2){
            WinTextvalue.x = X1percent * 48.5
          }
          else{
            WinTextvalue.x = (app.screen.width - WinText.width) / 1.96;
          }
        }

      }





      // UpdateWinValue(50)
      // ********* Circular Image/Denomination Image imported
      const DenominationImg = new Sprite(
        Texture.from("/assets/Backgrounds/Hud_tutoriales.png")
      );
      DenominationImg.height = app.screen.height / 7;
      DenominationImg.width = app.screen.height / 7;
      DenominationImg.x = (app.screen.width - DenominationImg.width) / 10;
      DenominationImg.y = (app.screen.height - DenominationImg.height) / 1;
      app.stage.addChild(DenominationImg);

      // Circular Image/Denomination text added
      const DenominationText = new BitmapText(`${1}\u20B5`, coinDenomTextStyle);
      //DenominationText.x = (app.screen.width - DenominationImg.width) / 8;
      DenominationText.x = (window.innerWidth > 736) ? X1percent * 11.4 : X1percent * 11.7;
      DenominationText.y = (app.screen.height - DenominationText.height) / 1.065;
      app.stage.addChild(DenominationText);

      // ********* Info Image imported
      const InfoImg = new Sprite(
        Texture.from("/assets/Backgrounds/Hud_Game1of10.png")
      );
      InfoImg.height = app.screen.height / 16;
      InfoImg.width = app.screen.width / 20;
      InfoImg.x = (app.screen.width - InfoImg.width) / 9;
      InfoImg.y = (app.screen.height - InfoImg.height) / 1.2;
      app.stage.addChild(InfoImg);

      const infoTextStyle = new TextStyle({
        fontSize: app.screen.width / 90,
        fontWeight: 400,
        fill: "#fff",
        fontFamily: "roboto, sans-serif , Arial",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440
      });

      // Info text added
      const InfoText = new BitmapText("INFO", infoTextStyle);
      InfoText.x = (app.screen.width - InfoText.width) / 8.25;
      InfoText.y = (app.screen.height - InfoImg.height) / 1.175;
      app.stage.addChild(InfoText);

      // ********* FoF/Flames of Fortune Image imported
      const FoFImg = new Sprite(Texture.from("/assets/Link/FoF-Logo.png"));
      FoFImg.height = app.screen.height / 10;
      FoFImg.width = app.screen.width / 8;
      FoFImg.x = (app.screen.width - FoFImg.width) / 1.15;
      FoFImg.y = (app.screen.height - FoFImg.height) / 1.02;
      FoFImg.visible = true
      app.stage.addChild(FoFImg);

      // Spin Button added here
      const SpinbuttonBg2 = Texture.from("/assets/Icons/spin.png");
      const SpinBtn2 = new Sprite(SpinbuttonBg2);
      SpinBtn2.alpha = 1;
      SpinBtn2.height = app.screen.height / 11;
      SpinBtn2.width = app.screen.width / 14;
      SpinBtn2.y = (app.screen.height - SpinBtn2.height) / 2.2;
      SpinBtn2.x = Math.round((bottom.width * 4 - SpinBtn2.width * 1.5) / 4.93);
      app.stage.addChild(SpinBtn2);
      SpinBtn2.visible = false;


      const stickytBtn2 = new Sprite(Texture.from("/assets/Icons/spin.png"));
      stickytBtn2.alpha = 1;
      stickytBtn2.height = app.screen.height / 11;
      stickytBtn2.width = app.screen.width / 14;
      stickytBtn2.y = 0;
      stickytBtn2.x = 0;
      app.stage.addChild(stickytBtn2);



      stickytBtn2.eventMode = "static";
      stickytBtn2.cursor = "pointer";
      stickytBtn2.addListener(app.screen.width > 992 ? 'click' : 'tap', async (event) => {
        testKeyVar = 4;
        console.log(testKeyVar, 'testKeyVar')
      });
      // stickytBtn2.visible = false;


      // Spin Button added here
      const SpinbuttonBg = Texture.from("/assets/Icons/spin.png");
      const SpinBtn = new Sprite(SpinbuttonBg);
      SpinBtn.alpha = 1;
      SpinBtn.height = app.screen.height / 11;
      SpinBtn.width = app.screen.width / 14;
      SpinBtn.y = (app.screen.height - SpinBtn.height) / 2.2;
      SpinBtn.x = Math.round((bottom.width * 4 - SpinBtn.width * 1.5) / 4.93);
      bottom.addChild(SpinBtn);



      const reelSymbolsContainer = new Container();
      reelSymbolsContainer.height = (window.innerWidth > 480) ? app.screen.height / 3 : app.screen.height / 2;
      reelSymbolsContainer.mask = new Graphics();
      reelSymbolsContainer.mask.beginFill(0xffffff);
      reelSymbolsContainer.mask.drawRect(
        0,
        0,
        (app.screen.width - reelSymbolsContainer.width) / 1,
        window.innerWidth > 480 ? (app.screen.height - reelSymbolsContainer.height) / 1.25 : (app.screen.height - reelSymbolsContainer.height) / 1.62
      ); // set the mask to a rectangle with a width of 400 and a height of 300
      reelSymbolsContainer.mask.endFill();
      reelSymbolsContainer.hitArea = new Rectangle(
        0,
        0,
        (app.screen.width - reelSymbolsContainer.width) / 2,
        (app.screen.height - reelSymbolsContainer.height) / 1.35
      );


      const winBlackBg = new Sprite(Texture.from('/assets/MainBG/Dorado_background.png'));
      winBlackBg.height = app.screen.height;
      winBlackBg.width = app.screen.width;
      app.stage.addChildAt(winBlackBg, 29)
      winBlackBg.visible = false;
      winBlackBg.x = 0;
      winBlackBg.y = 0;

      app.stage.addChildAt(reelSymbolsContainer, 20);
      app.stage.addChildAt(stickySpinBg, 21)


      // Build the reels
      const reels = [];
      const reelContainer = new Container();
      let reelDataIndex = 0;
      for (let i = 0; i < 5; i++) {
        const rc = new Container();

        rc.x = window.innerWidth <= 480 ? i * REEL_WIDTH * 1.5 : i * REEL_WIDTH;
        rc.y = window.innerWidth <= 480 ? 50 : 0;
        reelContainer.addChild(rc);


        const reel = {
          container: rc,
          symbols: [],
          position: 0,
          previousPosition: 0,
          visible: "true",
          blur: new BlurFilter()
        };
        // Build the symbols
        for (let j = 0; j < 3; j++) {
          // console.log("slotTextures", slotTextures);
          let data = (slotTextures?.length > 0 && reelData?.length > 0) ? slotTextures?.filter(
            (r) => r?.name == reelData[reelDataIndex]
          )[0] : [];

          // console.log(data, "data");
          const symbol = new Sprite(data);

          symbol.y = window.innerWidth <= 480 ? j * SYMBOL_SIZE * 3.2 : j * SYMBOL_SIZE;
          console.log('YY', window.innerWidth)

          symbol.scale.x = symbol.scale.y = Math.min(
            SYMBOL_SIZE / symbol.width,
            SYMBOL_SIZE / symbol.height
          );
          symbol.x = Math.round((SYMBOL_SIZE - symbol.width) / 2);
          reel.symbols.push(symbol);
          rc.addChild(symbol);
          if (reelData?.length == reelDataIndex) {
            reelDataIndex = 0;
          } else {
            reelDataIndex++;
          }
        }

        reels.push(reel);
      }

      // console.log(reels[0], "reels[0]");

      //  reelContainer.addChild(reels)
      reelSymbolsContainer.addChild(reelContainer);

      // Build top & bottom covers and position reelContainer
      reelContainer.height = app.screen.height / 2;
      reelContainer.y = (app.screen.height - reelContainer.height) / 1.36;
      reelContainer.x = (app.screen.width - reelContainer.width) / 2;

      // Create gradient fill
      const fill = new FillGradient(0, 0, 0, 36 * 1.7);

      const colors = [0xffffff, 0x00ff99].map((color) =>
        Color.shared.setValue(color).toNumber()
      );

      colors.forEach((number, index) => {
        const ratio = index / colors.length;

        fill.addColorStop(ratio, number);
      });
      app.stage.addChild(top);
      app.stage.addChild(bottom);
      // Set the interactivity.
      bottom.eventMode = "static";
      bottom.cursor = "pointer";
      bottom.addListener(app.screen.width > 992 ? 'click' : 'tap', async (event) => {
        const modal = document.querySelectorAll("addCredit");

        if (creditsVal >= (BetSuboutput * coinDenonimation) * 100) {
          modal.visible = false;
          payLineTrueHandle()
          startPlay()
          await gameSpinApi(false);

        } else {
          modalContainer.visible = true;
          setTimeout(() => { modalContainer.visible = false; }, 1500)
        }
      });

      const creditPopup = new TextStyle({
        fontSize: app.screen.width / 60,
        // fontStyle: 'italic',
        align: 'center', // set text alignment to center
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: "normal",
        fill: "#fff",
        // stroke: { color: 0x4a1850, width: 5 },
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440,
        lineHeight: 60, // set line height
      });

      const modalContainer = new Container();
      // const modalBackground = new Graphics();
      const modalBackground = new Sprite(Texture.from('/assets/MainBG/modalBg.png'));
      modalBackground.scale.set(0.6);
      modalContainer.addChild(modalBackground);
      modalContainer.addChild(modalBackground);
      const modalContent = new Text(`Not enough Credits! Please,${window.innerWidth > 1200 && window.innerWidth < 1400 ? '\n' : ''}  Add credits to play.`, creditPopup);
      modalContent.anchor.x = window.innerWidth > 1600 ? -0.3 : (window.innerWidth > 1366 && window.innerWidth < 1600) ? -0.9 / 5 : -0.5;
      modalContent.anchor.y = -0.8;
      modalContainer.addChild(modalContent);
      modalBackground.width = app.screen.width / 3;
      modalBackground.height = app.screen.height / 3;
      modalContainer.x = app.screen.width / 2;
      modalContainer.y = app.screen.height / 2;
      modalContainer.x = (app.screen.width - modalContainer.width) / 2
      modalContainer.y = (app.screen.height - modalContainer.height) / 2

      modalBackground.x = window.innerWidth > 1600 ? (app.screen.width - modalBackground.width) / 90 : (window.innerWidth > 1366 && window.innerWidth < 1600) ? (app.screen.width - modalBackground.width) / 80 : (app.screen.width - modalBackground.width) / 15;
      modalBackground.y = (app.screen.height - modalBackground.height) / 15;
      app.stage.addChildAt(modalContainer, 30)

      modalContainer.visible = false;



      // const LinkVideoSprite = new Sprite("/assets/Link/Cortinilla_LinkBeat_v08_wAlpha_HAPQ.ogv");
      // LinkVideoSprite.x = 0
      // LinkVideoSprite.y = 0
      // app.stage.addChild(LinkVideoSprite)


      // play spin audio
      const spinAudio = new Audio('/assets/Audios/railsStartSpinning.wav');
      spinAudio.volume = 0.5;

      // stop reel spin audio
      const stopReel = new Audio('/assets/Audios/railStop.wav');
      stopReel.volume = 0.5;

      const stopReel1 = new Audio('/assets/Audios/railStop.wav');
      stopReel.volume = 0.5;

      const stopReel2 = new Audio('/assets/Audios/railStop.wav');
      stopReel.volume = 0.5;

      const stopReel3 = new Audio('/assets/Audios/railStop.wav');
      stopReel.volume = 0.5;

      const stopReel4 = new Audio('/assets/Audios/railStop.wav');
      stopReel.volume = 0.5;

      // Win audio
      const winAudio1 = new Audio('/assets/Audios/BaseCelebration1.wav');
      winAudio1.volume = 1;

      const winAudio2 = new Audio('/assets/Audios/BaseCelebration2.wav');
      winAudio2.volume = 1;

      const winAudio3 = new Audio('/assets/Audios/BaseCelebration3.wav');
      winAudio2.volume = 1;


      //free Spin Audio
      // play spin audio
      const freeSpinRing = new Audio('/assets/Audios/FreeGames/FreeGamesChicharra.wav');
      freeSpinRing.volume = 0.5;

      const freeSpinMusic = new Audio('/assets/Audios/FreeGames/FreeGamesMusic.wav');
      freeSpinMusic.volume = 0.5;

      const freeSpinScream = new Audio('/assets/Audios/FreeGames/FreeGamesScream.wav');
      freeSpinScream.volume = 0.5;



      //free Spin Audio
      // play spin audio
      const LinkHitRing = new Audio('/assets/Audios/Link/LinkGolpeMeteoro.wav');
      LinkHitRing.volume = 0.5;

      const LinkHitRing2 = new Audio('/assets/Audios/Link/LinkMeteoroSubiendo.wav');
      LinkHitRing2.volume = 0.5;

      const LinkHitMusic = new Audio('/assets/Audios/Link/LinkMusic.wav');
      LinkHitMusic.volume = 0.5;

      const LinkHitScream = new Audio('/assets/Audios/Link/LinkScream.mp3');
      LinkHitScream.volume = 0.5;


      let running = false;

      let reelArr = [];
      function startPlay() {
        IsspinReelAnimation = false;
        winBlackBg.visible = false;
        if (currentfreeSpin > 0) {
          SpinBtn.visible = false;
          SpinBtn2.visible = false;
          bottom.eventMode = "none";
          bottom.cursor = "none";
        } else {
          SpinBtn2.visible = false;
        }

        winAudio1.pause()
        winAudio2.pause()
        winAudio3.pause()
        spinAudio.play()
        playStopReel()
        subWinTextvalue.x = window.innerWidth > 480 ? X1percent * 47.5 : X1percent * 50;
        payLineTrueHandle()
        winBlackBg.visible = false;
        //bottom.eventMode = "none";
        //bottom.cursor = "none";
        strickfordoblecallFunction = true
        bottom.visible = false;
        if (running) return;
        running = true;
        removingfunction()
        reelSymbolsContainer.addChild(reelContainer);
        for (let i = 0; i < reels.length; i++) {
          const r = reels[i];
          var target = 0;
          target = r.position + 30;
          const time = 2000 + i * 300;

          tweenTo(
            r,
            "position",
            target,
            time,
            backout(0.5),
            null,
            i === reels.length - 1 ? reelsComplete : null
          );
          if (reelArr.lenght < 5) {
            reelArr.splice(0, 5);
          }
          
          reelArr.push(r);
          target = r.position + 30;
          // console.log(reelArr,' s.texture s.texture')
        }

        // creditsValue.x = creditsValue.text.length === 3 ? X1percent * 30.8 : creditsValue.text.length === 12 ? X1percent * 27 :creditsValue.text.length === 5 ? X1percent * 30.1 : creditsValue.text.length === 6 ? X1percent * 29.5 : creditsValue.text.length === 7 ? X1percent * 28.8 : creditsValue.text.length === 8 ? X1percent * 29 : creditsValue.text.length === 9 ? X1percent * 28.3: creditsValue.text.length === 11 ? X1percent * 27.5 : X1percent * 31.5;
        // creditsValue.x = creditsValue.text.length === 3 ? X1percent * 30.8 : creditsValue.text.length === 4 ? X1percent * 30 :creditsValue.text.length === 5 ? X1percent * 29.5 : creditsValue.text.length === 6 ? X1percent * 29 : creditsValue.text.length === 7 ? X1percent * 28.8 : creditsValue.text.length === 8 ? X1percent * 28.5 : creditsValue.text.length === 9 ? X1percent * 27.7: creditsValue.text.length === 10 ? X1percent * 27 : X1percent * 31.5;
      }

      // Reels done handler.
      function reelsComplete() {
        running = false;
        testKeyVar = 0;
        updateCredits(Math.floor(creditsVal / coinDenonimation));
        updateCreditsSubVal(creditsVal)
        setTimeout(()=>{
          bottom.visible = true;
          SpinBtn2.visible = false;
          
        },300)
        // functionSpinningWheel()
      }


      function playStopReel() {
        setTimeout(() => {
          stopReel.play();
        }, 1800)
        setTimeout(() => {
          stopReel1.play();
        }, 2100)
        setTimeout(() => {
          stopReel2.play();
        }, 2400)
        setTimeout(() => {
          stopReel3.play();
        }, 2700)
        setTimeout(() => {
          stopReel4.play();
        }, 3000)
      }
      function playWinAudio() {
        console.log(winCount, 'winFunctionCalled')
        setTimeout(() => {
          if (winCount <= 20) {
            winAudio1.play()
          } else if (winCount >= 21 && winCount <= 50) {
            winAudio2.play()
          } else if (winCount >= 51) {
            winAudio3.play()
          }

        }, 2200)
      }




      function playFreeSpinAudio() {
        freeSpinScream.play();
        freeSpinMusic.play()
        freeSpinRing.play()
        freeSpinMusic.loop = true;
      }
      // Listen for animate update.

      var dot = 0;
      var hitflag = false;

      app.ticker.add(() => {
        // Update the slots.

        for (let i = 0; i < reels.length; i++) {
          const r = reels[i];
          // Update blur filter y amount based on speed.
          // This would be better if calculated with time in mind also. Now blur depends on frame rate.
          r.blur.blurY = (r.position - r.previousPosition) * 8;
          r.previousPosition = r.position;

          // Update symbol positions on reel.
          for (let j = 0; j < r.symbols.length; j++) {
            const s = r.symbols[j];
            const prevy = s.y;
            if (running) {
              dot += 1;
              payLineTrueHandle()
           
              // console.log(dot, "dot=========================================");
              if (dot === 40) {
                dot = 0;
                hitflag = false;
                return setsymbols();
              }
            }
            s.y =
              ((r.position + j) % r.symbols.length) * 1.25 * SYMBOL_SIZE -
              SYMBOL_SIZE;
            if (s.y < 0 && prevy > SYMBOL_SIZE) {
              s.texture =
                slotTextures[Math.floor(Math.random() * slotTextures.length)];
              s.scale.x = s.scale.y = Math.min(
                SYMBOL_SIZE / s.texture.width,
                SYMBOL_SIZE / s.texture.height
              );
              s.x = Math.round((SYMBOL_SIZE - s.width) / 2);
            }
          }
        }
      });

      var fireCount = 0;
      function setsymbols() {
        fireCount += 1;

        if (hitflag === false) {

          // reels[0].container.children[0].texture = imgArrSlot[0];
          // reels[0].container.children[1].texture = imgArrSlot[1];
          // reels[0].container.children[2].texture = imgArrSlot[2];
          // reels[1].container.children[0].texture = imgArrSlot[3];
          // reels[1].container.children[1].texture = imgArrSlot[4];
          // reels[1].container.children[2].texture = imgArrSlot[5];
          // reels[2].container.children[0].texture = imgArrSlot[6];
          // reels[2].container.children[1].texture = imgArrSlot[7];
          // reels[2].container.children[2].texture = imgArrSlot[8];
          // reels[3].container.children[0].texture = imgArrSlot[9];
          // reels[3].container.children[1].texture = imgArrSlot[10];
          // reels[3].container.children[2].texture = imgArrSlot[11];
          // reels[4].container.children[0].texture = imgArrSlot[12];
          // reels[4].container.children[1].texture = imgArrSlot[13];
          // reels[4].container.children[2].texture = imgArrSlot[14];
          setTimeout(() => {
            reels[0].container.children[0].texture = imgArrSlot[0];
            reels[0].container.children[1].texture = imgArrSlot[1];
            reels[0].container.children[2].texture = imgArrSlot[2];

            // stopReel.play();
          }, 500);
          setTimeout(() => {
            reels[1].container.children[0].texture = imgArrSlot[3];
            reels[1].container.children[1].texture = imgArrSlot[4];
            reels[1].container.children[2].texture = imgArrSlot[5];
          }, 900);
          setTimeout(() => {
            reels[2].container.children[0].texture = imgArrSlot[6];
            reels[2].container.children[1].texture = imgArrSlot[7];
            reels[2].container.children[2].texture = imgArrSlot[8];
          }, 1200);
          setTimeout(() => {
            reels[3].container.children[0].texture = imgArrSlot[9];
            reels[3].container.children[1].texture = imgArrSlot[10];
            reels[3].container.children[2].texture = imgArrSlot[11];
          }, 1500);
          setTimeout(() => {
            reels[4].container.children[0].texture = imgArrSlot[12];
            reels[4].container.children[1].texture = imgArrSlot[13];
            reels[4].container.children[2].texture = imgArrSlot[14];
          }, 1800);
        }
        // console.log(fireCount, "22222222222222222222222222222", StickeySpinWon);
        if (fireCount === 19) {
          fireCount = 0;
          if(IsspinReelAnimation == false){
            console.log(fireCount, "22222222222222222222222222222", StickeySpinWon);
           setTimeout(()=>{
            spinReelAnimation();
           },1500)
            IsspinReelAnimation = true;
          }

          spinReelAnimation();
          setTimeout(() => {
            if (StickeySpinWon == true) {
              SpinBtn.visible = false;
              SpinBtn2.visible = false;

              setTimeout(() => {
                if (IsstickySpin == false) {
                  IsstickySpin = true
                  triggerStikySpin();
                }
              }, 1500)
            }
            // checkStickyspin();

          }, 1500);
        }
      }

      // function spinReelAnimation() {
      //   setTimeout(() => {
      //     let ttArr = [];
      //     let allName = [];

      //     for (let i = 0; i < 5; i++) {
      //       for (let j = 0; j < 3; j++) {
      //         allName.push(reels[i].container.children[j].texture.name);

      //         if (reels[i].container.children[j].texture.name === "TT") {
      //           ttArr.push({
      //             i,
      //             j,
      //             name: reels[i].container.children[j].texture.name
      //           });
      //         }
      //       }
      //     }
      //     console.log(allName, "allName----------------allName");
      //     console.log(ttArr, "fireCount-----------------------fireCount");

      //     ttArr.forEach((el) => {
      //       if (el.i === 0 && el.name === "TT") {
      //         console.log(0, "fireCount-----------------------fireCount", el.j);
      //         reels[0].container.children[el.j].visible = false;
      //       } else if (el.i === 1 && el.name === "TT") {
      //         console.log(1, "fireCount-----------------------fireCount", el.j);
      //         reels[1].container.children[el.j].visible = false;
      //       } else if (el.i === 2 && el.name === "TT") {
      //         console.log(2, "fireCount-----------------------fireCount", el.j);
      //         reels[2].container.children[el.j].visible = false;
      //       } else if (el.i === 3 && el.name === "TT") {
      //         console.log(3, "fireCount-----------------------fireCount", el.j);
      //         reels[3].container.children[el.j].visible = false;
      //       } else if (el.i === 4 && el.name === "TT") {
      //         console.log(4, "fireCount-----------------------fireCount", el.j);
      //         reels[4].container.children[el.j].visible = false;
      //       }
      //     });
      //     if (
      //       reels[0].container.children[0].visible === false &&
      //       reels[0].container.children[0].texture.name === "TT"
      //     ) {
      //       spriteA00.y = app.screen.height / 3.5;
      //       spriteA00.x = app.screen.width / 4;
      //       animateSpin();
      //     }
      //     if (
      //       reels[0].container.children[1].visible === false &&
      //       reels[0].container.children[1].texture.name === "TT"
      //     ) {
      //       spriteA01.y = app.screen.height / 2.05;
      //       spriteA01.x = app.screen.width / 4;
      //       animateSpin1();
      //     }
      //     if (
      //       reels[0].container.children[2].visible === false &&
      //       reels[0].container.children[2].texture.name === "TT"
      //     ) {
      //       spriteA02.y = app.screen.height / 1.435;
      //       spriteA02.x = app.screen.width / 4;
      //       animateSpin2();
      //     }

      //     //00000000
      //     if (
      //       reels[1].container.children[0].visible === false &&
      //       reels[1].container.children[0].texture.name === "TT"
      //     ) {
      //       spriteA10.y = app.screen.height / 3.5;
      //       spriteA10.x = app.screen.width / 2.67;
      //       animateSpin3();
      //     }
      //     if (
      //       reels[1].container.children[1].visible === false &&
      //       reels[1].container.children[1].texture.name === "TT"
      //     ) {
      //       spriteA11.y = app.screen.height / 2.05;
      //       spriteA11.x = app.screen.width / 2.67;
      //       animateSpin4();
      //     }
      //     if (
      //       reels[1].container.children[2].visible === false &&
      //       reels[1].container.children[2].texture.name === "TT"
      //     ) {
      //       spriteA12.y = app.screen.height / 1.435;
      //       spriteA12.x = app.screen.width / 2.67;
      //       animateSpin5();
      //     }

      //     //  1111111
      //     if (
      //       reels[2].container.children[0].visible === false &&
      //       reels[2].container.children[0].texture.name === "TT"
      //     ) {
      //       spriteA20.y = app.screen.height / 3.5;
      //       spriteA20.x = app.screen.width / 2;
      //       animateSpin6();
      //     }
      //     if (
      //       reels[2].container.children[1].visible === false &&
      //       reels[2].container.children[1].texture.name === "TT"
      //     ) {
      //       spriteA21.y = app.screen.height / 2.05;
      //       spriteA21.x = app.screen.width / 2;
      //       animateSpin7();
      //     }
      //     if (
      //       reels[2].container.children[2].visible === false &&
      //       reels[2].container.children[2].texture.name === "TT"
      //     ) {
      //       spriteA22.y = app.screen.height / 1.435;
      //       spriteA22.x = app.screen.width / 2;
      //       animateSpin8();
      //     }

      //     //  22222222
      //     if (
      //       reels[3].container.children[0].visible === false &&
      //       reels[3].container.children[0].texture.name === "TT"
      //     ) {
      //       spriteA30.y = app.screen.height / 3.5;
      //       spriteA30.x = app.screen.width / 1.6;
      //       animateSpin9();
      //     }
      //     if (
      //       reels[3].container.children[1].visible === false &&
      //       reels[3].container.children[1].texture.name === "TT"
      //     ) {
      //       spriteA31.y = app.screen.height / 2.05;
      //       spriteA31.x = app.screen.width / 1.6;
      //       animateSpin10();
      //     }
      //     if (
      //       reels[3].container.children[2].visible === false &&
      //       reels[3].container.children[2].texture.name === "TT"
      //     ) {
      //       spriteA32.y = app.screen.height / 1.435;
      //       spriteA32.x = app.screen.width / 1.6;
      //       animateSpin11();
      //     }
      //     //  333333333333333
      //     if (
      //       reels[4].container.children[0].visible === false &&
      //       reels[4].container.children[0].texture.name === "TT"
      //     ) {
      //       spriteA40.y = app.screen.height / 3.5;
      //       spriteA40.x = app.screen.width / 1.335;
      //       animateSpin12();
      //     }
      //     if (
      //       reels[4].container.children[1].visible === false &&
      //       reels[4].container.children[1].texture.name === "TT"
      //     ) {
      //       spriteA41.y = app.screen.height / 2.05;
      //       spriteA41.x = app.screen.width / 1.335;
      //       animateSpin13();
      //     }
      //     if (
      //       reels[4].container.children[2].visible === false &&
      //       reels[4].container.children[2].texture.name === "TT"
      //     ) {
      //       spriteA42.y = app.screen.height / 1.435;
      //       spriteA42.x = app.screen.width / 1.335;
      //       animateSpin14();
      //     }
      //   }, 1800);
      // }


      //check sticky spin

      // function checkStickyspin() {
      //   checkFIreIcon = 0;
      //   if (strickfordoblecallFunction == true) {
      //     strickfordoblecallFunction = false
      //     if (IsstickySpin) {
      //       return;
      //     }
      //     //console.log(imgArrSlot)
      //     // setStickySpinn(!isStickySpinn)
      //     stickySpinindex = [];
      //     imgArrSlot.forEach((element, index) => {
      //       //console.log(element,index)
      //       if (element.name == "TT") {
      //         checkFIreIcon = checkFIreIcon + 1
      //         // stickySpinindex.push({
      //         //   index: index,
      //         //   value: Math.floor(Math.random() * 100) + 1
      //         // })
      //         //stickyCounter = 3;
      //         stickyremainingCount.text = remaningStickeySpin;
      //         //stickySpaceFilledCountContainer.children[2].text = checkFIreIcon

      //       }
      //     });
      //     console.log(checkFIreIcon)
      //     if (checkFIreIcon >= 6) {
      //       IsStickeySpinWon = true;
      //       setTimeout(() => {
      //         if (IsstickySpin == false) {
      //           IsstickySpin = true
      //           triggerStikySpin();
      //         }

      //       }, 2000)
      //     }
      //   }
      // }


      function addtextReel() {
        var lnternOverNum = 0;
        var ind = 0;
        for (var i = 0; i < reels.length; i++) {
          for (let j = 0; j < 3; j++) {
            lnternOverNum += 1;
            console.log(firiconindex[ind])
            if (firiconindex[ind] != " ") {
              var text = new BitmapText(`${firiconindex[ind]}`);
              console.log(text, reels[i].container.children[j])
              if (reels[i].container.children[j]._texture.name == "TT") {
                reels[i].container.children[j].removeChildren() //  remove overlapping Text after Completed Fever Respin
                colorizedText(firiconindex[ind], 40, reels[i].container.children[j])
              }
              text.scale.x = 2.2;
              text.scale.y = 1;
              text.x = X1percent * 4.5;
              text.y = Y1percent * 12;
            }
            else {
              reels[i].container.children[j].removeChildren()
            }
            ind += 1;
          }

        }
      }


      // function for colorised text
      function colorizedText(val, gapInDigits, container) {
        try {
          const style = new TextStyle({
            align: "center",
            fill: "#fff",
            fontSize: 32,
            fontVariant: "small-caps",
            lineHeight: 6,
            padding: 5,
            strokeThickness: 4,
            trim: true,
            whiteSpace: "pre-line",
            wordWrapWidth: 160
          });
          const text = new PIXI.Text(val, style);
          text.x = gapInDigits * 1;
          text.scale.x = 2.2;
          text.scale.y = 1;
          text.x = 66;
          text.y = 83;
          container.addChild(text)

        } catch (e) {
          //console.log(e);
        }

      }

      var fireIconArray = [];
      let firiconindex = []
      function addStickyNumber(reel, el) {
        firiconindex = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        console.log(reel, el)
        let i;
        for (let index = 0; index < reel.length; index++) {
          console.log(reel[index], "reel[index]")
          if (reel[index] == 0) {
            i = el[index]
          } else if (reel[index] == 1) {
            i = el[index] + 3
          }
          else if (reel[index] == 2) {
            i = el[index] + 6
          }
          else if (reel[index] == 3) {
            i = el[index] + 9
          }
          else if (reel[index] == 4) {
            i = el[index] + 12
          }
          fireIconArray.push(i)
        }
        fireIconArray = fireIconArray.sort(function (a, b) { return a - b })
        console.log(fireIconArray.sort(function (a, b) { return a - b }))
        for (let index = 0; index < fireIconArray.length; index++) {
          firiconindex[fireIconArray[index]] = fireIconArray[index]
        }
        console.log(firiconindex);

      }




      function spinReelAnimation() {
        setTimeout(() => {
          let ttArr = [];
          const sunFireData = [];

          if (sunFireDataObj?.prizeAmount?.length >= 0) {
            for (let i = 0; i < sunFireDataObj?.prizeAmount?.length; i++) {
              sunFireData.push({
                prizeAmount: sunFireDataObj?.prizeAmount[i],
                prizeType: sunFireDataObj?.prizeType[i],
                reelIndex: sunFireDataObj?.reelIndex[i],
                slotIndex: sunFireDataObj?.slotIndex[i]
              });
            }
          }

          let index = 0;
          reels.forEach(element => {
            element.container.children.forEach((el)=>{
              index = index + 1
              if(el.texture.name == 'TT'){
                el.visible = false
                showstickyAnimation(index,sunFireData)
              }
            })
           });
  

          // for (let i = 0; i < 5; i++) {
          //   for (let j = 0; j < 3; j++) {
          //     const textureName = reels[i].container.children[j].texture.name;

          //     if (textureName === "TT") {
          //       ttArr.push({
          //         i,
          //         j,
          //         name: textureName
          //       });
          //     }
          //   }
          // }

          // // console.log(allName, "allName----------------allName");

          // ttArr.forEach((el) => {
          //   if (el.i === 0 && el.name === "TT") {
          //     reels[0].container.children[el.j].visible = false;
          //   } else if (el.i === 1 && el.name === "TT") {
          //     reels[1].container.children[el.j].visible = false;
          //   } else if (el.i === 2 && el.name === "TT") {
          //     reels[2].container.children[el.j].visible = false;
          //   } else if (el.i === 3 && el.name === "TT") {
          //     reels[3].container.children[el.j].visible = false;
          //   } else if (el.i === 4 && el.name === "TT") {
          //     reels[4].container.children[el.j].visible = false;
          //   }
          // });

          // if (
          //   reels[0].container.children[0].visible === false &&
          //   reels[0].container.children[0].texture.name === "TT"
          // ) {
          //   spriteA00.y = window.innerWidth > 480 ? app.screen.height / 3.5 : Y1percent * 40;
          //   spriteA00.x = window.innerWidth > 480 ? app.screen.width / 4 : X1percent * 14;
          //   spriteAOverlay00.y = window.innerWidth > 480 ? app.screen.height / 3.5 : Y1percent * 41;
          //   spriteAOverlay00.x = window.innerWidth > 480 ? app.screen.width / 4 : X1percent * 14;

          //   const prizeData = sunFireData.find(item =>
          //     item.reelIndex === 0 && item.slotIndex === 0
          //   );
          //   if (spriteB00.children.length == 0) {

          //   }
          //   prizeData?.prizeAmount > 0 ? animateSpin(prizeData?.prizeAmount) : animateSpin(linkPrizeAmount[0]);
          //   console.log(prizeData, linkPrizeAmount[0], 'prizeData--00')
          // }
          // if (
          //   reels[0].container.children[1].visible === false &&
          //   reels[0].container.children[1].texture.name === "TT"
          // ) {
          //   spriteA01.y = window.innerWidth > 480 ? app.screen.height / 2.05 : Y1percent * 49.5;
          //   spriteA01.x = window.innerWidth > 480 ? app.screen.width / 4 : X1percent * 14;
          //   spriteAOverlay01.y = window.innerWidth > 480 ? app.screen.height / 2.05 : Y1percent * 49.5;
          //   spriteAOverlay01.x = window.innerWidth > 480 ? app.screen.width / 4 : X1percent * 14;
          //   const prizeData = sunFireData.find(item =>
          //     item.reelIndex === 0 && item.slotIndex === 1
          //   );
          //   console.log(prizeData, linkPrizeAmount[1], 'prizeData--00')
          //   if (spriteB01.children.length == 0) {

          //   }
          //   prizeData?.prizeAmount > 0 ? animateSpin1(prizeData?.prizeAmount) : animateSpin1(linkPrizeAmount[1]);

          // }
          // if (
          //   reels[0].container.children[2].visible === false &&
          //   reels[0].container.children[2].texture.name === "TT"
          // ) {
          //   spriteA02.y = window.innerWidth > 480 ? app.screen.height / 1.435 : Y1percent * 58;
          //   spriteA02.x = window.innerWidth > 480 ? app.screen.width / 4 : X1percent * 14
          //   spriteAOverlay02.x = window.innerWidth > 480 ? app.screen.width / 4 : X1percent * 14
          //   spriteAOverlay02.y = window.innerWidth > 480 ? app.screen.height / 1.435 : Y1percent * 57.7;
          //   const prizeData = sunFireData.find(item =>
          //     item.reelIndex === 0 && item.slotIndex === 2
          //   );
          //   console.log(prizeData, linkPrizeAmount[2], 'prizeData--00')
          //   if (spriteB02.children.length == 0) {

          //   }
          //   prizeData?.prizeAmount > 0 ? animateSpin2(prizeData?.prizeAmount) : animateSpin2(linkPrizeAmount[2]);
          // }

          // //00000000 spriteAOverlay10
          // if (
          //   reels[1].container.children[0].visible === false &&
          //   reels[1].container.children[0].texture.name === "TT"
          // ) {
          //   spriteA10.y = window.innerWidth > 480 ? app.screen.height / 3.5 : Y1percent * 41;
          //   spriteA10.x = window.innerWidth > 480 ? app.screen.width / 2.67 : X1percent * 32
          //   spriteAOverlay10.y = window.innerWidth > 480 ? app.screen.height / 3.5 : Y1percent * 41;
          //   spriteAOverlay10.x = window.innerWidth > 480 ? app.screen.width / 2.67 : X1percent * 32;
          //   const prizeData = sunFireData.find(item =>
          //     item.reelIndex === 1 && item.slotIndex === 0
          //   );
          //   console.log(prizeData, linkPrizeAmount[3], 'prizeData--10')
          //   if (spriteB10.children.length == 0) {

          //   }
          //   prizeData?.prizeAmount > 0 ? animateSpin3(prizeData?.prizeAmount) : animateSpin3(linkPrizeAmount[3]);
          // }
          // if (
          //   reels[1].container.children[1].visible === false &&
          //   reels[1].container.children[1].texture.name === "TT"
          // ) {
          //   spriteA11.y = window.innerWidth > 480 ? app.screen.height / 2.05 : Y1percent * 49;
          //   spriteA11.x = window.innerWidth > 480 ? app.screen.width / 2.67 : X1percent * 32;
          //   spriteAOverlay11.y = window.innerWidth > 480 ? app.screen.height / 2.05 : Y1percent * 49.5;
          //   spriteAOverlay11.x = window.innerWidth > 480 ? app.screen.width / 2.67 : X1percent * 32;
          //   const prizeData = sunFireData.find(item =>
          //     item.reelIndex === 1 && item.slotIndex === 1
          //   );
          //   console.log(prizeData, linkPrizeAmount[4], 'prizeData--11')
          //   if (spriteB11.children.length == 0) {

          //   }
          //   prizeData?.prizeAmount > 0 ? animateSpin4(prizeData?.prizeAmount) : animateSpin4(linkPrizeAmount[4]);
          // }
          // if (
          //   reels[1].container.children[2].visible === false &&
          //   reels[1].container.children[2].texture.name === "TT"
          // ) {
          //   spriteA12.y = window.innerWidth > 480 ? app.screen.height / 1.435 : Y1percent * 58;
          //   spriteA12.x = window.innerWidth > 480 ? app.screen.width / 2.67 : X1percent * 32;
          //   spriteAOverlay12.y = window.innerWidth > 480 ? app.screen.height / 1.435 : Y1percent * 57.7;
          //   spriteAOverlay12.x = window.innerWidth > 480 ? app.screen.width / 2.67 : X1percent * 32;

          //   const prizeData = sunFireData.find(item =>
          //     item.reelIndex === 1 && item.slotIndex === 2
          //   );
          //   console.log(prizeData, linkPrizeAmount[5], 'prizeData--12')
          //   if (spriteB12.children.length == 0) {

          //   }
          //   prizeData?.prizeAmount > 0 ? animateSpin5(prizeData?.prizeAmount) : animateSpin5(linkPrizeAmount[5]);
          // }

          // //  1111111
          // if (
          //   reels[2].container.children[0].visible === false &&
          //   reels[2].container.children[0].texture.name === "TT"
          // ) {
          //   spriteA20.y = window.innerWidth > 480 ? app.screen.height / 3.5 : Y1percent * 40;
          //   spriteA20.x = window.innerWidth > 480 ? app.screen.width / 2 : X1percent * 50;
          //   spriteAOverlay20.y = window.innerWidth > 480 ? app.screen.height / 3.5 : Y1percent * 41;
          //   spriteAOverlay20.x = window.innerWidth > 480 ? app.screen.width / 2 : X1percent * 50;

          //   const prizeData = sunFireData.find(item =>
          //     item.reelIndex === 2 && item.slotIndex === 0
          //   );
          //   console.log(prizeData, linkPrizeAmount[6], 'prizeData--20')
          //   if (spriteB20.children.length == 0) {

          //   }
          //   prizeData?.prizeAmount > 0 ? animateSpin6(prizeData?.prizeAmount) : animateSpin6(linkPrizeAmount[6]);
          // }
          // if (
          //   reels[2].container.children[1].visible === false &&
          //   reels[2].container.children[1].texture.name === "TT"
          // ) {
          //   spriteA21.y = window.innerWidth > 480 ? app.screen.height / 2.05 : Y1percent * 49;
          //   spriteA21.x = window.innerWidth > 480 ? app.screen.width / 2 : X1percent * 50;
          //   spriteAOverlay21.y = window.innerWidth > 480 ? app.screen.height / 2.05 : Y1percent * 49.5;
          //   spriteAOverlay21.x = window.innerWidth > 480 ? app.screen.width / 2 : X1percent * 50;

          //   const prizeData = sunFireData.find(item =>
          //     item.reelIndex === 2 && item.slotIndex === 1
          //   );
          //   console.log(prizeData, linkPrizeAmount[7], 'prizeData--21')
          //   if (spriteB21.children.length == 0) {

          //   }
          //   prizeData?.prizeAmount > 0 ? animateSpin7(prizeData?.prizeAmount) : animateSpin7(linkPrizeAmount[7]);
          // }
          // if (
          //   reels[2].container.children[2].visible === false &&
          //   reels[2].container.children[2].texture.name === "TT"
          // ) {
          //   spriteA22.y = window.innerWidth > 480 ? app.screen.height / 1.435 : Y1percent * 57;
          //   spriteA22.x = window.innerWidth > 480 ? app.screen.width / 2 : X1percent * 50;
          //   spriteAOverlay22.y = window.innerWidth > 480 ? app.screen.height / 1.435 : Y1percent * 57.7;
          //   spriteAOverlay22.x = window.innerWidth > 480 ? app.screen.width / 2 : X1percent * 50;

          //   const prizeData = sunFireData.find(item =>
          //     item.reelIndex === 2 && item.slotIndex === 2
          //   );
          //   console.log(prizeData, linkPrizeAmount[8], 'prizeData--22')
          //   if (spriteB22.children.length == 0) {

          //   }
          //   prizeData?.prizeAmount > 0 ? animateSpin8(prizeData?.prizeAmount) : animateSpin8(linkPrizeAmount[8]);
          // }

          // //  22222222
          // if (
          //   reels[3].container.children[0].visible === false &&
          //   reels[3].container.children[0].texture.name === "TT"
          // ) {
          //   spriteA30.y = window.innerWidth > 480 ? app.screen.height / 3.5 : Y1percent * 40;
          //   spriteA30.x = window.innerWidth > 480 ? app.screen.width / 1.6 : X1percent * 68;
          //   spriteAOverlay30.y = window.innerWidth > 480 ? app.screen.height / 3.5 : Y1percent * 41;
          //   spriteAOverlay30.x = window.innerWidth > 480 ? app.screen.width / 1.6 : X1percent * 68;


          //   const prizeData = sunFireData.find(item =>
          //     item.reelIndex === 3 && item.slotIndex === 0
          //   );
          //   console.log(prizeData, linkPrizeAmount[9], 'prizeData--30')
          //   if (spriteB30.children.length == 0) {

          //   }
          //   prizeData?.prizeAmount > 0 ? animateSpin9(prizeData?.prizeAmount) : animateSpin9(linkPrizeAmount[9]);
          // }
          // if (
          //   reels[3].container.children[1].visible === false &&
          //   reels[3].container.children[1].texture.name === "TT"
          // ) {
          //   spriteA31.y = window.innerWidth > 480 ? app.screen.height / 2.05 : Y1percent * 49;
          //   spriteA31.x = window.innerWidth > 480 ? app.screen.width / 1.6 : X1percent * 68;
          //   spriteAOverlay31.y = window.innerWidth > 480 ? app.screen.height / 2.05 : Y1percent * 49.5;
          //   spriteAOverlay31.x = window.innerWidth > 480 ? app.screen.width / 1.6 : X1percent * 68;

          //   const prizeData = sunFireData.find(item =>
          //     item.reelIndex === 3 && item.slotIndex === 1
          //   );
          //   console.log(prizeData, linkPrizeAmount[10], 'prizeData--31')
          //   if (spriteB31.children.length == 0) {

          //   }
          //   prizeData?.prizeAmount > 0 ? animateSpin10(prizeData?.prizeAmount) : animateSpin10(linkPrizeAmount[10]);
          // }
          // if (
          //   reels[3].container.children[2].visible === false &&
          //   reels[3].container.children[2].texture.name === "TT"
          // ) {
          //   spriteA32.y = window.innerWidth > 480 ? app.screen.height / 1.435 : Y1percent * 58;
          //   spriteA32.x = window.innerWidth > 480 ? app.screen.width / 1.6 : X1percent * 68;
          //   spriteAOverlay32.y = window.innerWidth > 480 ? app.screen.height / 1.435 : Y1percent * 57.7;
          //   spriteAOverlay32.x = window.innerWidth > 480 ? app.screen.width / 1.6 : X1percent * 68;

          //   const prizeData = sunFireData.find(item =>
          //     item.reelIndex === 3 && item.slotIndex === 2
          //   );
          //   console.log(prizeData, linkPrizeAmount[11], 'prizeData--32')
          //   if (spriteB32.children.length == 0) {

          //   }
          //   prizeData?.prizeAmount > 0 ? animateSpin11(prizeData?.prizeAmount) : animateSpin11(linkPrizeAmount[11]);
          // }
          // //  333333333333333
          // if (
          //   reels[4].container.children[0].visible === false &&
          //   reels[4].container.children[0].texture.name === "TT"
          // ) {
          //   spriteA40.y = window.innerWidth > 480 ? app.screen.height / 3.5 : Y1percent * 40;
          //   spriteA40.x = window.innerWidth > 480 ? app.screen.width / 1.335 : X1percent * 86;
          //   spriteAOverlay40.y = window.innerWidth > 480 ? app.screen.height / 3.5 : Y1percent * 41;
          //   spriteAOverlay40.x = window.innerWidth > 480 ? app.screen.width / 1.335 : X1percent * 86;

          //   const prizeData = sunFireData.find(item =>
          //     item.reelIndex === 4 && item.slotIndex === 0
          //   );
          //   console.log(prizeData, linkPrizeAmount[12], 'prizeData--40')
          //   if (spriteB40.children.length == 0) {

          //   }
          //   prizeData?.prizeAmount > 0 ? animateSpin12(prizeData?.prizeAmount) : animateSpin12(linkPrizeAmount[12]);
          // }
          // if (
          //   reels[4].container.children[1].visible === false &&
          //   reels[4].container.children[1].texture.name === "TT"
          // ) {
          //   spriteA41.y = window.innerWidth > 480 ? app.screen.height / 2.05 : Y1percent * 49;
          //   spriteA41.x = window.innerWidth > 480 ? app.screen.width / 1.335 : X1percent * 86;
          //   spriteAOverlay41.y = window.innerWidth > 480 ? app.screen.height / 2.05 : Y1percent * 49.5;
          //   spriteAOverlay41.x = window.innerWidth > 480 ? app.screen.width / 1.335 : X1percent * 86;
          //   const prizeData = sunFireData.find(item =>
          //     item.reelIndex === 4 && item.slotIndex === 1
          //   );
          //   console.log(prizeData, linkPrizeAmount[13], 'prizeData--41')
          //   if (spriteB41.children.length == 0) {

          //   }
          //   prizeData?.prizeAmount > 0 ? animateSpin13(prizeData?.prizeAmount) : animateSpin13(linkPrizeAmount[13]);
          // }
          // if (
          //   reels[4].container.children[2].visible === false &&
          //   reels[4].container.children[2].texture.name === "TT"
          // ) {
          //   spriteA42.y = window.innerWidth > 480 ? app.screen.height / 1.435 : Y1percent * 58;
          //   spriteA42.x = window.innerWidth > 480 ? app.screen.width / 1.335 : X1percent * 86;
          //   spriteAOverlay42.y = window.innerWidth > 480 ? app.screen.height / 1.435 : Y1percent * 57.7;
          //   spriteAOverlay42.x = window.innerWidth > 480 ? app.screen.width / 1.335 : X1percent * 86;

          //   const prizeData = sunFireData.find(item =>
          //     item.reelIndex === 4 && item.slotIndex === 2
          //   );
          //   console.log(spriteAOverlay42)
          //   // if(spriteAOverlay42.children.length == 0){
          //   prizeData?.prizeAmount > 0 ? animateSpin14(prizeData?.prizeAmount) : animateSpin14(linkPrizeAmount[14]);
          //   // }
          // }
        }, 1800);
      }

      function showstickyAnimation(value,sunFireData){
        console.log(value,'zzzzzzzzzzzzzzzz')
        switch(value){
          case 1:
            if (reels[0].container.children[0].visible === false && reels[0].container.children[0].texture.name === "TT") {
              spriteA00.y = window.innerWidth > 480 ? app.screen.height / 3.5 : Y1percent * 40;
              spriteA00.x = window.innerWidth > 480 ? app.screen.width / 4 : X1percent * 14;
              spriteAOverlay00.y = window.innerWidth > 480 ? app.screen.height / 3.5 : Y1percent * 41;
              spriteAOverlay00.x = window.innerWidth > 480 ? app.screen.width / 4 : X1percent * 14;
              const prizeData = sunFireData.find(item =>
               item.reelIndex === 0 && item.slotIndex === 0
              );
              prizeData?.prizeAmount > 0 ? animateSpin(prizeData?.prizeAmount) : animateSpin(linkPrizeAmount[0]);
              console.log(reels[0].container.children[0].visible,reels[0].container.children[0].texture.name,prizeData, linkPrizeAmount[0], 'prizeData--00')
            }
            break;
          case 2:
            if (reels[0].container.children[1].visible === false && reels[0].container.children[1].texture.name === "TT") {
              spriteA01.y = window.innerWidth > 480 ? app.screen.height / 2.05 : Y1percent * 49.5;
              spriteA01.x = window.innerWidth > 480 ? app.screen.width / 4 : X1percent * 14;
              spriteAOverlay01.y = window.innerWidth > 480 ? app.screen.height / 2.05 : Y1percent * 49.5;
              spriteAOverlay01.x = window.innerWidth > 480 ? app.screen.width / 4 : X1percent * 14;
              const prizeData = sunFireData.find(item =>
                item.reelIndex === 0 && item.slotIndex === 1
              );
              console.log(prizeData, linkPrizeAmount[1], 'prizeData--01')
              prizeData?.prizeAmount > 0 ? animateSpin1(prizeData?.prizeAmount) : animateSpin1(linkPrizeAmount[1]);
            }
            break;
            case 3:
            if (
              reels[0].container.children[2].visible === false &&
              reels[0].container.children[2].texture.name === "TT"
            ) {
              spriteA02.y = window.innerWidth > 480 ? app.screen.height / 1.435 : Y1percent * 58;
              spriteA02.x = window.innerWidth > 480 ? app.screen.width / 4 : X1percent * 14
              spriteAOverlay02.x = window.innerWidth > 480 ? app.screen.width / 4 : X1percent * 14
              spriteAOverlay02.y = window.innerWidth > 480 ? app.screen.height / 1.435 : Y1percent * 57.7;
              const prizeData = sunFireData.find(item =>
                item.reelIndex === 0 && item.slotIndex === 2
              );
              console.log(prizeData, linkPrizeAmount[2], 'prizeData--02')
              prizeData?.prizeAmount > 0 ? animateSpin2(prizeData?.prizeAmount) : animateSpin2(linkPrizeAmount[2]);
            }
            break;
            case 4:
            //00000000 spriteAOverlay10
            if (
              reels[1].container.children[0].visible === false &&
              reels[1].container.children[0].texture.name === "TT"
            ) {
              spriteA10.y = window.innerWidth > 480 ? app.screen.height / 3.5 : Y1percent * 41;
              spriteA10.x = window.innerWidth > 480 ? app.screen.width / 2.67 : X1percent * 32
              spriteAOverlay10.y = window.innerWidth > 480 ? app.screen.height / 3.5 : Y1percent * 41;
              spriteAOverlay10.x = window.innerWidth > 480 ? app.screen.width / 2.67 : X1percent * 32;
              const prizeData = sunFireData.find(item =>
                item.reelIndex === 1 && item.slotIndex === 0
              );
              console.log(reels[1].container.children[0].visible,reels[1].container.children[0].texture.name,prizeData, linkPrizeAmount[3], 'prizeData--10')
              
              prizeData?.prizeAmount > 0 ? animateSpin3(prizeData?.prizeAmount) : animateSpin3(linkPrizeAmount[3]);
            }
            break;
            case 5:
            if (
              reels[1].container.children[1].visible === false &&
              reels[1].container.children[1].texture.name === "TT"
            ) {
              spriteA11.y = window.innerWidth > 480 ? app.screen.height / 2.05 : Y1percent * 49;
              spriteA11.x = window.innerWidth > 480 ? app.screen.width / 2.67 : X1percent * 32;
              spriteAOverlay11.y = window.innerWidth > 480 ? app.screen.height / 2.05 : Y1percent * 49.5;
              spriteAOverlay11.x = window.innerWidth > 480 ? app.screen.width / 2.67 : X1percent * 32;
              const prizeData = sunFireData.find(item =>
                item.reelIndex === 1 && item.slotIndex === 1
              );
              console.log(prizeData, linkPrizeAmount[4], 'prizeData--11')
              prizeData?.prizeAmount > 0 ? animateSpin4(prizeData?.prizeAmount) : animateSpin4(linkPrizeAmount[4]);
            }
            break;
            case 6:
            if (
              reels[1].container.children[2].visible === false &&
              reels[1].container.children[2].texture.name === "TT"
            ) {
              spriteA12.y = window.innerWidth > 480 ? app.screen.height / 1.435 : Y1percent * 58;
              spriteA12.x = window.innerWidth > 480 ? app.screen.width / 2.67 : X1percent * 32;
              spriteAOverlay12.y = window.innerWidth > 480 ? app.screen.height / 1.435 : Y1percent * 57.7;
              spriteAOverlay12.x = window.innerWidth > 480 ? app.screen.width / 2.67 : X1percent * 32;
  
              const prizeData = sunFireData.find(item =>
                item.reelIndex === 1 && item.slotIndex === 2
             );
              console.log(prizeData, linkPrizeAmount[5], 'prizeData--12')
              prizeData?.prizeAmount > 0 ? animateSpin5(prizeData?.prizeAmount) : animateSpin5(linkPrizeAmount[5]);
            }
            break;
            case 7:
            //  1111111
            if (
              reels[2].container.children[0].visible === false &&
              reels[2].container.children[0].texture.name === "TT"
            ) {
              spriteA20.y = window.innerWidth > 480 ? app.screen.height / 3.5 : Y1percent * 40;
              spriteA20.x = window.innerWidth > 480 ? app.screen.width / 2 : X1percent * 50;
              spriteAOverlay20.y = window.innerWidth > 480 ? app.screen.height / 3.5 : Y1percent * 41;
              spriteAOverlay20.x = window.innerWidth > 480 ? app.screen.width / 2 : X1percent * 50;
  
              const prizeData = sunFireData.find(item =>
                item.reelIndex === 2 && item.slotIndex === 0
              );
              console.log(reels[2].container.children[0].visible, reels[2].container.children[0].texture.name,prizeData, linkPrizeAmount[6], 'prizeData--20')
              prizeData?.prizeAmount > 0 ? animateSpin6(prizeData?.prizeAmount) : animateSpin6(linkPrizeAmount[6]);
            }
            break;
            case 8:
            if (
              reels[2].container.children[1].visible === false &&
              reels[2].container.children[1].texture.name === "TT"
            ) {
              spriteA21.y = window.innerWidth > 480 ? app.screen.height / 2.05 : Y1percent * 49;
              spriteA21.x = window.innerWidth > 480 ? app.screen.width / 2 : X1percent * 50;
              spriteAOverlay21.y = window.innerWidth > 480 ? app.screen.height / 2.05 : Y1percent * 49.5;
              spriteAOverlay21.x = window.innerWidth > 480 ? app.screen.width / 2 : X1percent * 50;
  
              const prizeData = sunFireData.find(item =>
                item.reelIndex === 2 && item.slotIndex === 1
              );
              console.log(prizeData, linkPrizeAmount[7], 'prizeData--21')
              prizeData?.prizeAmount > 0 ? animateSpin7(prizeData?.prizeAmount) : animateSpin7(linkPrizeAmount[7]);
            }
            break;
            case 9:
            if (
              reels[2].container.children[2].visible === false &&
              reels[2].container.children[2].texture.name === "TT"
            ) {
              spriteA22.y = window.innerWidth > 480 ? app.screen.height / 1.435 : Y1percent * 57;
              spriteA22.x = window.innerWidth > 480 ? app.screen.width / 2 : X1percent * 50;
              spriteAOverlay22.y = window.innerWidth > 480 ? app.screen.height / 1.435 : Y1percent * 57.7;
              spriteAOverlay22.x = window.innerWidth > 480 ? app.screen.width / 2 : X1percent * 50;
  
              const prizeData = sunFireData.find(item =>
                item.reelIndex === 2 && item.slotIndex === 2
              );
              console.log(prizeData, linkPrizeAmount[8], 'prizeData--22')
              prizeData?.prizeAmount > 0 ? animateSpin8(prizeData?.prizeAmount) : animateSpin8(linkPrizeAmount[8]);
            }
            break;
            case 10:
            if (
              reels[3].container.children[0].visible === false &&
              reels[3].container.children[0].texture.name === "TT"
            ) {
              spriteA30.y = window.innerWidth > 480 ? app.screen.height / 3.5 : Y1percent * 40;
              spriteA30.x = window.innerWidth > 480 ? app.screen.width / 1.6 : X1percent * 68;
              spriteAOverlay30.y = window.innerWidth > 480 ? app.screen.height / 3.5 : Y1percent * 41;
              spriteAOverlay30.x = window.innerWidth > 480 ? app.screen.width / 1.6 : X1percent * 68;
  
  
              const prizeData = sunFireData.find(item =>
                item.reelIndex === 3 && item.slotIndex === 0
              );
              console.log( reels[3].container.children[0].visible,reels[3].container.children[0].texture.name,prizeData, linkPrizeAmount[9], 'prizeData--30')
              prizeData?.prizeAmount > 0 ? animateSpin9(prizeData?.prizeAmount) : animateSpin9(linkPrizeAmount[9]);
            }
            break;
            case 11:
            if (
              reels[3].container.children[1].visible === false &&
              reels[3].container.children[1].texture.name === "TT"
            ) {
              spriteA31.y = window.innerWidth > 480 ? app.screen.height / 2.05 : Y1percent * 49;
              spriteA31.x = window.innerWidth > 480 ? app.screen.width / 1.6 : X1percent * 68;
              spriteAOverlay31.y = window.innerWidth > 480 ? app.screen.height / 2.05 : Y1percent * 49.5;
              spriteAOverlay31.x = window.innerWidth > 480 ? app.screen.width / 1.6 : X1percent * 68;
  
              const prizeData = sunFireData.find(item =>
                item.reelIndex === 3 && item.slotIndex === 1
              );
              console.log(prizeData, linkPrizeAmount[10], 'prizeData--31')
              prizeData?.prizeAmount > 0 ? animateSpin10(prizeData?.prizeAmount) : animateSpin10(linkPrizeAmount[10]);
            }
            break;
            case 12:
            if (
              reels[3].container.children[2].visible === false &&
              reels[3].container.children[2].texture.name === "TT"
            ) {
              spriteA32.y = window.innerWidth > 480 ? app.screen.height / 1.435 : Y1percent * 58;
              spriteA32.x = window.innerWidth > 480 ? app.screen.width / 1.6 : X1percent * 68;
              spriteAOverlay32.y = window.innerWidth > 480 ? app.screen.height / 1.435 : Y1percent * 57.7;
              spriteAOverlay32.x = window.innerWidth > 480 ? app.screen.width / 1.6 : X1percent * 68;
  
              const prizeData = sunFireData.find(item =>
                item.reelIndex === 3 && item.slotIndex === 2
              );
              console.log(prizeData, linkPrizeAmount[11], 'prizeData--32')
              prizeData?.prizeAmount > 0 ? animateSpin11(prizeData?.prizeAmount) : animateSpin11(linkPrizeAmount[11]);
            }
            break;
            case 13:
            //  333333333333333
            if (
              reels[4].container.children[0].visible === false &&
              reels[4].container.children[0].texture.name === "TT"
            ) {
              spriteA40.y = window.innerWidth > 480 ? app.screen.height / 3.5 : Y1percent * 40;
              spriteA40.x = window.innerWidth > 480 ? app.screen.width / 1.335 : X1percent * 86;
              spriteAOverlay40.y = window.innerWidth > 480 ? app.screen.height / 3.5 : Y1percent * 41;
              spriteAOverlay40.x = window.innerWidth > 480 ? app.screen.width / 1.335 : X1percent * 86;
  
              const prizeData = sunFireData.find(item =>
                item.reelIndex === 4 && item.slotIndex === 0
              );
              console.log(reels[4].container.children[0].visible,reels[4].container.children[0].texture.name,prizeData, linkPrizeAmount[12], 'prizeData--40')
              prizeData?.prizeAmount > 0 ? animateSpin12(prizeData?.prizeAmount) : animateSpin12(linkPrizeAmount[12]);
            }
            break;
            case 14:
            if (
              reels[4].container.children[1].visible === false &&
              reels[4].container.children[1].texture.name === "TT"
            ) {
              spriteA41.y = window.innerWidth > 480 ? app.screen.height / 2.05 : Y1percent * 49;
              spriteA41.x = window.innerWidth > 480 ? app.screen.width / 1.335 : X1percent * 86;
              spriteAOverlay41.y = window.innerWidth > 480 ? app.screen.height / 2.05 : Y1percent * 49.5;
              spriteAOverlay41.x = window.innerWidth > 480 ? app.screen.width / 1.335 : X1percent * 86;
              const prizeData = sunFireData.find(item =>
                item.reelIndex === 4 && item.slotIndex === 1
              );
              console.log(prizeData, linkPrizeAmount[13], 'prizeData--41')
              prizeData?.prizeAmount > 0 ? animateSpin13(prizeData?.prizeAmount) : animateSpin13(linkPrizeAmount[13]);
            }
            break;
            case 15:
            if (
              reels[4].container.children[2].visible === false &&
              reels[4].container.children[2].texture.name === "TT"
            ) {
              spriteA42.y = window.innerWidth > 480 ? app.screen.height / 1.435 : Y1percent * 58;
              spriteA42.x = window.innerWidth > 480 ? app.screen.width / 1.335 : X1percent * 86;
              spriteAOverlay42.y = window.innerWidth > 480 ? app.screen.height / 1.435 : Y1percent * 57.7;
              spriteAOverlay42.x = window.innerWidth > 480 ? app.screen.width / 1.335 : X1percent * 86;
  
              const prizeData = sunFireData.find(item =>
                item.reelIndex === 4 && item.slotIndex === 2
              );
              console.log(spriteAOverlay42)
              // if(spriteAOverlay42.children.length == 0){
              prizeData?.prizeAmount > 0 ? animateSpin14(prizeData?.prizeAmount) : animateSpin14(linkPrizeAmount[14]);
              // }
            }
        }
      }



      // function showStickyScreen() {
      //   stickySpinBg.visible = true;
      //   stickyBackgroundImg.visible = true;
      //   MinorStickyTextFrame.visible = true;
      //   MinorStickyTextFrameBG.visible = true;
      //   MinorStickyTextImg.visible = true;
      //   MajorstickyTextFrame.visible = true;
      //   MajorstickyTextFrameBG.visible = true;
      //   MajorstickyTextImg.visible = true;
      //   GrandstickyTextFrame.visible = true;
      //   GrandstickyTextFrameBG.visible = true;
      //   GrandstickyTextImg.visible = true;
      //   MinistickyTextFrame.visible = true;
      //   MinistickyTextFrameBG.visible = true;
      //   MinistickyTextImg.visible = true;
      //   MinorStickyFrameDesign.visible = true;
      //   GrandStickyFrameDesign.visible = true;
      //   MajorStickyFrameDesign.visible = true;
      //   MiniStickyFrameDesign.visible = true;
      //   stickyGameFrame.visible = true;
      //   stickyMinorText.visible = true;
      //   stickyMiniText.visible = true;
      //   stickySpaceFilledCountContainer.visible = true;
      //   remainingStickyCountainer.visible = true;
      // }
      function showStickyScreen(bool) {
        stickySpinBg.visible = bool;
        stickyBackgroundImg.visible = bool;
        // const MinorContainerSticky = new Container();

        MinorStickyTextFrame.visible = bool;
        MinorStickyTextFrameBG.visible = bool;
        MinorStickyTextImg.visible = bool;
        MinorStickyFrameDesign.visible = bool;
        MinorContainerSticky.addChild(MinorStickyTextFrame)
        MinorContainerSticky.addChild(MinorStickyTextFrameBG)
        MinorContainerSticky.addChild(MinorStickyTextImg)
        MinorContainerSticky.addChild(MinorStickyFrameDesign)
        top.removeChild(stickyMinorText)
        stickyMinorText.visible = bool;
        MinorContainerSticky.addChild(stickyMinorText)
        app.stage.addChild(MinorContainerSticky)
        // const MajorContainerSticky = new Container();
        MajorstickyTextFrame.visible = bool;
        MajorstickyTextFrameBG.visible = bool;
        MajorstickyTextImg.visible = bool;
        MajorStickyFrameDesign.visible = bool;
        MajorContainerSticky.addChild(MajorstickyTextFrame)
        MajorContainerSticky.addChild(MajorstickyTextFrameBG)
        MajorContainerSticky.addChild(MajorstickyTextImg)
        MajorContainerSticky.addChild(MajorStickyFrameDesign)

        top.removeChild(MajorText);
        MajorContainerSticky.addChild(MajorText)
        app.stage.addChild(MajorContainerSticky)
        // const GrandContainerSticky = new Container();
        GrandstickyTextFrame.visible = bool;
        GrandstickyTextFrameBG.visible = bool;
        GrandstickyTextImg.visible = bool;
        GrandStickyFrameDesign.visible = bool;
        GrandContainerSticky.addChild(GrandstickyTextFrame)
        GrandContainerSticky.addChild(GrandstickyTextFrameBG)
        GrandContainerSticky.addChild(GrandstickyTextImg)
        GrandContainerSticky.addChild(GrandStickyFrameDesign)

        top.removeChild(GrandText);
        GrandContainerSticky.addChild(GrandText)
        app.stage.addChild(GrandContainerSticky);
        // const MiniContainerSticky = new Container();
        MinistickyTextFrame.visible = bool;
        MinistickyTextFrameBG.visible = bool;
        MinistickyTextImg.visible = bool;
        MiniStickyFrameDesign.visible = bool;
        MiniContainerSticky.addChild(MinistickyTextFrame)
        MiniContainerSticky.addChild(MinistickyTextFrameBG)
        MiniContainerSticky.addChild(MinistickyTextImg)
        MiniContainerSticky.addChild(MiniStickyFrameDesign)
        top.removeChild(stickyMiniText)
        stickyMiniText.visible = bool;
        MiniContainerSticky.addChild(stickyMiniText)
        app.stage.addChild(MiniContainerSticky);
        app.stage.addChild(linkHitImg)
        stickyGameFrame.visible = bool;
        stickySpaceFilledCountContainer.visible = bool;
        stickySpinBootomTextCount.text = spaceFilledWtihFire;
        if ((window.innerWidth < 480)) {
          stickySpinBootomTextCount.text.length == 2 ? stickySpinBootomTextCount.x = X1percent * 61 : stickySpinBootomTextCount.x = X1percent * 63;
        } else {
          stickySpinBootomTextCount.x = stickySpinBootomTextCount.text.length == 2 ? X1percent * 87.5 : X1percent * 88.2;
        }

        remainingStickyCountainer.visible = bool;
      }
      function hideStickyScreen(bool) {
        removingfunction()
        removeAllSprites()
        bottom.eventMode = "none";
        bottom.cursor = "none";
        InfoImg.eventMode = "none";
        InfoImg.cursor = "none";
        dollarBtn1.eventMode = "none";
        dollarBtn1.cursor = "none";

        app.stage.removeChild(removePy);
        MiniText.visible = bool;
        MinorText.visible = bool;
        BackgroundImg.visible = bool;
        GameFrame.visible = bool;
        GameBG.visible = bool;
        MinorTextFrameBG.visible = bool;
        MinorTextFrame.visible = bool;
        MinorTextImg.visible = bool;
        GrandTextFrame.visible = bool;
        GrandTextFrameBG.visible = bool;
        GrandTextImg.visible = bool;
        MajorTextFrame.visible = bool;
        MajorTextFrameBG.visible = bool;
        MajorTextImg.visible = bool;
        MiniTextFrame.visible = bool;
        MiniTextFrameBG.visible = bool;
        MiniTextImg.visible = bool;
        //stickyMinorText.visible = bool;
        // stickyMiniText.visible = bool;
        FoFImg.visible = bool;

      }


      function removeStickyItems(bool) {
        MinorContainerSticky.removeChild(MinorStickyTextFrame)
        MinorContainerSticky.removeChild(MinorStickyTextFrameBG)
        MinorContainerSticky.removeChild(MinorStickyTextImg)
        MinorContainerSticky.removeChild(MinorStickyFrameDesign)
        MajorContainerSticky.removeChild(MajorstickyTextFrame)
        MajorContainerSticky.removeChild(MajorstickyTextFrameBG)
        MajorContainerSticky.removeChild(MajorstickyTextImg)
        MajorContainerSticky.removeChild(MajorStickyFrameDesign)
        GrandContainerSticky.removeChild(GrandstickyTextFrame)
        GrandContainerSticky.removeChild(GrandstickyTextFrameBG)
        GrandContainerSticky.removeChild(GrandstickyTextImg)
        GrandContainerSticky.removeChild(GrandStickyFrameDesign)
        MiniContainerSticky.removeChild(MinistickyTextFrame)
        MiniContainerSticky.removeChild(MinistickyTextFrameBG)
        MiniContainerSticky.removeChild(MinistickyTextImg)
        MiniContainerSticky.removeChild(MiniStickyFrameDesign)

      }
      //showStickyScreen();
      //hideStickyScreen();
      //trigger sticky spin
      function triggerStikySpin() {
        IsStickeySpinWon = true;
        playLinkHitAnimation()
        showStickyScreen(true);
        hideStickyScreen(false);
        stickyremainingCount.visible = true
        stickyremainingCount.text = remaningStickeySpin;
        setTimeout(() => {
          app.stage.removeChild(linkHitImg)
        }, 2600)

        let stickySpininterval = setInterval(async () => {
          console.log(stickySpaceFilledCountContainer, "1111111111111111111111111111111111111111111111111", remaningStickeySpin)
          // if(stickyCounter > 0){
          if (remaningStickeySpin > 0 && countTTSym < 15) {

            if (countTTSym == 15) {
              clearInterval(stickySpininterval)
              return;
            }
            startPlay()
            await gameSpinApi(false)
            // stickyCounter =  stickyCounter - 1;
            // stickyremainingCount.text = stickyCounter;
            stickyremainingCount.text = remaningStickeySpin;
            //stickySpaceFilledCountContainer.children[2].text = checkFIreIcon;
            stickySpaceFilledCountContainer.children[2].text = spaceFilledWtihFire
            setTimeout(() => {
              imgArrSlot?.forEach((element, index) => {
                //console.log(element,index)
                if (element?.name == "TT") {
                  checkFIreIcon = checkFIreIcon + 1
                  // stickySpinindex.push({
                  //   index: index,
                  //   value: Math.floor(Math.random() * 100) + 1
                  // })
                  // stickyCounter = remaningStickeySpin;
                  stickyremainingCount.text = remaningStickeySpin;
                  //stickySpaceFilledCountContainer.children[2].text = checkFIreIcon
                  stickySpaceFilledCountContainer.children[2].text = spaceFilledWtihFire
                }
              });
            }, 1000)
          } else {
            clearInterval(stickySpininterval)
            hidestickyHeader();
            // showstickyWin();
            stickyremainingCount.text = remaningStickeySpin;
            stickyMinorText.visible = false;
            stickyMiniText.visible = false;
            IsStickeySpinWon = false;
            checkFIreIcon = 0;
            let stickyspinTotalAmount = []
            if (spriteB00.children.length > 0) stickyspinTotalAmount.push(spriteB00.children[0])
            if (spriteB01.children.length > 0) stickyspinTotalAmount.push(spriteB01.children[0])
            if (spriteB02.children.length > 0) stickyspinTotalAmount.push(spriteB02.children[0])
            if (spriteB10.children.length > 0) stickyspinTotalAmount.push(spriteB10.children[0])
            if (spriteB11.children.length > 0) stickyspinTotalAmount.push(spriteB11.children[0])
            if (spriteB12.children.length > 0) stickyspinTotalAmount.push(spriteB12.children[0])
            if (spriteB20.children.length > 0) stickyspinTotalAmount.push(spriteB20.children[0])
            if (spriteB21.children.length > 0) stickyspinTotalAmount.push(spriteB21.children[0])
            if (spriteB22.children.length > 0) stickyspinTotalAmount.push(spriteB22.children[0])
            if (spriteB30.children.length > 0) stickyspinTotalAmount.push(spriteB30.children[0])
            if (spriteB31.children.length > 0) stickyspinTotalAmount.push(spriteB31.children[0])
            if (spriteB32.children.length > 0) stickyspinTotalAmount.push(spriteB32.children[0])
            if (spriteB40.children.length > 0) stickyspinTotalAmount.push(spriteB40.children[0])
            if (spriteB41.children.length > 0) stickyspinTotalAmount.push(spriteB41.children[0])
            if (spriteB42.children.length > 0) stickyspinTotalAmount.push(spriteB42.children[0])
            console.log(stickyspinTotalAmount)
            // const totalStickywinvalue = stickyspinTotalAmount.text.reduce((a,b)=>(a += b), 0)
            // console.log(totalStickywinvalue);

            //sendAllTextToTop(stickyspinTotalAmount)
          }
        }, 5000)

        //         //send all scatter symbol text on top 
        //         let summation = 0;
        // function sendAllTextToTop(ScatterTextArr) {
        //   summation = 0
        //   ScatterTextArr.forEach((el, index) => {
        //       var Initialpos = {x:1,y:1}
        //       Initialpos.x = ScatterTextArr[index].x;
        //       Initialpos.y = ScatterTextArr[index].y;
        //       var Finalpos = { x: X1percent * 50, y: Y1percent * 5 };
        //       var coords1 = { x: Initialpos.x, y: Initialpos.y }; //Finalpos;    
        //       console.log(Initialpos)
        //       let tween = new TWEEN.Tween(coords1)
        //           .to({ x: (Finalpos.x), y: (Finalpos.y) }, 100 * index)
        //           .onUpdate(async () => {
        //             console.log( ScatterTextArr[index].position)
        //               await ScatterTextArr[index].position.set(coords1.x, coords1.y);
        //           })
        //           .onComplete(() => {
        //               //if (ScatterTextArr[index].visible) {
        //               console.log(parseInt(ScatterTextArr[index].text.toString()));
        //                   summation = summation + parseInt(ScatterTextArr[index].text.toString())
        //                   console.log(summation)
        //                  // ScatterTextArr[index].text = summation.toString()
        //                  // ScatterTextArr[index].name = "Sum";
        //              // }
        //              // if (index > 0) {
        //                  // ScatterTextArr[index - 1].visible = false;
        //              // }
        //           })
        //           .start();
        //       app.ticker.add(dt => gameLoop(dt));
        //   })
        // }

        // //function for game loop
        // function gameLoop(dt) {
        //   var dt = app.ticker.elapsedMS / 10;
        //   TWEEN.update(app.ticker.lastTime);
        // }

        // console.log(stickySpinindex, stickyBackgroundImg)


        // const style = new TextStyle({
        //   fontFamily: 'fantasy',
        //   fontSize: 15,
        //   fontWeight: '100',
        //   fill: 0xdddddd, // White color
        //   align: 'center',
        //   letterSpacing: 3,
        //   stroke: { color: "#000", width: 3 },
        //   dropShadow: {
        //     color: "#494949",
        //     // angle: Math.PI / 40,
        //     blur: 1,
        //     distance: 2
        //   },
        // });
        // stickySpinindex.forEach(el => {




        //   let stickyspinValuetext = new Text(el.value, textStyle);
        //   stickyspinValuetext.x = pos[el.index].x
        //   stickyspinValuetext.y = pos[el.index].y
        //   console.log([el.index])
        //   console.log(stickyspinValuetext)
        //   app.stage.addChild(stickyspinValuetext)
        //});
      }

      function hidestickyHeader() {
        gsap.to(MiniContainerSticky, { x: app.stage.width + 100, duration: 3 });
        gsap.to(MajorContainerSticky, { x: -app.stage.width, y: 100, duration: 3 });
        gsap.to(GrandContainerSticky, { x: app.stage.width + 100, y: 100, duration: 3 });
        gsap.to(MinorContainerSticky, {
          x: -app.stage.width,

          duration: 3.5,
          onComplete: () => {
            showstickyWin()
            // Create the second tween after the first one completes
            // gsap.to(container, { x: 200, y: 200, duration: 2 });
          }
        });
      }
      // function showstickyWin(){
      //   console.log(remainingStickyCountainer.children)
      //   remainingStickyCountainer.visible = true
      //   remainingStickyCountainer.children[0].y = Y1percent * 5
      //   remainingStickyCountainer.children[0].height = Y1percent * 15
      //   remainingStickyCountainer.children[1].y = Y1percent * 5
      //   remainingStickyCountainer.children[1].height = Y1percent * 15
      //   remainingStickyCountainer.children[4].visible = true

      // }





      // Very simple tweening utility function. This should be replaced with a proper tweening library in a real product.
      // function showstickyWin(){
      //   console.log(remainingStickyCountainer.children)
      //   remainingStickyCountainer.visible = true

      //   // remainingStickyCountainer.children[0].y = Y1percent * 5
      //   remainingStickyCountainer.children[0].height = Y1percent * 15
      //   // remainingStickyCountainer.children[1].y = Y1percent * 5
      //   remainingStickyCountainer.children[1].height = Y1percent * 15
      //   remainingStickyCountainer.children[4].visible = true
      //    gsap.to(remainingStickyCountainer, {  y: Y1percent , duration: 3 });
      // }


      function showstickyWin() {
        remainingStickyCountainer.y = -100

        remainingStickyCountainer.visible = true

        // remainingStickyCountainer.children[0].y = Y1percent * 5
        remainingStickyCountainer.children[0].height = Y1percent * 15
        remainingStickyCountainer.children[0].y = Y1percent * 2
        remainingStickyCountainer.children[1].y = Y1percent * 2
        remainingStickyCountainer.children[1].height = Y1percent * 15
        remainingStickyCountainer.children[4].visible = true
        stickyremainingText.visible = false;
        stickyremainingCount.visible = false;

        console.log(remainingStickyCountainer)
        //  gsap.to(remainingStickyCountainer, {  y: Y1percent , duration: 3 });
        gsap.to(remainingStickyCountainer, {
          y: Y1percent,
          x: X1percent,
          onStart() {
            Stickywin.visible = true;
          },
          duration: 3.5,
          onComplete: () => {

            stickyfun()
          }
        });
        //  console.log('linkPrizeAmount',linkPrizeAmount,imgArrSlot)

      }



      // function showstickyWin() {
      //   remainingStickyCountainer.y = -100

      //   remainingStickyCountainer.visible = true

      //   // remainingStickyCountainer.children[0].y = Y1percent * 5
      //   remainingStickyCountainer.children[0].height = Y1percent * 15
      //   remainingStickyCountainer.children[1].y = Y1percent * 2
      //   remainingStickyCountainer.children[1].height = Y1percent * 15
      //   remainingStickyCountainer.children[4].visible = true
      //   stickyremainingText.visible = false;
      //   stickyremainingCount.visible = false;
      //   //  gsap.to(remainingStickyCountainer, {  y: Y1percent , duration: 3 });
      //   gsap.to(remainingStickyCountainer, {
      //     y: Y1percent,
      //     onStart() {
      //       Stickywin.visible = true;
      //     },
      //     duration: 3.5,
      //     onComplete: () => {

      //       stickyfun()
      //     }
      //   });
      //   //  console.log('linkPrizeAmount',linkPrizeAmount,imgArrSlot)

      // }
      function stickyfun() {
        var SunArray = []
        var squares = new Container()
        for (let i = 0; i < 10; i++) {
          const square = new PIXI.Graphics();
          square.beginFill(0xff0000);
          square.drawRect(0, 0, 10, 10);
          square.endFill();

          square.x = Math.random() * 50;
          square.y = Math.random() * 50;
          // square.vx = Math.random() * 5 - 4;
          // square.vy = Math.random() * 5 - 4;

          squares.addChild(square);
          // app.stage.addChild(square);
        }

        // var square = new PIXI.Graphics();
        // square.beginFill(0xff0000);
        // square.drawRect(0, 0, 30, 30);
        // square.endFill();
        app.stage.addChild(squares);
        console.log('imgArrSlot', imgArrSlot)
        for (var x = 0; x < imgArrSlot.length; x++) {
          if (imgArrSlot[x].name == 'TT') {
            switch (x) {
              case 0:
                SunArray.push({ img: spriteA00, index: x })
                break;
              case 1:
                SunArray.push({ img: spriteA01, index: x })
                break;
              case 2:
                SunArray.push({ img: spriteA02, index: x })
                break;
              case 3:
                SunArray.push({ img: spriteA10, index: x })
                break;
              case 4:
                SunArray.push({ img: spriteA11, index: x })
                break;
              case 5:
                SunArray.push({ img: spriteA12, index: x })
                break;
              case 6:
                SunArray.push({ img: spriteA20, index: x })
                break;
              case 7:
                SunArray.push({ img: spriteA21, index: x })
                break;
              case 8:
                SunArray.push({ img: spriteA22, index: x })
                break;
              case 9:
                SunArray.push({ img: spriteA30, index: x })
                break;
              case 10:
                SunArray.push({ img: spriteA31, index: x })
                break;
              case 11:
                SunArray.push({ img: spriteA32, index: x })
                break;
              case 12:
                SunArray.push({ img: spriteA40, index: x })
                break;
              case 13:
                SunArray.push({ img: spriteA41, index: x })
                break;
              case 14:
                SunArray.push({ img: spriteA42, index: x })
                break;

            }
          }
        }
        console.log('SunArray', SunArray)

        runflyAnimation(SunArray, SunArray[0].index, 0, squares)
        // gsap.to(square,{ x: stickyspinoverlayFrame.x,y:stickyspinoverlayFrame.y, duration: 1 })
      }
      function runflyAnimation(SunArray, index, val, squares) {
        //linkPrizeAmount
        console.log('SunArray[index]', SunArray, index,linkPrizeAmount)
        squares.x = SunArray[val].img.x
        squares.y = SunArray[val].img.y
        gsap.to(squares, {
          //x: (window.innerWidth > 480) ? stickyspinoverlayFrame.x + 240 : stickyspinoverlayFrame.x + 100,
          //y: stickyspinoverlayFrame.y + 50,
          x: (window.innerWidth > 480) ? stickyspinoverlayFrame.x + (stickyspinoverlayFrame.width / 2) : stickyspinoverlayFrame.x + (stickyspinoverlayFrame.width / 2),
          y: stickyspinoverlayFrame.y + (stickyspinoverlayFrame.height / 2),

          onStart() {

          },
          duration: 2,
          onComplete: async () => {
            if (val !== SunArray.length - 1) {
              console.log('linkPrizeAmount[index]', linkPrizeAmount[index])
              Stickywin.text = ((Number(Stickywin.text)) + Number(linkPrizeAmount[SunArray[val].index]))

              runflyAnimation(SunArray, SunArray[val].index, val + 1, squares)
            }
            else {
              Stickywin.text = ((Number(Stickywin.text))+Number(linkPrizeAmount[SunArray[val].index]))
              app.stage.removeChild(squares);
              // setTimeout(()=>{
              //   UpdateWinValue(Number(Stickywin.text))
              // },1500)
              app.stage.removeChild(squares);
              UpdateWinValue(Number(Stickywin.text))
              backToPreviousLayout()
              console.log('completed')
            }
          }
        });
      }


      async function backToPreviousLayout() {
        setTimeout(() => {
          switchNormalSpin()
          SpinBtn.visible = true;
          SpinBtn2.visible = true;
          IsstickySpin = false;
        }, 5000)
        setTimeout(() => {
          app.stage.removeChild(linkHitImg2)
          if (currentfreeSpinGameLoad > 0) {
            noOfFreeSpinWonHandleGameLoad()
            currentfreeSpinGameLoad = data?.noOfRemaningFreeSpin;
            freeSpinText.text = `${currentfreeSpinGameLoad}`;

          } else {
            setTimeout(() => {
              if (running === false) {
                bottom.eventMode = "static";
                bottom.cursor = "pointer";
              }
            }, 50);
          }
        }, 7000)
        setTimeout(() => {

          var dummuwiningCombination = "CC,EE,WW,CC,CC,WW,HH,WW,EE,CC,JJ,DD,KK,EE,II"
          dummuwiningCombination = dummuwiningCombination.split(",")
          imgArrSlot = [];
          function getImgWithUrl(name) {
            return slotTextures.filter((el, i) => (el.name === name ? el : ""));
          }
          for (let i = 0; i < dummuwiningCombination.length; i++) {
            imgArrSlot.push(getImgWithUrl(dummuwiningCombination[i])[0]);
          }
          top.addChild(MajorText);
          top.addChild(GrandText);
          setTimeout(() => {
            reels[0].container.children[0].texture = imgArrSlot[0];
            reels[0].container.children[1].texture = imgArrSlot[1];
            reels[0].container.children[2].texture = imgArrSlot[2];
            reels[1].container.children[0].texture = imgArrSlot[3];
            reels[1].container.children[1].texture = imgArrSlot[4];
            reels[1].container.children[2].texture = imgArrSlot[5];
            reels[2].container.children[0].texture = imgArrSlot[6];
            reels[2].container.children[1].texture = imgArrSlot[7];
            reels[2].container.children[2].texture = imgArrSlot[8];
            reels[3].container.children[0].texture = imgArrSlot[9];
            reels[3].container.children[1].texture = imgArrSlot[10];
            reels[3].container.children[2].texture = imgArrSlot[11];
            reels[4].container.children[0].texture = imgArrSlot[12];
            reels[4].container.children[1].texture = imgArrSlot[13];
            reels[4].container.children[2].texture = imgArrSlot[14];
          }, 10)
          // playLinkHitAnimation()
          // linkHitImg.animationSpeed = 0.5;
          // linkHitImg.loop = false;
          // app.stage.removeChild(subWinTextvalue)
          // subWinTextvalue.alpha=0



          // switchNormalSpin()
          // app.stage.removeChild(spriteAOverlay00)
          // app.stage.removeChild(spriteAOverlay01)
          // app.stage.removeChild(spriteAOverlay02)
          // spriteAOverlay00.visible = false;
          // spriteAOverlay01.visible = false;
          // spriteAOverlay02.visible = false;
          // spriteAOverlay10.visible = false;
          // spriteAOverlay11.visible = false;
          // spriteAOverlay12.visible = false;
          // spriteAOverlay20.visible = false;
          // spriteAOverlay21.visible = false;
          // spriteAOverlay22.visible = false;
          // spriteAOverlay30.visible = false;
          // spriteAOverlay31.visible = false;
          // spriteAOverlay32.visible = false;
          // spriteAOverlay40.visible = false;
          // spriteAOverlay41.visible = false;
          // spriteAOverlay42.visible = false;
          dollarBtn1.eventMode = "static";
          dollarBtn1.cursor = "pointer";
          InfoImg.eventMode = "static";
          InfoImg.cursor = "pointer";
          bottom.eventMode = "static";
          bottom.cursor = "pointer";

          MiniText.visible = true;
          MinorText.visible = true;
          BackgroundImg.visible = true;
          GameFrame.visible = true;
          GameBG.visible = true;
          MinorTextFrameBG.visible = true;
          MinorTextFrame.visible = true;
          MinorTextImg.visible = true;
          GrandTextFrame.visible = true;
          GrandTextFrameBG.visible = true;
          GrandTextImg.visible = true;
          GrandText.visible = true;
          MajorTextFrame.visible = true;
          MajorTextFrameBG.visible = true;
          MajorTextImg.visible = true;
          MajorText.visible = true;
          MiniTextFrame.visible = true;
          MiniTextFrameBG.visible = true;
          MiniTextImg.visible = true;
          //stickyMinorText.visible = true;
          // stickyMiniText.visible = true;
          FoFImg.visible = true;


          stickySpinBg.visible = false;
          stickyBackgroundImg.visible = false;
          // const MinorContainerSticky = new Container();

          MinorStickyTextFrame.visible = false;
          MinorStickyTextFrameBG.visible = false;
          MinorStickyTextImg.visible = false;
          MinorStickyFrameDesign.visible = false;
          stickyMinorText.visible = false;
          // const MajorContainerSticky = new Container();
          MajorstickyTextFrame.visible = false;
          MajorstickyTextFrameBG.visible = false;
          MajorstickyTextImg.visible = false;
          MajorStickyFrameDesign.visible = false;

          // const GrandContainerSticky = new Container();
          GrandstickyTextFrame.visible = false;
          GrandstickyTextFrameBG.visible = false;
          GrandstickyTextImg.visible = false;
          GrandStickyFrameDesign.visible = false;
          // const MiniContainerSticky = new Container();
          MinistickyTextFrame.visible = false;
          MinistickyTextFrameBG.visible = false;
          MinistickyTextImg.visible = false;
          MiniStickyFrameDesign.visible = false;
          stickyMiniText.visible = false;
          stickyGameFrame.visible = false;
          stickySpaceFilledCountContainer.visible = false;
          remainingStickyCountainer.visible = false;


        }, 5000)
      }





      const tweening = [];

      function tweenTo(
        object,
        property,
        target,
        time,
        easing,
        onchange,
        oncomplete
      ) {
        const tween = {
          object,
          property,
          propertyBeginValue: object[property],
          target,
          easing,
          time,
          change: onchange,
          complete: oncomplete,
          start: Date.now()
        };

        tweening.push(tween);
        return tween;
      }
      // Listen for animate update.

      app.ticker.add(() => {
        const now = Date.now();
        const remove = [];

        for (let i = 0; i < tweening.length; i++) {
          const t = tweening[i];
          const phase = Math.min(1, (now - t.start) / t.time);
          t.object[t.property] = lerp(
            t.propertyBeginValue,
            t.target,
            t.easing(phase)
          );
          if (t.change) t.change(t);
          if (phase === 1) {
            t.object[t.property] = t.target;
            if (t.complete) t.complete(t);
            remove.push(t);
          }
        }
        for (let i = 0; i < remove.length; i++) {
          tweening.splice(tweening.indexOf(remove[i]), 1);
        }
      });


      // Basic lerp funtion.
      function lerp(a1, a2, t) {
        return a1 * (1 - t) + a2 * t;
      }

      // Backout function from tweenjs.
      function backout(amount) {
        return (t) => +t * ((amount + 1) * t + amount) + 1;
      }



      const lightImgBG = new Sprite(
        Texture.from("/assets/Slots/Slot_Sprite/Calavera.png")
      );


      const paylineBG = new Sprite(
        Texture.from("/assets/Tutorials/popUpBackground.png")
      );
      paylineBG.height = app.screen.height / 1.3 - 100;
      paylineBG.width = app.screen.width / 1.5 - 50;
      paylineBG.x = (app.screen.width - paylineBG.width) / 2;
      paylineBG.y = (app.screen.height - paylineBG.height) / 2;
      app.stage.addChild(paylineBG);

      const imagesPerRow = 10; // Number of images per row
      const imgSize = 50; // Size of each image
      const startX = paylineBG.x - 100; // Starting X position
      const rowY = paylineBG.y + 280; // Starting Y position for the first row


      const textOptions = {
        fontFamily: 'fantasy',
        fontSize: 80,
        fontWeight: '300',
        fill: 0xdddddd, // White color
        align: 'center',
        stroke: { color: "#000", width: 5 },
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
      };



      const gameRulesBG = new Sprite(
        Texture.from("/assets/Tutorials/popUpBackground.png")
      );


      const gameRules = [
        "Select credits per line to start betting.",
        "${} Substitute for all symbols except scatter symbols ${} & Symbols.",
        "A player can't get ${} on the first reel on base game.",
        "Can only appear on the second, third, fourth and fifth reel.",
      ]

      gameRulesBG.height = app.screen.height / 1.3 - 100;
      gameRulesBG.width = app.screen.width / 1.5 - 50;
      gameRulesBG.x = (app.screen.width - gameRulesBG.width) / 2;
      gameRulesBG.y = (app.screen.height - gameRulesBG.height) / 2;
      app.stage.addChild(gameRulesBG);



      const gameRuleStyle = {
        fontFamily: 'fantasy',
        fontSize: 60,
        fontWeight: '100',
        fill: 0xdddddd, // White color
        align: 'center',
        stroke: { color: "#000", width: 5 },
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
      };

      const gameRulesText = new Text('Rules', gameRuleStyle);
      gameRulesText.x = (gameRulesBG.width - gameRulesText.width) / 1.15; // X position
      gameRulesText.y = gameRulesBG.height / 2.5; // Y position
      gameRulesText.anchor.set(0.5); // Center the text
      gameRulesBG.addChild(gameRulesText)


      const gameRuleContainer = new Container();
      gameRuleContainer.x = app.screen.height / 8;
      gameRuleContainer.y = app.screen.height / 3;
      gameRulesBG.addChild(gameRuleContainer);


      gameRules.forEach((paragraph, index) => {
        // Create text style
        const style = new TextStyle({
          fontFamily: 'fantasy',
          fontSize: 24,
          fontWeight: '100',
          fill: 0xdddddd, // White color
          align: 'center',
          letterSpacing: 3,
          stroke: { color: "#000", width: 3 },
          dropShadow: {
            color: "#494949",
            // angle: Math.PI / 40,
            blur: 1,
            distance: 2
          },
        });

        // Create text object for each paragraph
        const text = new Text(paragraph.toUpperCase(), style);
        text.anchor.set(0.5, 0);
        text.y = app.screen.width / 1;
        // Position the text object
        text.x = (gameRulesBG.width - text.text.length) / 1.4; // X position
        text.y = index * 34; // Y position, spaced out by 60 pixels

        // Add the text object to the container
        gameRuleContainer.addChild(text);
      });


      const rules2 = [
        "On paid games, all wins begin from the left most reel and pay left to right on adjacent reels,",
        "expect for scatter symbols ${}",
        "Scatter symbols ${} ca't from multiple lines or prizes.",
        "Scatter symbols ${} can only appear once per reel.",
        "Scatter symbols ${} wins are added to the other win lines",
        "All wins show only in highlighted lines, except scatter symbols ${} & symbols ${}",
      ]

      const gameRuleContainer2 = new Container();
      gameRuleContainer2.x = app.screen.height / 8;
      gameRuleContainer2.y = app.screen.height / 2;
      gameRulesBG.addChild(gameRuleContainer2);


      rules2.forEach((paragraph, index) => {
        // Create text style
        const style = new TextStyle({
          fontFamily: 'fantasy',
          fontSize: 24,
          fontWeight: '100',
          fill: 0xdddddd, // White color
          align: 'center',
          letterSpacing: 3,
          stroke: { color: "#000", width: 3 },
          dropShadow: {
            color: "#494949",
            // angle: Math.PI / 40,
            blur: 1,
            distance: 2
          },
        });

        // Create text object for each paragraph
        const text = new Text(paragraph.toUpperCase(), style);
        text.anchor.set(0.5, 0);
        // Position the text object
        text.x = (gameRulesBG.width - text.text.length) / 1.4; // X position
        text.y = index * 34; // Y position, spaced out by 60 pixels

        // Add the text object to the container
        gameRuleContainer2.addChild(text);
      });

      const freeGameBouns = new Sprite(
        Texture.from("/assets/Tutorials/popUpBackground.png")
      );


      const rules1 = [
        "5 Free Games are awareded by 3 or more scatter symbols.",
        `5 ${lightImgBG} Pays bet x 100.`,
        `4 ${lightImgBG} Pays bet x 10.`,
        `3 ${lightImgBG} Pays bet x 2.`,
        "During the free games bonus, all of the symbols in the first & last reels will become wilds.",
        "During the free games bonus, 3 scatter symbols ${} will award 5 additional free games.",
      ]

      freeGameBouns.height = app.screen.height / 1.3 - 100;
      freeGameBouns.width = app.screen.width / 1.5 - 50;
      freeGameBouns.x = (app.screen.width - freeGameBouns.width) / 2;
      freeGameBouns.y = (app.screen.height - freeGameBouns.height) / 2;
      app.stage.addChild(freeGameBouns);



      const freeGameBounsText = {
        fontFamily: 'fantasy',
        fontSize: 40,
        fontWeight: '100',
        fill: '#dda40e', // White color
        align: 'center',
        stroke: { color: "#622e0c", width: 5 },
        dropShadow: {
          color: '#71420a',
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
      };

      const gameBounsText = new Text('Free games bonus'.toUpperCase(), freeGameBounsText);
      gameBounsText.x = (freeGameBouns.width - gameBounsText.width) / .95; // X position
      gameBounsText.y = freeGameBouns.height / 2.5; // Y position
      gameBounsText.anchor.set(0.5); // Center the text
      freeGameBouns.addChild(gameBounsText)


      const firstContainer = new Container();
      firstContainer.y = app.screen.height / 3.3;
      firstContainer.x = app.screen.height / 8;
      freeGameBouns.addChild(firstContainer);


      rules1.forEach((paragraph, index) => {
        // Create text style
        const style = new TextStyle({
          fontFamily: 'fantasy',
          fontSize: 24,
          fontWeight: '100',
          fill: 0xdddddd, // White color
          align: 'center',
          letterSpacing: 3,
          stroke: { color: "#000", width: 3 },
          dropShadow: {
            color: "#494949",
            // angle: Math.PI / 40,
            blur: 1,
            distance: 2
          },
        });

        // Create text object for each paragraph
        const text = new Text(paragraph.toUpperCase(), style);
        text.anchor.set(0.5, 0);
        // Position the text object
        text.x = (freeGameBouns.width - text.text.length) / 1.4; // X position
        text.y = index * 34; // Y position, spaced out by 60 pixels

        // Add the text object to the container
        firstContainer.addChild(text);
      });

      freeGameBouns.visible = false
      paylineBG.visible = false
      // payOutBG.visible = false
      gameRulesBG.visible = false



      const ONoff = new Sprite(
        Texture.from("/assets/Tutorials/Return-Button.png")
      );
      ONoff.height = app.screen.height / 5;
      ONoff.width = app.screen.width / 9;
      ONoff.x = app.screen.width - 1100;
      ONoff.y = (app.screen.height / 2) + 240;

      const prev = new Sprite(
        Texture.from("/assets/Tutorials/Boton-Flecha.png")
      );
      prev.x = app.screen.width - 1280;
      prev.y = (app.screen.height / 2) + 270;
      // prev.y = app.screen.height + 380;  
      prev.height = app.screen.height / 7;
      prev.width = app.screen.height / 7;

      const Next = new Sprite(
        Texture.from("/assets/Tutorials/Boton-Flecha.png")
      );
      Next.x = app.screen.width - 700;
      Next.y = (app.screen.height / 2) + 400;
      // Next.y = app.screen.height + 380;  
      Next.height = app.screen.height / 7;
      Next.width = app.screen.height / 7;
      Next.rotation = Math.PI;
      app.stage.addChild(prev);
      app.stage.addChild(Next);
      app.stage.addChild(ONoff);

      prev.visible = false;
      Next.visible = false;
      ONoff.visible = false;



      InfoImg.eventMode = "static";
      InfoImg.cursor = "pointer";
      InfoImg.addListener("pointerdown", () => {
        setPayoutTable(!isPayoutTable)
      });


      //doller btn container
      // Add play text
      const style = new TextStyle({
        fontFamily: "Arial",
        fontSize: 56,
        fontStyle: "italic",
        fontWeight: "bold",
        fill: { fill },
        stroke: { color: 0x4a1850, width: 5 },
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440
      });

      const style1 = new TextStyle({
        fontFamily: "Arial",
        fontSize: 250,
        fontStyle: "italic",
        fontWeight: "bold",
        fill: 0xffffff,
        stroke: { color: "#008000", width: 5 },
        dropShadow: {
          color: "#008000",
          angle: Math.PI / 6,
          blur: 14,
          distance: 6
        }
      });
      const dollarBg = new Sprite(
        Texture.from(`/assets/Denominations/Denom-Slide.png`)
      );

      const dollarbGText = new Text(`CHOOSE YOUR DENOMINATION`, style1);

      dollarbGText.x = 800;
      dollarbGText.y = 200;

      const dollarText1 = new Text(`${1}\u20B5`, style);
      const dollarText2 = new Text(`${2}\u20B5`, style);
      const dollarText3 = new Text(`${5}\u20B5`, style);
      const dollarText4 = new Text(`${10}\u20B5`, style);
      const dollarText5 = new Text(`${25}\u20B5`, style);

      const dollarBtn1 = new Sprite(
        Texture.from("/assets/Denominations/Denom-Button-1.png")
      );
      dollarBtn1.addChild(dollarText1);
      dollarText1.width = 400;
      dollarText1.height = 300;
      dollarText1.x = 240;
      dollarText1.y = 155;

      const dollarBtn2 = new Sprite(
        Texture.from("/assets/Denominations/Denom-Button-2.png")
      );
      dollarBtn2.addChild(dollarText2);
      dollarText2.width = 400;
      dollarText2.height = 300;
      dollarText2.x = 240;
      dollarText2.y = 155;

      const dollarBtn3 = new Sprite(
        Texture.from("/assets/Denominations/Denom-Button-3.png")
      );
      dollarBtn3.addChild(dollarText3);
      dollarText3.width = 400;
      dollarText3.height = 300;
      dollarText3.x = 240;
      dollarText3.y = 155;

      const dollarBtn4 = new Sprite(
        Texture.from("/assets/Denominations/Denom-Button-4.png")
      );
      dollarBtn4.addChild(dollarText4);
      dollarText4.width = 400;
      dollarText4.height = 300;
      dollarText4.x = 240;
      dollarText4.y = 155;

      const dollarBtn5 = new Sprite(
        Texture.from("/assets/Denominations/Denom-Button-5.png")
      );
      dollarBtn5.addChild(dollarText5);
      dollarText5.width = 400;
      dollarText5.height = 300;
      dollarText5.x = 240;
      dollarText5.y = 155;

      dollarBtn1.width = 900;
      dollarBtn1.height = 520;
      dollarBtn1.x = dollarBg.width / 10;
      dollarBtn1.y = dollarBg.height / 2;
      dollarBtn1.eventMode = "static";
      dollarBtn1.cursor = "pointer";
      dollarBtn1.addEventListener("pointerdown", () => {
        dollarBg.visible = false;
        coinDenonimation = 1;
        BetSubValue.text = `$${(BetSuboutput * coinDenonimation).toFixed(2)}`;
        MinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
        stickyMinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
        MiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(2)}`;
        stickyMiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(2)}`;
        // updateCredits(creditsVal/coinDenonimation);
        updateCredits(Math.floor(creditsVal / coinDenonimation));
        DenominationText.text = `${1}\u20B5`;
        DenominationText.x = (window.innerWidth > 480) ? (app.screen.width - DenominationImg.width) / 8 : DenominationText.x;
        removingfunction()

        winBlackBg.visible = false;
        creditsValue.x = creditsValue.text.length === 3 ? X1percent * 30.8 : creditsValue.text.length === 12 ? X1percent * 27 : creditsValue.text.length === 5 ? X1percent * 30 : creditsValue.text.length === 6 ? X1percent * 29.5 : creditsValue.text.length === 7 ? X1percent * 28.8 : creditsValue.text.length === 8 ? X1percent * 29 : creditsValue.text.length === 9 ? X1percent * 28.3 : creditsValue.text.length === 11 ? X1percent * 27.5 : creditsValue.text.length === 10 ? X1percent * 27.5 : creditsValue.text.length === 2 ? X1percent * 31.5 : X1percent * 32;
        responsiveTextMiniMinor()
      });

      dollarBtn2.width = 900;
      dollarBtn2.height = 520;
      dollarBtn2.x = dollarBg.width / 3.8;
      dollarBtn2.y = dollarBg.height / 2;
      dollarBtn2.eventMode = "static";
      dollarBtn2.cursor = "pointer";
      dollarBtn2.addEventListener("pointerdown", () => {
        dollarBg.visible = false;
        coinDenonimation = 2;
        MinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
        stickyMinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
        MiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(2)}`;
        stickyMiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(2)}`;
        BetSubValue.text = `$${(BetSuboutput * coinDenonimation).toFixed(2)}`;
        DenominationText.text = `${2}\u20B5`;
        // updateCredits(creditsVal/coinDenonimation);
        updateCredits(Math.floor(creditsVal / coinDenonimation));
        DenominationText.x = (window.innerWidth > 480) ? (app.screen.width - DenominationImg.width) / 8 : DenominationText.x;
        removingfunction()


        winBlackBg.visible = false;
        creditsValue.x = creditsValue.text.length === 3 ? X1percent * 30.8 : creditsValue.text.length === 12 ? X1percent * 27 : creditsValue.text.length === 5 ? X1percent * 30 : creditsValue.text.length === 6 ? X1percent * 29.5 : creditsValue.text.length === 7 ? X1percent * 28.8 : creditsValue.text.length === 8 ? X1percent * 29 : creditsValue.text.length === 9 ? X1percent * 28.3 : creditsValue.text.length === 11 ? X1percent * 27.5 : creditsValue.text.length === 10 ? X1percent * 27.5 : creditsValue.text.length === 2 ? X1percent * 31.5 : X1percent * 32;
        responsiveTextMiniMinor()

      });

      dollarBtn3.width = 900;
      dollarBtn3.height = 520;
      dollarBtn3.x = dollarBg.width / 2.35;
      dollarBtn3.y = dollarBg.height / 2;
      dollarBtn3.eventMode = "static";
      dollarBtn3.cursor = "pointer";
      dollarBtn3.addEventListener("pointerdown", () => {
        dollarBg.visible = false;
        coinDenonimation = 5;
        MinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
        stickyMinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
        MiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(2)}`;
        stickyMiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(2)}`;
        BetSubValue.text = `$${(BetSuboutput * coinDenonimation).toFixed(2)}`;
        DenominationText.text = `${5}\u20B5`;
        // updateCredits(creditsVal/coinDenonimation);
        updateCredits(Math.floor(creditsVal / coinDenonimation));
        DenominationText.x = (window.innerWidth > 480) ? (app.screen.width - DenominationImg.width) / 8 : DenominationText.x;
        removingfunction()


        winBlackBg.visible = false;
        creditsValue.x = creditsValue.text.length === 3 ? X1percent * 30.8 : creditsValue.text.length === 12 ? X1percent * 27 : creditsValue.text.length === 5 ? X1percent * 30 : creditsValue.text.length === 6 ? X1percent * 29.5 : creditsValue.text.length === 7 ? X1percent * 28.8 : creditsValue.text.length === 8 ? X1percent * 29 : creditsValue.text.length === 9 ? X1percent * 28.3 : creditsValue.text.length === 11 ? X1percent * 27.5 : creditsValue.text.length === 10 ? X1percent * 27.5 : creditsValue.text.length === 2 ? X1percent * 31.5 : X1percent * 32;
        responsiveTextMiniMinor()

      });

      dollarBtn4.width = 900;
      dollarBtn4.height = 520;
      dollarBtn4.x = dollarBg.width / 1.7;
      dollarBtn4.y = dollarBg.height / 2;
      dollarBtn4.eventMode = "static";
      dollarBtn4.cursor = "pointer";
      dollarBtn4.addEventListener("pointerdown", () => {
        dollarBg.visible = false;
        coinDenonimation = 10;
        MinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
        stickyMinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
        MiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(2)}`;
        stickyMiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(2)}`;
        BetSubValue.text = `$${(BetSuboutput * coinDenonimation).toFixed(2)}`;
        DenominationText.text = `${10}\u20B5`;
        // updateCredits(creditsVal/coinDenonimation);
        updateCredits(Math.floor(creditsVal / coinDenonimation));
        DenominationText.x = (window.innerWidth > 480) ? (app.screen.width - DenominationImg.width) / 8.3 : DenominationText.x;
        removingfunction()


        winBlackBg.visible = false;
        creditsValue.x = creditsValue.text.length === 3 ? X1percent * 30.8 : creditsValue.text.length === 12 ? X1percent * 27 : creditsValue.text.length === 5 ? X1percent * 30 : creditsValue.text.length === 6 ? X1percent * 29.5 : creditsValue.text.length === 7 ? X1percent * 28.8 : creditsValue.text.length === 8 ? X1percent * 29 : creditsValue.text.length === 9 ? X1percent * 28.3 : creditsValue.text.length === 11 ? X1percent * 27.5 : creditsValue.text.length === 10 ? X1percent * 27.5 : creditsValue.text.length === 2 ? X1percent * 31.5 : X1percent * 32;
        responsiveTextMiniMinor()

      });

      dollarBtn5.width = 900;
      dollarBtn5.height = 520;
      dollarBtn5.x = dollarBg.width / 1.33;
      dollarBtn5.y = dollarBg.height / 2;
      dollarBtn5.eventMode = "static";
      dollarBtn5.cursor = "pointer";
      dollarBtn5.addEventListener("pointerdown", () => {
        dollarBg.visible = false;
        coinDenonimation = 25;
        MinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
        stickyMinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
        MiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(2)}`;
        stickyMiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(2)}`;
        BetSubValue.text = `$${(BetSuboutput * coinDenonimation).toFixed(2)}`;
        DenominationText.text = `${25}\u20B5`;
        // updateCredits(creditsVal/coinDenonimation);
        updateCredits(Math.floor(creditsVal / coinDenonimation));
        DenominationText.x = (window.innerWidth > 480) ? (app.screen.width - DenominationImg.width) / 8.8 : DenominationText.x;
        removingfunction()


        winBlackBg.visible = false;
        BetSubValue.x = (app.screen.width - BetSubValue.width) / 1.467;
        creditsValue.x = creditsValue.text.length === 3 ? X1percent * 30.8 : creditsValue.text.length === 12 ? X1percent * 27 : creditsValue.text.length === 5 ? X1percent * 30 : creditsValue.text.length === 6 ? X1percent * 29.5 : creditsValue.text.length === 7 ? X1percent * 28.8 : creditsValue.text.length === 8 ? X1percent * 29 : creditsValue.text.length === 9 ? X1percent * 28.3 : creditsValue.text.length === 11 ? X1percent * 27.5 : creditsValue.text.length === 10 ? X1percent * 27.5 : creditsValue.text.length === 2 ? X1percent * 31.5 : X1percent * 32;
        responsiveTextMiniMinor()

      });


      function responsiveTextMiniMinor() {

        ////console.log(MinorText.text.length, "coinDenonimation", DenominationText.text.length)
        //minor text responsive text
        if (window.innerWidth > 480) {
          if (MinorText.text.length >= 9) {
            (window.innerWidth > 1440 && window.innerWidth < 1366) ? MinorText.x = X1percent * 14 : (window.innerWidth >= 1366 && window.innerWidth < 1600) ? MinorText.x = X1percent * 15 : (window.innerWidth >= 667 && window.innerWidth <= 932) ? MinorText.x = X1percent * 14.5 : MinorText.x = X1percent * 14;
          } else if (MinorText.text.length >= 8) {
            window.innerWidth > 1440 ? MinorText.x = X1percent * 14.5 : (window.innerWidth >= 667 && window.innerWidth <= 932) ? MinorText.x = X1percent * 15 : MinorText.x = X1percent * 15.5;

          } else if (MinorText.text.length >= 7) {
            window.innerWidth > 1440 ? MinorText.x = X1percent * 15 : (window.innerWidth >= 667 && window.innerWidth <= 932) ? MinorText.x = X1percent * 15.5 : MinorText.x = X1percent * 16;

          } else if (MinorText.text.length >= 6) {
            window.innerWidth > 1440 ? MinorText.x = X1percent * 15.4 : MinorText.x = X1percent * 15.5;

          } else if (MinorText.text.length == 5) {
            window.innerWidth > 1440 ? MinorText.x = X1percent * 15 : MinorText.x = X1percent * 15.5;

          }
          else if (MinorText.text.length <= 4) {
            window.innerWidth > 1440 ? MinorText.x = X1percent * 15 : MinorText.x = X1percent * 16;
          }
          //mini text responsive text
          if (MiniText.text.length >= 8) {
            window.innerWidth > 1440 ? MiniText.x = X1percent * 77.5 : (window.innerWidth >= 667 && window.innerWidth <= 932) ? MiniText.x = X1percent * 78.4 : MiniText.x = X1percent * 78.7;

          }
          else if (MiniText.text.length >= 7) {
            window.innerWidth > 1440 ? MiniText.x = X1percent * 78.5 : (window.innerWidth >= 667 && window.innerWidth <= 932) ? MiniText.x = X1percent * 78.6 : MiniText.x = X1percent * 79;
          }
          else if (MiniText.text.length >= 6) {
            window.innerWidth > 1440 ? MiniText.x = X1percent * 79 : (window.innerWidth >= 667 && window.innerWidth <= 932) ? MiniText.x = X1percent * 79.2 : MiniText.x = X1percent * 79.5;

          } else if (MiniText.text.length == 5) {
            window.innerWidth > 1440 ? MiniText.x = X1percent * 78 : MiniText.x = X1percent * 78.5;
          }
          else if (MiniText.text.length == 4) {
            window.innerWidth > 1440 ? MiniText.x = X1percent * 79 : MiniText.x = X1percent * 78.6;
          }
          else if (MiniText.text.length <= 3) {
            window.innerWidth > 1440 ? MiniText.x = X1percent * 79 : MiniText.x = X1percent * 79.2;
          }

          //denomination responsive text
          if (DenominationText.text.length >= 3) {
            window.innerWidth > 1440 ? DenominationText.x = X1percent * 11.2 : (window.innerWidth <= 932 && window.innerWidth > 736) ? DenominationText.x = X1percent * 11 : DenominationText.x = X1percent * 11.5;
          }
          else {
            window.innerWidth > 1440 ? DenominationText.x = X1percent * 11.8 : (window.innerWidth <= 932 && window.innerWidth > 736) ? DenominationText.x = X1percent * 11.5 : DenominationText.x = X1percent * 12;
          }


          if (window.innerWidth >= 932 && window.innerWidth < 1024) {
            console.log("check space filled")
            stickySpinBootomTextCount.text.length == 2 ? stickySpinBootomTextCount.x = X1percent * 87.5 : stickySpinBootomTextCount.x = X1percent * 88;
          }
        } else {
          responsiveLandscap()
        }







      }



      function removingfunction() {
        setTimeout(() => {
          for (let i = 0; i < removePy.length; i++) {
            app.stage.removeChild(removePy[i]);
          }
        }, 0)
        clearInterval(intervalPayline);

       
        removePayLines();
        startCount = 0;
        clearInterval(payLineIntervel);
        popupContainer.visible = false;
        UpdateWinValue(0)
        removeAllSprites();
      }


      function payLineTrueHandle() {

        reels[0].container.children[0].visible = true;
        reels[0].container.children[1].visible = true;
        reels[0].container.children[2].visible = true;
        reels[1].container.children[0].visible = true;
        reels[1].container.children[1].visible = true;
        reels[1].container.children[2].visible = true;
        reels[2].container.children[0].visible = true;
        reels[2].container.children[1].visible = true;
        reels[2].container.children[2].visible = true;
        reels[3].container.children[0].visible = true;
        reels[3].container.children[1].visible = true;
        reels[3].container.children[2].visible = true;
        reels[4].container.children[0].visible = true;
        reels[4].container.children[1].visible = true;
        reels[4].container.children[2].visible = true;

        if (checkFIreIcon < 6) {
          app.stage.removeChild(spriteA00);
          app.stage.removeChild(spriteA01);
          app.stage.removeChild(spriteA02);
          app.stage.removeChild(spriteA10);
          app.stage.removeChild(spriteA11);
          app.stage.removeChild(spriteA12);
          app.stage.removeChild(spriteA20);
          app.stage.removeChild(spriteA21);
          app.stage.removeChild(spriteA22);
          app.stage.removeChild(spriteA30);
          app.stage.removeChild(spriteA31);
          app.stage.removeChild(spriteA32);
          app.stage.removeChild(spriteA40);
          app.stage.removeChild(spriteA41);
          app.stage.removeChild(spriteA42);

          app.stage.removeChild(SunFireText);
        }
      }



      dollarBg.height = app.screen.height / 2.5;
      dollarBg.width = app.screen.width / 2;
      dollarBg.x = app.screen.height / 2;
      dollarBg.y = app.screen.width / 7;
      dollarBg.addChild(dollarbGText);
      dollarBg.addChild(dollarBtn1);
      dollarBg.addChild(dollarBtn2);
      dollarBg.addChild(dollarBtn3);
      dollarBg.addChild(dollarBtn4);
      dollarBg.addChild(dollarBtn5);
      app.stage.addChild(dollarBg);
      dollarBg.visible = false;
      DenominationImg.eventMode = "static";
      DenominationImg.cursor = "pointer";
      DenominationImg.addListener("pointerdown", () => {
        dollarBg.visible = true;
      });

      BetBg.eventMode = "static";
      BetBg.cursor = "pointer";
      var DeltaXBetValue = (window.innerWidth > 480) ? (app.screen.width - BetText.width) / 1.462 : BetValue.x + 50;
      // WinTextvalue.x = BetValue.x-100
      var DeltaXSubValue = (window.innerWidth > 480) ? BetSubValue.x : BetSubValue.x + 60;
      console.log('BetValue.x-10', BetValue.x)
      BetBg.addEventListener("pointerdown", () => {
        winBlackBg.visible = false;
        console.log(betVal, "betValbetVal");
        if (betVal <= 50 || betVal < 150) {
          betVal += 50;
          BetValue.x = (window.innerWidth > 480) ? (app.screen.width - BetText.width) / 1.46 : DeltaXBetValue;
          BetSubValue.x = (app.screen.width - BetText.width) / 1.46;
          responsiveTextMiniMinor()
        } else if (betVal === 150 && betVal < 250) {
          betVal += 100;
          BetValue.x = (window.innerWidth > 480) ? (app.screen.width - BetText.width) / 1.46 : DeltaXBetValue;
          // MinorText.x = (app.screen.width - MinorText.width) / 6.2;
          // BetSubValue.x = (app.screen.width - BetText.width) / 1.485;
          BetSubValue.x = (window.innerWidth > 480) ? BetSubValue.x : DeltaXSubValue;
          responsiveTextMiniMinor()
        } else if (betVal >= 250 && betVal < 500) {
          betVal += 250;
          BetValue.x = (window.innerWidth > 480) ? (app.screen.width - BetText.width) / 1.462 : DeltaXBetValue;
          // MiniText.x = (app.screen.width - GrandTextImg.width) / 1.196;
          // MinorText.x = (app.screen.width - MinorText.width) / 6.2;
          BetSubValue.x = (window.innerWidth > 480) ? (app.screen.width - BetText.width) / 1.465 : DeltaXSubValue;
          responsiveTextMiniMinor()
        } else {
          betVal = 50;
          if (betVal === 50) {
            BetValue.x = (window.innerWidth > 480) ? (app.screen.width - BetText.width) / 1.455 : DeltaXBetValue;
            BetSubValue.x = (window.innerWidth > 480) ? (app.screen.width - BetText.width) / 1.47 : DeltaXSubValue;
            // MiniText.x = (app.screen.width - GrandTextImg.width) / 1.185;
            // MinorText.x = (app.screen.width - MinorText.width) / 6.2;
            responsiveTextMiniMinor()
          }
        }
        MinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
        MiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(2)}`;

        BetValue.text = `${betVal}`;

        let input = parseFloat(betVal / 100);
        BetSuboutput =
          input % 1 === 0
            ? input.toFixed(2).replace(/\.0$/, "")
            : input.toFixed(2);
        BetSubValue.text = `$${(BetSuboutput * coinDenonimation).toFixed(2)}`;
        removingfunction()


        responsiveTextMiniMinor()
      });



      // // Payllines
      function indexVal(index) {
        if (index === 0) {
          return 2;
        }
        if (index === 1) {
          return 1;
        }
        if (index === 2) {
          return 0;
        }
      }
      let payLine;



      var intervalPayline;
      const createSinglePayline = (paylinesNum) => {
        clearInterval(intervalPayline);
        intervalPayline = setInterval(() => {
          console.log("paylinesNum in out", paylinesNum);
          if (paylinesNum?.length > 0) {
            console.log("paylinesNum inside", paylinesNum);
            payLine = new Sprite(Texture.from(`/assets/PayLines/PayLine_${paylinesNum[startCount]}.png`));
            if (window.innerWidth > 480) {
              updatePopupUI(paylinesNum[startCount], winAmountShow[startCount]);
            }
            // if (Number(paylinesNum) !== '' && freeSpin > 0) {
            //   // updatePopupUI(paylinesNum[startCount], winAmountShow[startCount]);
            //   popupContainer.visible = false;
            // }
            // else{
            //   updatePopupUI(paylinesNum[startCount], winAmountShow[startCount]);
            // }
            // comingSpriteAnimation()
            console.log(winAmountShow, "pages", paylinesNum)
            payLineAnimateArr[startCount]?.splice(occurenceArr[startCount])
            payLineAnimateArr[startCount]?.forEach((el, i) => {



              let index = indexVal(el);

              if (
                reels[i]?.container?.children[index]?.texture?.name ===
                "WW"
              ) {
                reels[i].container.children[index].visible = false;
              }
              if (
                reels[i]?.container?.children[index]?.texture?.name ===
                winningSymbolArr[startCount]
              ) {
                let getname = getSpriteSheet(winningSymbolArr[startCount]);
                if (getname !== "PP") {
                  reels[i].container.children[index].visible = false;
                }
              }
            });

            console.log("paylinesNum inside-----", startCount);
            // payLine.width = ((app.screen.width - GameFrame.width) * 3.48) / 2;
            // payLine.height = app.screen.height / 1.62;
            // payLine.x = (app.screen.width - payLine.width) / 2;
            // payLine.y = (app.screen.height - payLine.height) / 2;
            payLine.width = (window.innerWidth > 480) ? ((app.screen.width - GameFrame.width) * 3.48) / 2 : X1percent * 95;
            payLine.height = (window.innerWidth > 480) ? app.screen.height / 1.62 : Y1percent * 28;
            payLine.x = (window.innerWidth > 480) ? (app.screen.width - payLine.width) / 2 : X1percent * 2.5;
            payLine.y = (window.innerWidth > 480) ? (app.screen.height - payLine.height) / 2 : Y1percent * 35;
            app.stage.addChildAt(payLine, 35);
            removePy.push(payLine);
            setTimeout(() => {
              for (let i = 0; i < removePy.length; i++) {
                app.stage.removeChild(removePy[i]);
              }

              if (startCount === paylinesNum?.length - 1) {
                startCount = 0;
                console.log("paylinesNum inside-----0", startCount);
                comingImg();
                removeAllSprites();
              } else {
                startCount++;
                console.log("paylinesNum inside-----++", startCount);
                comingImg();
                removeAllSprites();
              }
            }, 2000);
          } else {
            clearInterval(intervalPayline);
          }
          comingSpriteAnimation()
        }, 2400);
        comingSpriteAnimation()
      };

      function comingImg() {
        console.log('paylinesNum inside-----++', 'aaaaaaaaaa')
        for (let i = 0; i < 5; i++) {
          for (let j = 0; j < 3; j++) {
            if (
              reels[i]?.container?.children[j]?.visible === false &&
              reels[i]?.container?.children[j]?.texture?.name !== "TT"
            ) {
              reels[i].container.children[j].visible = true;
            }
          }
        }
      }

      // function comingSpriteAnimation() {
      //   if (
      //     reels[0].container.children[0].visible === false &&
      //     reels[0].container.children[0].texture.name !== "TT"
      //   ) {
      //     animateSpinSprite(reels[0].container.children[0].texture.name, (window.innerWidth > 480) ? { xvalue: 4, yvalue: 3.5 } : { xvalue: 7.5, yvalue: 2.52 });
      //   }
      //   if (
      //     reels[0].container.children[1].visible === false &&
      //     reels[0].container.children[1].texture.name !== "TT"
      //   ) {

      //     animateSpinSprite(reels[0].container.children[1].texture.name, (window.innerWidth > 480) ? { xvalue: 4, yvalue: 2.05 } : { xvalue: 7.5, yvalue: 2.07 });
      //   }
      //   if (
      //     reels[0].container.children[2].visible === false &&
      //     reels[0].container.children[2].texture.name !== "TT"
      //   ) {

      //     animateSpinSprite(reels[0].container.children[2].texture.name, (window.innerWidth > 480) ? { xvalue: 4, yvalue: 1.435 } : { xvalue: 7.5, yvalue: 1.75 });
      //   }
      //   if (
      //     reels[1].container.children[0].visible === false &&
      //     reels[1].container.children[0].texture.name !== "TT"
      //   ) {

      //     animateSpinSprite(reels[1].container.children[0].texture.name, (window.innerWidth > 480) ? { xvalue: 2.67, yvalue: 3.5 } : { xvalue: 3.2, yvalue: 2.52 });
      //   }
      //   if (
      //     reels[1].container.children[1].visible === false &&
      //     reels[1].container.children[1].texture.name !== "TT"
      //   ) {

      //     animateSpinSprite(reels[1].container.children[1].texture.name, (window.innerWidth > 480) ? { xvalue: 2.67, yvalue: 2.05 } : { xvalue: 3.2, yvalue: 2.07 });
      //   }
      //   if (
      //     reels[1].container.children[2].visible === false &&
      //     reels[1].container.children[2].texture.name !== "TT"
      //   ) {

      //     animateSpinSprite(reels[1].container.children[2].texture.name, (window.innerWidth > 480) ? { xvalue: 2.67, yvalue: 1.435 } : { xvalue: 3.2, yvalue: 1.75 });
      //   }
      //   // y -- 3.5 , 2.05 , 1.435
      //   //x --  4, 2.67, 2, 1.6, 1.335 animateSpinSprite("DD",4,3.5);

      //   if (
      //     reels[2].container.children[0].visible === false &&
      //     reels[2].container.children[0].texture.name !== "TT"
      //   ) {

      //     animateSpinSprite(reels[2].container.children[0].texture.name, (window.innerWidth > 480) ? { xvalue: 2, yvalue: 3.5 } : { xvalue: 2, yvalue: 2.52 });
      //   }
      //   if (
      //     reels[2].container.children[1].visible === false &&
      //     reels[2].container.children[1].texture.name !== "TT"
      //   ) {

      //     animateSpinSprite(reels[2].container.children[1].texture.name, (window.innerWidth > 480) ? { xvalue: 2, yvalue: 2.05 } : { xvalue: 2, yvalue: 2.07 });
      //   }
      //   if (
      //     reels[2].container.children[2].visible === false &&
      //     reels[2].container.children[2].texture.name !== "TT"
      //   ) {

      //     animateSpinSprite(reels[2].container.children[2].texture.name, (window.innerWidth > 480) ? { xvalue: 2, yvalue: 1.435 } : { xvalue: 2, yvalue: 1.75 });
      //   }
      //   // y -- 3.5 , 2.05 , 1.435
      //   //x --  4, 2.67, 2, 1.6, 1.335 animateSpinSprite("DD",4,3.5);

      //   if (
      //     reels[3].container.children[0].visible === false &&
      //     reels[3].container.children[0].texture.name !== "TT"
      //   ) {

      //     animateSpinSprite(reels[3].container.children[0].texture.name, (window.innerWidth > 480) ? { xvalue: 1.6, yvalue: 3.5 } : { xvalue: 1.8, yvalue: 2.52 });
      //   }
      //   if (
      //     reels[3].container.children[1].visible === false &&
      //     reels[3].container.children[1].texture.name !== "TT"
      //   ) {

      //     animateSpinSprite(reels[3].container.children[1].texture.name, (window.innerWidth > 480) ? { xvalue: 1.6, yvalue: 2.05 } : { xvalue: 1.8, yvalue: 2.07 });
      //   }
      //   if (
      //     reels[3].container.children[2].visible === false &&
      //     reels[3].container.children[2].texture.name !== "TT"
      //   ) {
      //     animateSpinSprite(reels[3].container.children[2].texture.name, (window.innerWidth > 480) ? { xvalue: 1.6, yvalue: 1.435 } : { xvalue: 1.8, yvalue: 1.75 });
      //   }
      //   // y -- 3.5 , 2.05 , 1.435
      //   //x --  4, 2.67, 2, 1.6, 1.335 animateSpinSprite("DD",4,3.5);

      //   if (
      //     reels[4].container.children[0].visible === false &&
      //     reels[4].container.children[0].texture.name !== "TT"
      //   ) {

      //     animateSpinSprite(reels[4].container.children[0].texture.name, (window.innerWidth > 480) ? { xvalue: 1.335, yvalue: 3.5 } : { xvalue: 1.2, yvalue: 2.52 });
      //   }
      //   if (
      //     reels[4].container.children[1].visible === false &&
      //     reels[4].container.children[1].texture.name !== "TT"
      //   ) {

      //     animateSpinSprite(reels[4].container.children[1].texture.name, (window.innerWidth > 480) ? { xvalue: 1.335, yvalue: 2.05 } : { xvalue: 1.2, yvalue: 2.07 });
      //   }
      //   if (
      //     reels[4].container.children[2].visible === false &&
      //     reels[4].container.children[2].texture.name !== "TT"
      //   ) {

      //     animateSpinSprite(reels[4].container.children[2].texture.name, (window.innerWidth > 480) ? { xvalue: 1.335, yvalue: 1.435 } : { xvalue: 1.2, yvalue: 1.75 });
      //   }

      // }

      function comingSpriteAnimation() {
        if (
          reels[0].container.children[0].visible === false &&
          reels[0].container.children[0].texture.name !== "TT"
        ) {
           animateSpinSprite(reels[0].container.children[0].texture.name, window.innerWidth > 480 ?  { xvalue: 4, yvalue: 3.5 } : { xvalue: 7.5, yvalue: 2.52 });
          //animateSpinSprite(reels[0].container.children[0].texture.name, 4, 3.5);
        }
        if (
          reels[0].container.children[1].visible === false &&
          reels[0].container.children[1].texture.name !== "TT"
        ) {
           animateSpinSprite(reels[0].container.children[1].texture.name, (window.innerWidth > 480) ? { xvalue: 4, yvalue: 2.05 } : { xvalue: 7.5, yvalue: 2.07 });
          //animateSpinSprite(reels[0].container.children[1].texture.name, 4, 2.05);
        }
        if (
          reels[0].container.children[2].visible === false &&
          reels[0].container.children[2].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[0].container.children[2].texture.name, (window.innerWidth > 480) ? { xvalue: 4, yvalue: 1.435 } : { xvalue: 7.5, yvalue: 1.75 });
          // animateSpinSprite(reels[0].container.children[2].texture.name, 4, 1.435);
        }
        if (
          reels[1].container.children[0].visible === false &&
          reels[1].container.children[0].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[1].container.children[0].texture.name, (window.innerWidth > 480) ? { xvalue: 2.67, yvalue: 3.5 } : { xvalue: 3.2, yvalue: 2.52 });
          // animateSpinSprite(reels[1].container.children[0].texture.name, 2.67, 3.5);
        }
        if (
          reels[1].container.children[1].visible === false &&
          reels[1].container.children[1].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[1].container.children[1].texture.name, (window.innerWidth > 480) ? { xvalue: 2.67, yvalue: 2.05 } : { xvalue: 3.2, yvalue: 2.07 });
          // animateSpinSprite(reels[1].container.children[1].texture.name, 2.67, 2.05);
        }
        if (
          reels[1].container.children[2].visible === false &&
          reels[1].container.children[2].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[1].container.children[2].texture.name, (window.innerWidth > 480) ? { xvalue: 2.67, yvalue: 1.435 } : { xvalue: 3.2, yvalue: 1.75 });
          // animateSpinSprite(reels[1].container.children[2].texture.name, 2.67, 1.435);
        }
        // y -- 3.5 , 2.05 , 1.435
        //x --  4, 2.67, 2, 1.6, 1.335 animateSpinSprite("DD",4,3.5);

        if (
          reels[2].container.children[0].visible === false &&
          reels[2].container.children[0].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[2].container.children[0].texture.name, (window.innerWidth > 480) ? { xvalue: 2, yvalue: 3.5 } : { xvalue: 2, yvalue: 2.52 });
          // animateSpinSprite(reels[2].container.children[0].texture.name, 2, 3.5);
        }
        if (
          reels[2].container.children[1].visible === false &&
          reels[2].container.children[1].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[2].container.children[1].texture.name, (window.innerWidth > 480) ? { xvalue: 2, yvalue: 2.05 } : { xvalue: 2, yvalue: 2.07 });
          // animateSpinSprite(reels[2].container.children[1].texture.name, 2, 2.05);
        }
        if (
          reels[2].container.children[2].visible === false &&
          reels[2].container.children[2].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[2].container.children[2].texture.name, (window.innerWidth > 480) ? { xvalue: 2, yvalue: 1.435 } : { xvalue: 2, yvalue: 1.75 });
          // animateSpinSprite(reels[2].container.children[2].texture.name, 2, 1.435);
        }
        // y -- 3.5 , 2.05 , 1.435
        // x --  4, 2.67, 2, 1.6, 1.335 animateSpinSprite("DD",4,3.5);

        if (
          reels[3].container.children[0].visible === false &&
          reels[3].container.children[0].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[3].container.children[0].texture.name, (window.innerWidth > 480) ? { xvalue: 1.6, yvalue: 3.5 } : { xvalue: 1.5, yvalue: 2.52 });
          // animateSpinSprite(reels[3].container.children[0].texture.name, 1.6, 3.5);
        }
        if (
          reels[3].container.children[1].visible === false &&
          reels[3].container.children[1].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[3].container.children[1].texture.name, (window.innerWidth > 480) ? { xvalue: 1.6, yvalue: 2.05 } : { xvalue: 1.5, yvalue: 2.07 });
          // animateSpinSprite(reels[3].container.children[1].texture.name, 1.6, 2.05);
        }
        if (
          reels[3].container.children[2].visible === false &&
          reels[3].container.children[2].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[3].container.children[2].texture.name, (window.innerWidth > 480) ? { xvalue: 1.6, yvalue: 1.435 } : { xvalue: 1.5, yvalue: 1.75 });
          // animateSpinSprite(reels[3].container.children[2].texture.name, 1.6, 1.435);
        }
        // y -- 3.5 , 2.05 , 1.435
        //x --  4, 2.67, 2, 1.6, 1.335 animateSpinSprite("DD",4,3.5);

        if (
          reels[4].container.children[0].visible === false &&
          reels[4].container.children[0].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[4].container.children[0].texture.name, (window.innerWidth > 480) ? { xvalue: 1.335, yvalue: 3.5 } : { xvalue: 1.15, yvalue: 2.52 });
          // animateSpinSprite(reels[4].container.children[0].texture.name, 1.335, 3.5);
        }
        if (
          reels[4].container.children[1].visible === false &&
          reels[4].container.children[1].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[4].container.children[1].texture.name, (window.innerWidth > 480) ? { xvalue: 1.335, yvalue: 2.05 } : { xvalue: 1.15, yvalue: 2.07 });
          // animateSpinSprite(reels[4].container.children[1].texture.name, 1.335, 2.05);
        }
        if (
          reels[4].container.children[2].visible === false &&
          reels[4].container.children[2].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[4].container.children[2].texture.name, (window.innerWidth > 480) ? { xvalue: 1.335, yvalue: 1.435 } : { xvalue: 1.15, yvalue: 1.75 });
          // animateSpinSprite(reels[4].container.children[2].texture.name, 1.335, 1.435);
        }

      }



      var removePy = [];
      const createLinesBunch_No = (paylinesNum) => {
        console.log('createLinesBunch_No_Called', paylinesNum)

        if (IsstickySpin !== true) {
          setTimeout(() => {
            winBlackBg.visible = true;
            console.log(paylinesNum, "paylineItmsBunch");
            // removePy = [...removePy, ...item];
            console.log(paylinesNum, "paylinesNumsssssssssss");

            paylinesNum.map((item) => {
              payLine = new Sprite(
                Texture.from(`/assets/PayLines/PayLine_${item}.png`)
              );
              payLine.width = (window.innerWidth > 480) ? ((app.screen.width - GameFrame.width) * 3.48) / 2 : X1percent * 95;
              payLine.height = (window.innerWidth > 480) ? app.screen.height / 1.62 : Y1percent * 28;
              payLine.x = (window.innerWidth > 480) ? (app.screen.width - payLine.width) / 2 : X1percent * 2.5;
              payLine.y = (window.innerWidth > 480) ? (app.screen.height - payLine.height) / 2 : Y1percent * 35;

              app.stage.addChildAt(payLine, 30);
              removePy.push(payLine);
            });
            console.log(payLine, "payLinessssssss", app.stage);
          }, 2000);

          setTimeout(() => {
            for (let i = 0; i < removePy.length; i++) {
              // removePy[i].destroy();
              app.stage.removeChild(removePy[i]);
            }
          }, 2500);

          setTimeout(() => {
            createSinglePayline(paylinesNum);
          }, 500);
        } else {
          removePy.push(payLine);
        }
      };

      const removePayLines = () => {
        console.log(removePy, "removePy");
        removePy.visible = false;
        app.stage.removeChild(removePy)
        paylinesNum =[];
      };

      // **************************** History code************************** //



      // Create the pop-up container
      const popupContainer = new PIXI.Container();
      // popupContainer.visible = false;
      app.stage.addChild(popupContainer);

      // Add the pop-up background
      const popupBackground = new PIXI.Graphics();
      popupBackground.x = window.innerWidth > 1440 ? (app.screen.width - popupBackground.width) / 7 : (window.innerWidth > 1366 && window.innerWidth < 1440) ? app.screen.width / 35 : (window.innerWidth > 800 && window.innerWidth < 1367) ? (app.screen.width - popupBackground.width) / 8 : app.screen.width / 50;
      // popupBackground.x = (app.screen.width - popupBackground.width) / 8;
      popupBackground.y = window.innerWidth > 1440 ? (app.screen.height - popupBackground.height) / 2.2 : (window.innerWidth > 1366 && window.innerWidth < 1440) ? (app.screen.height - popupBackground.height) / 2.2 : (window.innerWidth > 800 && window.innerWidth < 1367) ? (app.screen.height - popupBackground.height) / 2.5 : app.screen.height / 2;
      // popupBackground.y = (app.screen.height -popupBackground.height)/2.5 ;
      popupBackground.beginFill('000', 0.4);
      popupBackground.drawRect(0, 0, 100, 100);
      popupBackground.endFill();
      popupContainer.addChild(popupBackground);

      const contentText = new PIXI.Text('Line: 0\nPay: 0', { fontSize: 18, fill: '#b38b00' });
      contentText.x = 15;
      contentText.y = 25;
      popupBackground.addChild(contentText);
      popupContainer.visible = false;
      function updatePopupUI(line, pay) {
        console.log(line,'line---line')
        popupContainer.visible = true;
        contentText.text = `Line: ${line}\nPay:  ${pay}`;
      }



      // Add Button added here
      const historyImage = new Sprite(Texture.from('/assets/Backgrounds/history.png'))
      const historyBtn = new Sprite(Texture.from('/assets/Backgrounds/Hud_tutoriales.png'))
      historyBtn.height = app.screen.height / 7;
      historyBtn.width = app.screen.height / 7;
      historyBtn.y = (app.screen.height - historyBtn.height) / 1.36;
      historyBtn.x = (app.screen.width - historyBtn.width) / 10;
      historyImage.height = app.screen.height / 12;
      historyImage.width = app.screen.height / 12;
      historyImage.y = (app.screen.height - historyImage.height) / 1.39;
      historyImage.x = (app.screen.width - historyImage.width) / 8.9;
      // historyBtn.x = Math.round((bottom.width * 4 - historyBtn.width) / 4.9);
      app.stage.addChild(historyBtn)
      app.stage.addChild(historyImage)
      historyBtn.eventMode = "static";
      historyBtn.cursor = "pointer";
      historyBtn.on('pointerdown', () => {
        setHtmlVisible(!isHtmlVisible); // Toggle HTML visibility
      });

      const spinRemainText = new TextStyle({
        fontSize: window.innerWidth > 1600 ? app.screen.width / 70 : (window.innerWidth > 1366 && window.innerWidth < 1600) ? app.screen.width / 70 : (window.innerWidth > 800 && window.innerWidth < 1367) ? app.screen.width / 70 : window.innerWidth <= 480 ? Y1percent * 2 : app.screen.width / 50,
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: "bolder",
        fill: "#fff",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440
      });


      const spinText = new TextStyle({
        fontSize: window.innerWidth > 1600 ? app.screen.width / 40 : (window.innerWidth > 1366 && window.innerWidth < 1599) ? app.screen.width / 30 : (window.innerWidth > 800 && window.innerWidth < 1367) ? app.screen.width / 30 : window.innerWidth <= 480 ? Y1percent * 8 : app.screen.width / 50,
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: "bolder",
        fill: "#fff",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440
      });


      const freeSpinImg = new Sprite(
        Texture.from("/assets/Backgrounds/Hud_tutoriales.png")
      );

      var freeSpinRemainingText = new Text(`Remaining \n Free Spin`, spinRemainText);
      // freeSpinRemainingText.style.fontSize = 30
      freeSpinRemainingText.x = app.screen.width / 1.33;
      freeSpinRemainingText.y = app.screen.height / 1.115
      app.stage.addChild(freeSpinRemainingText);



      freeSpinImg.scale.set(0.6)
      freeSpinImg.x = window.innerWidth > 1600 ? (app.screen.width - freeSpinImg.width) / 1.12 : (window.innerWidth > 1366 && window.innerWidth < 1599) ? (app.screen.width - freeSpinImg.width) / 1.1 : (window.innerWidth > 800 && window.innerWidth < 1367) ? (app.screen.width - freeSpinImg.width) / 1.07 : app.screen.width / 50;
      // freeSpinImg.x = (app.screen.width - freeSpinImg.width) / 1.12;
      freeSpinImg.y = (app.screen.height - freeSpinImg.height) / 1.02;
      app.stage.addChild(freeSpinImg);
      // const freeSpinText = new Text(`${freeSpin || 0}`, spinText);

      const freeSpinText = new Text(`${freeSpin || 0}`, spinText);
      console.log(freeSpinText.text.length)

      if (freeSpinText.text.length == 2) {
        freeSpinText.x = window.innerWidth > 1600 ? X1percent * 3.5 : (window.innerWidth > 1366 && window.innerWidth < 1599) ? X1percent * 4.3 : (window.innerWidth > 800 && window.innerWidth < 1367) ? (freeSpinImg.width - freeSpinText.width) / 0.8 : app.screen.width / 50;
      } else {
        freeSpinText.x = window.innerWidth > 1600 ? X1percent * 4 : (window.innerWidth > 1366 && window.innerWidth < 1599) ? X1percent * 5 : (window.innerWidth > 800 && window.innerWidth < 1367) ? (freeSpinImg.width - freeSpinText.width) / 1 : app.screen.width / 50;
      }
      // freeSpinText.x = (freeSpinImg.width - freeSpinText.width)/1.1;
      freeSpinText.y = window.innerWidth > 1600 ? (freeSpinImg.height - freeSpinText.height) / 0.85 : (window.innerWidth > 1366 && window.innerWidth < 1599) ? (freeSpinImg.height - freeSpinText.height) / 0.85 : (window.innerWidth > 800 && window.innerWidth < 1367) ? (freeSpinImg.height - freeSpinText.height) / 0.9 : app.screen.width / 50;
      freeSpinImg.addChild(freeSpinText);
      freeSpinImg.visible = false;
      freeSpinRemainingText.visible = false;



      const startFreeSpin = new Sprite(
        Texture.from("/assets/FreeGames/DoradoPressPlay.png")
      );
      startFreeSpin.scale.set(0.12)
      startFreeSpin.x = (app.screen.width - startFreeSpin.width) / 2;
      startFreeSpin.y = (app.screen.height - startFreeSpin.height) / 2;
      app.stage.addChild(startFreeSpin);
      startFreeSpin.visible = false;





      let isRunning = false; // Flag to control the execution of the function
      let freeSpinInterval = null;

      // function start() {

      //   startFreeSpin.visible = false;
      //   freeSpinModalContainer.visible = false;
      //   freeSpinInterval = setInterval(freeSpinHandle, 500);
      // }

      function start() {
        UpdateWinValue(0);
        freeSpinType = true;
        startFreeSpin.visible = false;
        freeSpinModalContainer.visible = false;
        freeSpinInterval = setInterval(freeSpinHandle, 500);
      }



      // freeSpin = 5;
      async function freeSpinHandle() {
        clearInterval(freeSpinInterval);
        if (isRunning) {
          console.log("freeSpinHandle is stopped.");
          return; // Exit the function if isRunning is true
        }
        freeSpin--;
        // freeSpinText.text=freeSpin;
        console.log("Counter:", freeSpin, "winValue", winValue);
        removingfunction()
        payLineTrueHandle()
        startPlay()
        await gameSpinApi(true)

        freeSpinText.text = `${freeSpin}`;
        console.log(freeSpin, 'freeSpin--------------freeSpin123')
        // Check if freeSpin has reached 10

        if (freeSpin < 1) {
          console.log("freeSpin has reached 10. Stopping freeSpinHandle.");
          clearInterval(freeSpinInterval); // Stop the interval
          freeSpinImg.visible = false;
          FoFImg.visible = true;
          freeSpinRemainingText.visible = false;
          freeSpinMusic.pause();
          bottom.eventMode = "static";
          bottom.cursor = "pointer";
          SpinBtn.visible = true;
          // setTimeout(() => {
          //   freeSpinType = false;
          //   alert("you total Win : " + freespinWinvalue)
          // }, 3000)

          return; // Exit the function
        }
        freeSpinInterval = setInterval(freeSpinHandle, 7000);
        console.log('freeSpin444444----', freeSpin, "winCount", winCount)
      }



      startFreeSpin.eventMode = "static";
      startFreeSpin.cursor = "pointer";
      startFreeSpin.addListener("pointerdown", () => {
        start();
      });

      setTimeout(() => {
        if (currentfreeSpinGameLoad > 0) {
          noOfFreeSpinWonHandleGameLoad()
          freeSpinText.text = `${currentfreeSpinGameLoad}`;
        } else {
          setTimeout(() => {
            if (running === false) {
              bottom.eventMode = "static";
              bottom.cursor = "pointer";
            }
          }, 2500);
        }
      }, 500)

      const freeSpinStyle = new TextStyle({
        fontSize: window.innerWidth > 480 ? app.screen.width / 40 : Y1percent * 3,
        // fontStyle: 'italic',
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: "bolder",
        fill: "#fff",
        align: 'center',
        // stroke: { color: 0x4a1850, width: 5 },
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440,
      });

      const freeSpinModalContainer = new Container();
      const freeSpinModalBackground = new Sprite(Texture.from('/assets/MainBG/modalBg.png'));
      freeSpinModalBackground.scale.set(0.6);
      freeSpinModalBackground.x = (app.screen.width - freeSpinModalBackground.width) / 2;
      freeSpinModalBackground.y = (app.screen.height - freeSpinModalBackground.height) / 2;
      freeSpinModalContainer.addChild(freeSpinModalBackground);
      app.stage.addChild(freeSpinModalContainer);


     
      const freeSpinMesg = new Text(`You have ${currentfreeSpinGameLoad} free Spins left. 
        Click on play to consume the remaining free spin.`, {
        font: freeSpinStyle.font,
        fontSize: window.innerWidth > 480 ? Y1percent * 3 : Y1percent * 2,
        fill: freeSpinStyle.fill,
        align: 'center',
        wordWrap: true, // Add this line
        wordWrapWidth: X1percent * 30 // Adjust the width to your liking
      });

      freeSpinMesg.y = Y1percent * 38;
      freeSpinMesg.x = window.innerWidth > 1600 ? X1percent * 35 : (window.innerWidth > 1440 && window.innerWidth <= 1599) ? X1percent * 38 : (window.innerWidth > 1024 && window.innerWidth <= 1366) ? X1percent * 38 : X1percent * 38;
      // freeSpinMesg.x = window.innerWidth > 1440 ? (freeSpinModalBackground.width - freeSpinMesg.width) / 0.1 : (window.innerWidth > 1024 && window.innerWidth <= 1366) ? (freeSpinModalBackground.width - freeSpinMesg.width) / 0.45 : X1percent * 24;
      if (window.innerWidth <= 480) {
        freeSpinMesg.x = X1percent * 15
        freeSpinMesg.style.wordWrapWidth = X1percent * 70
      }
      freeSpinModalContainer.addChild(freeSpinMesg);
      freeSpinModalContainer.visible = false;

      // free Spin win score score
      const freeSpinPopupContainer = new Container();
      const freeSpinPopupBackground = new Sprite(Texture.from('/assets/MainBG/freeWinBg.png'));
      freeSpinPopupBackground.scale.set(0.6);
      freeSpinPopupBackground.width = app.screen.width / 3.5;
      freeSpinPopupBackground.height = app.screen.height / 2.8;
      freeSpinPopupBackground.x = (app.screen.width - freeSpinPopupBackground.width) / 2;
      freeSpinPopupBackground.y = (app.screen.height - freeSpinPopupBackground.height) / 2;
      freeSpinPopupContainer.addChild(freeSpinPopupBackground);
      app.stage.addChild(freeSpinPopupContainer);





      const freeSpinPopupMesg = new Text(`CONGRATULATIONS!`, {
        fontSize: app.screen.width / 60,
        fontFamily: "Arial , roboto, sans-serif",
        fill: freeSpinStyle.fill,
        align: 'center',
        wordWrap: true, // Add this line
        wordWrapWidth: freeSpinPopupBackground.width - 20 // Adjust the width to your liking
      });
      freeSpinPopupBackground.addChild(freeSpinPopupMesg)
      freeSpinPopupMesg.anchor.set(0.5)
      freeSpinPopupMesg.position.set(280, 80)




      const freeSpinWon = new Text(`You Win Free Spin`, {
        fontSize: app.screen.width / 70,
        fontFamily: "Arial , roboto, sans-serif",
        fill: 'orange',
        align: 'center',
        wordWrap: true, // Add this line
        wordWrapWidth: freeSpinPopupBackground.width - 20 // Adjust the width to your liking
      });
      freeSpinPopupBackground.addChild(freeSpinWon)
      freeSpinWon.anchor.set(0.5)
      freeSpinWon.position.set(280, 140)


      const freeSpinReward = new Text(`Your Rewards:`, {
        fontSize: app.screen.width / 90,
        fontFamily: "Arial , roboto, sans-serif",
        fill: '#fff',
        align: 'center',
        wordWrap: true, // Add this line
        wordWrapWidth: freeSpinPopupBackground.width - 20 // Adjust the width to your liking
      });
      freeSpinPopupBackground.addChild(freeSpinReward)
      freeSpinReward.anchor.set(0.5)
      freeSpinReward.position.set(275, 200)



      const freeSpinWinBg = new Sprite(Texture.from('/assets/MainBG/freeSpinWinBg.png'));
      //freeSpinWinBg.scale.set(1.5);
      freeSpinWinBg.width = X1percent * 10;
      freeSpinWinBg.x = X1percent * 45;
      freeSpinWinBg.y = Y1percent * 52;
       freeSpinWinBg.height = (freeSpinPopupBackground.height-freeSpinWinBg.height)/5 ;
      //freeSpinWinBg.position.set((freeSpinPopupBackground.width - freeSpinWinBg.width) / 2, (freeSpinPopupBackground.height - freeSpinWinBg.height) / 1.1);
      //freeSpinPopupBackground.addChild(freeSpinWinBg);
      freeSpinPopupContainer.addChild(freeSpinWinBg)
      const freeSpinCoins = new Sprite(Texture.from('/assets/MainBG/coins.png'));
      freeSpinCoins.scale.set(0.8);
      // freeSpinCoins.width = (freeSpinPopupBackground.width-freeSpinCoins.width)/4 ;
      // freeSpinCoins.height = (freeSpinPopupBackground.height-freeSpinCoins.height)/5 ;
      freeSpinCoins.position.set((freeSpinWinBg.width - freeSpinCoins.width) / 10, (freeSpinWinBg.height - freeSpinCoins.height) / 5);
      freeSpinWinBg.addChild(freeSpinCoins);

      freeSpinCredits = new Text(`${freespinWinvalue}`, {
        fontSize: app.screen.width / 50,
        fontFamily: "Arial , roboto, sans-serif",
        fill: '#fff',
        align: 'center',
        wordWrap: true, // Add this line
        wordWrapWidth: freeSpinPopupBackground.width - 20 // Adjust the width to your liking
      });
      freeSpinWinBg.addChild(freeSpinCredits)
      freeSpinCredits.anchor.set(0.5)
      freeSpinCredits.position.set(90, 30)



      const freeSpinPopupBtn = new Sprite(Texture.from('/assets/MainBG/freeSpinBtn.png'));
      //freeSpinPopupBtn.scale.set(1.5);
      freeSpinPopupBtn.width = (freeSpinPopupBackground.width - freeSpinPopupBtn.width) / 4;
      freeSpinPopupBtn.height = (freeSpinPopupBackground.height - freeSpinPopupBtn.height) / 5;
      //freeSpinPopupBtn.position.set((freeSpinPopupBackground.width - freeSpinPopupBtn.width) / 2, (freeSpinPopupBackground.height - freeSpinPopupBtn.height) / 0.80);
      freeSpinPopupBtn.x = X1percent * 47;
      freeSpinPopupBtn.y = Y1percent * 59;
      //freeSpinPopupBackground.addChild(freeSpinPopupBtn)
      freeSpinPopupContainer.addChild(freeSpinPopupBtn);


      const closeFreeSpinPop = new Text(`OK`, {
        fontSize: app.screen.width / 70,
        fontFamily: "ADLaM Display, sans-serif",
        fill: 'green',
        align: 'center',
        wordWrap: true, // Add this line
        wordWrapWidth: freeSpinPopupBackground.width - 20 // Adjust the width to your liking
      });



      freeSpinPopupBtn.eventMode = "static";
      freeSpinPopupBtn.cursor = "pointer";
      freeSpinPopupBtn.addListener("pointerdown", () => {
        freeSpinPopupContainer.visible = false;
        bottom.eventMode = "static";
        bottom.cursor = "pointer";
        SpinBtn.visible = true;
        SpinBtn2.visible = true;
        winValue = 0;
      });
      freeSpinPopupBtn.addChild(closeFreeSpinPop)
      closeFreeSpinPop.anchor.set(0.5)
      closeFreeSpinPop.position.set(45, 20)
      freeSpinPopupContainer.visible = false;

      // const starGif = new Sprite(Texture.from('/assets/MainBG/stars.gif'));
      // starGif.scale.set(0.6);
      // starGif.width = app.screen.width  / 3.5;
      // starGif.height = app.screen.height  / 2.5;
      // starGif.x = (app.screen.width - starGif.width) / 2;
      // starGif.y = (app.screen.height - starGif.height) / 2;
      // freeSpinPopupContainer.addChild(starGif);




      const playFreeSpin = new Text(`Play`, freeSpinStyle);
      playFreeSpin.y = Y1percent * 55;
      //playFreeSpin.x = 
      playFreeSpin.x = window.innerWidth > 1600 ? X1percent * 47 : (window.innerWidth > 1440 && window.innerWidth <= 1599) ? X1percent * 47 : (window.innerWidth > 1024 && window.innerWidth <= 1366) ? X1percent * 47 : X1percent * 47;
      if (window.innerWidth <= 480) {
        // playFreeSpin.x = X1percent * 0.1
      }
      playFreeSpin.style.fontSize = freeSpinStyle.fontSize
      freeSpinModalContainer.addChild(playFreeSpin)

      playFreeSpin.eventMode = "static";
      playFreeSpin.cursor = "pointer";
      playFreeSpin.addListener("pointerdown", () => {
        start();
      });



      function noOfFreeSpinWonHandleGameLoad() {
        // playFreeSpinAudio()
        freeSpinText.text = `${currentfreeSpin}`;
        FoFImg.visible = false
        freeSpinImg.visible = true;
        // winBlackBg.visible = true;
        freeSpinModalContainer.visible = true;
        freeSpinRemainingText.visible = true;
        bottom.eventMode = "none";
        bottom.cursor = "none";
        SpinBtn.visible = false;
        freeSpinText.text = `${remaingFreeSpin}`;
      }


      function noOfFreeSpinWonHandle() {
        playFreeSpinAudio()
        freeSpinText.text = `${currentfreeSpin}`;
        FoFImg.visible = false
        freeSpinImg.visible = true;
        startFreeSpin.visible = true;
        winBlackBg.visible = true;
        freeSpinRemainingText.visible = true;
        bottom.eventMode = "none";
        bottom.cursor = "none";
        SpinBtn.visible = false;
      }
      // *************** free spin End Code ****************************//
      //responsive for landscap
      function IsMobile() {
        if (navigator.userAgent.match(/Android/i)
          || navigator.userAgent.match(/webOS/i)
          || navigator.userAgent.match(/iPhone/i)
          || navigator.userAgent.match(/iPad/i)
          || navigator.userAgent.match(/iPod/i)
          || navigator.userAgent.match(/BlackBerry/i)
          || navigator.userAgent.match(/Windows Phone/i)) {
          return true;
        } else {
          return false;
        }
      }
      function responsiveLandscap() {
        if (window.innerWidth <= 480) {
          BackgroundImg.visible = false
          BackgroundImgmobile.visible = true;

          ExploradoraSimbolo.visible = true;
          BackgroundImg.visible = false;
          GameFrame.height = Y1percent * 28;
          GameFrame.width = X1percent * 90;
          GameFrame.x = X1percent * 5
          GameFrame.y = Y1percent * 35;

          GameBG.height = Y1percent * 28;
          GameBG.width = X1percent * 90;
          GameBG.x = X1percent * 5
          GameBG.y = Y1percent * 35;

          //sticky 

          stickyGameFrame.height = Y1percent * 30;
          stickyGameFrame.width = X1percent * 98;
          stickyGameFrame.x = X1percent * 1
          stickyGameFrame.y = Y1percent * 34.5;


          //Minor Text Frame Background
          MinorTextFrameBG.height = app.screen.height / 19;
          MinorTextFrameBG.width = X1percent * 23.5;
          MinorTextFrameBG.x = X1percent * 74;
          MinorTextFrameBG.y = Y1percent * 22;

          //Minor Text Frame 
          MinorTextFrame.height = app.screen.height / 16;
          MinorTextFrame.width = X1percent * 23.5;
          MinorTextFrame.x = X1percent * 74;
          MinorTextFrame.y = Y1percent * 21;

          //Minor Text Png
          MinorTextImg.height = app.screen.height / 40;
          MinorTextImg.width = X1percent * 15;
          MinorTextImg.x = X1percent * 78.5;
          MinorTextImg.y = Y1percent * 20;

          // Minor Text 
          MinorText.width = app.screen.width / 8;
          MinorText.height = Y1percent * 2
          MinorText.x = X1percent * 79;
          MinorText.y = Y1percent * 23.5;

          MinorStickyTextFrameBG.height = app.screen.height / 19;
          MinorStickyTextFrameBG.width = X1percent * 25;
          MinorStickyTextFrameBG.x = X1percent * 72;
          MinorStickyTextFrameBG.y = Y1percent * 22;

          //Minor Text Frame 
          MinorStickyTextFrame.height = app.screen.height / 16;
          MinorStickyTextFrame.width = X1percent * 25;
          MinorStickyTextFrame.x = X1percent * 72;
          MinorStickyTextFrame.y = Y1percent * 21;

          //Minor Text Png
          MinorStickyTextImg.height = app.screen.height / 25;
          MinorStickyTextImg.width = X1percent * 25;
          MinorStickyTextImg.x = X1percent * 72;
          MinorStickyTextImg.y = Y1percent * 20;

          // Minor Text 
          MinorText.width = app.screen.width / 8;
          MinorText.height = Y1percent * 2
          MinorText.x = X1percent * 79;
          MinorText.y = Y1percent * 23.5;

          //Minor Text Png imported here
          MinorStickyFrameDesign.height = Y1percent * 6.5;
          MinorStickyFrameDesign.width = app.screen.width / 15;
          MinorStickyFrameDesign.x = X1percent * 72.2;
          MinorStickyFrameDesign.y = Y1percent * 21;

          ////***************   Grand text Section strats   ***************
          //Grand Text Frame background Image 
          GrandTextFrameBG.height = app.screen.height / 16.5;
          GrandTextFrameBG.width = X1percent * 32;
          GrandTextFrameBG.x = X1percent * 2;
          GrandTextFrameBG.y = Y1percent * 7.7;

          //Grand Text Frame 
          GrandTextFrame.height = app.screen.height / 15;
          GrandTextFrame.width = X1percent * 38;
          GrandTextFrame.x = X1percent * 1 - 12;
          GrandTextFrame.y = Y1percent * 7.5;

          //Grand Text Frame imported here
          GrandTextImg.height = app.screen.height / 25;
          GrandTextImg.width = X1percent * 20;
          GrandTextImg.scale.set(0.12)
          GrandTextImg.x = X1percent * 8;
          GrandTextImg.y = Y1percent * 6.5;

          //sticky grand
          GrandstickyTextFrameBG.height = app.screen.height / 14.2;
          GrandstickyTextFrameBG.width = X1percent * 35;
          GrandstickyTextFrameBG.x = X1percent * 2.2;
          GrandstickyTextFrameBG.y = Y1percent * 7.2;

          //Grand sticky Text Frame 
          GrandstickyTextFrame.height = app.screen.height / 13.8;
          GrandstickyTextFrame.width = X1percent * 36;
          GrandstickyTextFrame.x = X1percent * 2;
          GrandstickyTextFrame.y = Y1percent * 7;

          //Grand sticky Text Frame imported here
          GrandstickyTextImg.height = app.screen.height / 20;
          GrandstickyTextImg.width = X1percent * 30;
          GrandstickyTextImg.x = X1percent * 5;
          GrandstickyTextImg.y = Y1percent * 5.5;

          GrandStickyFrameDesign.height = Y1percent * 7.5;
          GrandStickyFrameDesign.width = X1percent * 36.5;
          GrandStickyFrameDesign.x = X1percent * 2;
          GrandStickyFrameDesign.y = Y1percent * 6.7;


          // Add Grand text
          GrandText.width = app.screen.width / 4;
          GrandText.height = Y1percent * 3
          GrandText.x = X1percent * 5;
          GrandText.y = Y1percent * 9;


          ////***************   Major text Section starts   ***************
          //Major Text Frame background Image 
          MajorTextFrameBG.height = app.screen.height / 15;
          MajorTextFrameBG.width = X1percent * 30;
          MajorTextFrameBG.x = X1percent * 67.5;
          MajorTextFrameBG.y = Y1percent * 7.5;
          // MajorTextFrameBG.alpha=0

          //Grand Text Frame
          MajorTextFrame.height = app.screen.height / 14;
          MajorTextFrame.width = X1percent * 30;
          MajorTextFrame.x = X1percent * 67.5;
          MajorTextFrame.y = Y1percent * 7;
          // MajorTextFrame.alpha=0
          //Major Text Frame  
          MajorTextImg.height = Y1percent * 5;
          MajorTextImg.width = X1percent * 20;
          MajorTextImg.scale.set(0.12)
          MajorTextImg.x = X1percent * 75;
          MajorTextImg.y = Y1percent * 6.5;
          // MajorTextImg.alpha=0
          // Add Major text
          //MajorText.height = Y1percent * 5;
          MajorText.width = app.screen.width / 4.2;
          MajorText.height = Y1percent * 3
          MajorText.x = X1percent * 70.1;
          MajorText.y = Y1percent * 9.3;

          // MajorText.alpha=0

          // sticky major 
          //Major Text Frame background Image 
          MajorstickyTextFrameBG.height = app.screen.height / 16.5;
          MajorstickyTextFrameBG.width = X1percent * 30;
          MajorstickyTextFrameBG.x = X1percent * 66;
          MajorstickyTextFrameBG.y = Y1percent * 7.5;
          // MajorTextFrameBG.alpha=0

          //Grand Text Frame
          MajorstickyTextFrame.height = app.screen.height / 14;
          MajorstickyTextFrame.width = X1percent * 32;
          MajorstickyTextFrame.x = X1percent * 66;
          MajorstickyTextFrame.y = Y1percent * 7;
          // MajorTextFrame.alpha=0
          //Major Text Frame  
          MajorstickyTextImg.height = Y1percent * 5;
          MajorstickyTextImg.width = X1percent * 25;
          MajorstickyTextImg.x = X1percent * 70;
          MajorstickyTextImg.y = Y1percent * 5.5;


          MajorStickyFrameDesign.height = Y1percent * 7.1;
          MajorStickyFrameDesign.width = X1percent * 32;
          MajorStickyFrameDesign.x = X1percent * 66;
          MajorStickyFrameDesign.y = Y1percent * 6.8;
          ////***************   Mini text section starts   ***************
          //Mini Text Frame background Image 

          MiniTextFrameBG.height = app.screen.height / 20;
          MiniTextFrameBG.width = X1percent * 21;
          MiniTextFrameBG.x = X1percent * 2;
          MiniTextFrameBG.y = Y1percent * 22;
          // MiniTextFrameBG.alpha=0
          //Mini Text Frame  
          MiniTextFrame.height = app.screen.height / 17;
          MiniTextFrame.width = X1percent * 27;
          MiniTextFrame.x = X1percent * 1 - 10;
          MiniTextFrame.y = Y1percent * 21.4;
          // MiniTextFrame.alpha=0
          //Mini Text Png  
          MiniTextImg.height = app.screen.height / 29;
          MiniTextImg.width = X1percent * 15;
          MiniTextImg.x = X1percent * 5.5;
          MiniTextImg.y = Y1percent * 19.5;


          stickyMiniText.height = Y1percent * 2;
          stickyMiniText.width = app.screen.width / 8;
          stickyMiniText.x = X1percent * 6.5;
          stickyMiniText.y = Y1percent * 23.6;


          stickyMinorText.width = app.screen.width / 8;
          stickyMinorText.height = Y1percent * 2
          stickyMinorText.x = X1percent * 79;
          stickyMinorText.y = Y1percent * 23.5;

          // stickyMiniText.alpha=0

          // sticky mini


          MinistickyTextFrameBG.height = app.screen.height / 19;
          MinistickyTextFrameBG.width = X1percent * 25.5;
          MinistickyTextFrameBG.x = X1percent * 2;
          MinistickyTextFrameBG.y = Y1percent * 22;
          // MiniTextFrameBG.alpha=0
          //Mini Text Frame  
          MinistickyTextFrame.height = app.screen.height / 16.5;
          MinistickyTextFrame.width = X1percent * 27;
          MinistickyTextFrame.x = X1percent * 1;
          MinistickyTextFrame.y = Y1percent * 21.4;
          // MiniTextFrame.alpha=0
          //Mini Text Png  
          MinistickyTextImg.height = app.screen.height / 29;
          MinistickyTextImg.width = X1percent * 20;
          MinistickyTextImg.x = X1percent * 4.5;
          MinistickyTextImg.y = Y1percent * 20.5;

          MiniStickyFrameDesign.height = Y1percent * 6;
          MiniStickyFrameDesign.width = X1percent * 10;
          MiniStickyFrameDesign.x = X1percent * 18;
          MiniStickyFrameDesign.y = Y1percent * 21.5;
          // Add Mini text

          //sticky remianing  count text
          stickyspinoverlayFrame.width = X1percent * 82
          stickyspinoverlayFrame.height = Y1percent * 8;
          stickyspinoverlayFrame.x = X1percent * 10
          stickyspinoverlayFrame.y = Y1percent * 30

          stickyspinBGFrame.width = X1percent * 82
          stickyspinBGFrame.height = Y1percent * 8;
          stickyspinBGFrame.x = X1percent * 9
          stickyspinBGFrame.y = Y1percent * 29

          stickyremainingText.x = X1percent * 18;
          stickyremainingText.y = Y1percent * 31;

          stickyremainingCount.x = X1percent * 72;
          stickyremainingCount.y = Y1percent * 31;





          stickyspinTotalwinImg.width = X1percent * 40
          stickyspinTotalwinImg.height = Y1percent * 6;
          stickyspinTotalwinImg.x = X1percent * 30
          stickyspinTotalwinImg.y = Y1percent * 1

          stickySpinBootomText.x = X1percent * 15
          stickySpinBootomText.y = Y1percent * 92.5
          stickySpinBootomText.style.wordWrapWidth = X1percent * 90;


          stickyspinBottomCount.width = X1percent * 20;
          stickyspinBottomCount.height = X1percent * 20;
          stickyspinBottomCount.x = X1percent * 55;
          stickyspinBottomCount.y = Y1percent * 90;

          stickySpinBootomTextCount.text.length == 2 ? stickySpinBootomTextCount.x = X1percent * 61 : stickySpinBootomTextCount.x = X1percent * 63;
          stickySpinBootomTextCount.y = Y1percent * 92;

          MiniText.width = app.screen.width / 8;
          MiniText.height = Y1percent * 2
          MiniText.x = (app.screen.width - GrandTextImg.width) / 12;
          MiniText.y = Y1percent * 23.5;
          // MiniText.alpha=0

          //spinbutton button
          SpinBtn2.height = Y1percent * 5;
          SpinBtn2.width = X1percent * 25;
          SpinBtn2.y = Y1percent * 64;
          SpinBtn2.x = X1percent * 37.5;

          //spinbutton button
          SpinBtn.height = Y1percent * 5;
          SpinBtn.width = X1percent * 25;
          SpinBtn.y = Y1percent * 64;
          SpinBtn.x = X1percent * 37.5;

          //history button
          historyBtn.height = Y1percent * 8;
          historyBtn.width = Y1percent * 8;
          historyBtn.y = Y1percent * 82;
          historyBtn.x = X1percent * 14;

          //history button
          historyImage.height = Y1percent * 5;
          historyImage.width = Y1percent * 5;
          historyImage.y = Y1percent * 83.3;
          historyImage.x = X1percent * 17;

          //infor button 
          InfoImg.height = Y1percent * 6;
          InfoImg.width = X1percent * 25;
          InfoImg.x = X1percent * 38;
          InfoImg.y = Y1percent * 83;

          //infor button text
          InfoText.height = Y1percent * 4;
          InfoText.width = X1percent * 12;
          InfoText.x = X1percent * 44.5;
          InfoText.y = Y1percent * 83.5;

          //denomination btn
          DenominationImg.height = Y1percent * 8;
          DenominationImg.width = Y1percent * 8;
          DenominationImg.x = X1percent * 70;
          DenominationImg.y = Y1percent * 82;

          //denomination text
          DenominationText.x = X1percent * 76;
          DenominationText.y = Y1percent * 84.3;
          DenominationText.height = Y1percent * 2.5;
          DenominationText.width = X1percent * 5;

          //credit box responsive
          CreditsBg.height = Y1percent * 10;
          CreditsBg.width = X1percent * 35;
          CreditsBg.x = X1percent * 5;
          CreditsBg.y = Y1percent * 70;

          CreditText.height = Y1percent * 3;
          CreditText.width = X1percent * 18;
          CreditText.x = X1percent * 14.5;
          CreditText.y = Y1percent * 69;
          CreditText.style.stroke = { color: "#000", width: 2 };

          creditsValue.height = Y1percent * 3.5;
          creditsValue.width = creditsValue.text.length == 1 ? X1percent * 10 : X1percent * 22;
          creditsValue.x = creditsValue.text.length === 3 ? X1percent * 10 : creditsValue.text.length === 12 ? 10 : creditsValue.text.length === 5 ? X1percent * 10 : creditsValue.text.length === 6 ? X1percent * 12 : creditsValue.text.length === 7 ? X1percent * 12 : creditsValue.text.length === 8 ? X1percent * 10 : creditsValue.text.length === 9 ? X1percent * 12 : creditsValue.text.length === 11 ? X1percent * 10 : creditsValue.text.length === 10 ? X1percent * 10 : creditsValue.text.length === 2 ? X1percent * 15 : X1percent * 18;          creditsValue.y = Y1percent * 72;
          creditsValue.style.stroke = { color: "#000", width: 1 };

          creditsSubValue.height = Y1percent * 3;
          creditsSubValue.width = X1percent * 18;
          creditsSubValue.x = X1percent * 14;
          creditsSubValue.y = Y1percent * 75.5;
          creditsSubValue.style.stroke = { color: "#000", width: 1 };

          //win box responsive
          WinBg.height = Y1percent * 10;
          WinBg.width = X1percent * 25;
          WinBg.x = X1percent * 43;
          WinBg.y = Y1percent * 70;

          WinText.height = Y1percent * 4;
          WinText.width = X1percent * 12;
          WinText.x = X1percent * 50;
          WinText.y = Y1percent * 68.5;
          //WinText.style.stroke = { color: "#000", width: 2 }
          //WinText.style.dropShadow = { color: 0x000000, angle: Math.PI / 6, blur: 2, distance: 6}

          WinTextvalue.height = Y1percent * 3.5;
          WinTextvalue.width = X1percent * 5;
          WinTextvalue.x = winValue === 0 ? X1percent * 52.5 :  X1percent * 52;
          WinTextvalue.y = Y1percent * 72;
          WinTextvalue.style.fontSize = Y1percent * 1

          // WinTextvalue.style.stroke = { color: "#000", width: 2 }
          // WinTextvalue.style.dropShadow = { color: 0x000000, angle: Math.PI / 6, blur: 1, distance: 2}


          subWinTextvalue.height = Y1percent * 3.2;
          subWinTextvalue.width = X1percent * 12;
          subWinTextvalue.x = X1percent * 50;
          subWinTextvalue.y = Y1percent * 75;

          //Bet box
          BetBg.height = Y1percent * 10;
          BetBg.width = X1percent * 23;
          BetBg.x = X1percent * 72;
          BetBg.y = Y1percent * 70;

          BetText.height = Y1percent * 2.5;
          BetText.width = X1percent * 13;
          BetText.x = X1percent * 77.5;
          BetText.y = Y1percent * 69;

          BetValue.height = Y1percent * 2.5;
          BetValue.width = X1percent * 10;
          BetValue.x = X1percent * 79;
          BetValue.y = Y1percent * 72;

          BetSubValue.height = Y1percent * 2.5;
          BetSubValue.width = X1percent * 10;
          BetSubValue.x = X1percent * 78;
          BetSubValue.y = Y1percent * 75;

          //flame of fortune logo
          FoFImg.height = Y1percent * 8;
          FoFImg.width = X1percent * 80;
          FoFImg.x = X1percent * 10;
          FoFImg.y = Y1percent * 92;

          //denomination box
          dollarBg.height = Y1percent * 30;
          dollarBg.width = X1percent * 90;
          dollarBg.x = X1percent * 5;
          dollarBg.y = Y1percent * 30;

          freeSpinImg.x = X1percent * 55
          freeSpinImg.y = Y1percent * 90
          freeSpinImg.width = X1percent * 20
          freeSpinImg.height = X1percent * 20
          freeSpinText.x = X1percent * 16
          if(window.innerHeight < 700){
            freeSpinText.y = Y1percent * 8;
            freeSpinText.x = X1percent * 20
          }else{
            freeSpinText.y = Y1percent * 4
          }
          freeSpinText.y = Y1percent * 4
          freeSpinRemainingText.x = X1percent * 25
          freeSpinRemainingText.y = Y1percent * 92
          // popup after free spin popup
          freeSpinPopupBackground.width = X1percent * 80;
          freeSpinPopupBackground.height = Y1percent * 30;
          freeSpinPopupBackground.x = X1percent * 10;
          freeSpinPopupBackground.y = Y1percent * 35;

          freeSpinPopupMesg.style.fontSize = Y1percent * 3

          freeSpinWon.style.fontSize = Y1percent * 2.5
          freeSpinWon.style.wordWrapWidth = X1percent * 50

          freeSpinReward.style.fontSize = Y1percent * 2
          freeSpinReward.style.wordWrapWidth = X1percent * 50

          freeSpinWinBg.width = X1percent * 30;
          freeSpinWinBg.x = X1percent * 35;
          freeSpinWinBg.y = Y1percent * 51;

          freeSpinCredits.style.fontSize = Y1percent * 2.5

          freeSpinPopupBtn.width = X1percent * 25;
          freeSpinPopupBtn.x = X1percent * 37.5
          freeSpinPopupBtn.y = Y1percent * 57;

          closeFreeSpinPop.style.fontSize = Y1percent * 1.5
          
          // modalContent for credit value less then 50
          modalContainer.x = X1percent * 0;
          modalContainer.height = Y1percent * 30;
          modalContainer.width = X1percent * 75;
          modalContainer.y = Y1percent * 35;

          modalBackground.width = X1percent * 60;
          modalBackground.height = Y1percent * 40;
          modalBackground.x = X1percent * 5;
          modalBackground.y = Y1percent * 0;

          modalContent.style.fontSize = Y1percent * 1.8;
          modalContent.x = X1percent * -15;
          modalContent.style.wordWrapWidth = X1percent * 50;

        }
        if(window.innerWidth > 480 && window.innerWidth <= 932 && window.innerHeight <= 440){

          // popup after free spin popup
          freeSpinPopupBackground.width = X1percent * 40;
          freeSpinPopupBackground.height = Y1percent * 60;
          freeSpinPopupBackground.x = X1percent * 30;
          freeSpinPopupBackground.y = Y1percent * 20;

          freeSpinPopupMesg.style.fontSize = Y1percent * 7

          freeSpinWon.style.fontSize = Y1percent * 5
          freeSpinWon.style.wordWrapWidth = X1percent * 50

          freeSpinReward.style.fontSize = Y1percent * 5
          freeSpinReward.style.wordWrapWidth = X1percent * 50

          freeSpinWinBg.width = X1percent * 15
          freeSpinWinBg.height = Y1percent * 12
          freeSpinWinBg.x = X1percent * 42.5
          freeSpinWinBg.y = Y1percent * 53

          freeSpinCredits.style.fontSize = Y1percent * 5

          freeSpinPopupBtn.width = X1percent * 10;
          freeSpinPopupBtn.height = Y1percent * 10;
          freeSpinPopupBtn.x = X1percent * 45
          freeSpinPopupBtn.y = Y1percent * 68;

          closeFreeSpinPop.style.fontSize = Y1percent * 4

          // modalContent for credit value less then 50
          // modalContainer.x = X1percent * 0;
          // modalContainer.height = Y1percent * 30;
          // modalContainer.width = X1percent * 75;
          // modalContainer.y = Y1percent * 35;

          modalBackground.width = X1percent * 60;
          modalBackground.height = Y1percent * 40;
          modalBackground.x = X1percent * 0;
          modalBackground.y = Y1percent * 0;

           //modalContent.style.fontSize = Y1percent * 1.8;
           modalContent.x = X1percent * -5;
           modalContent.style.wordWrapWidth = X1percent * 50;
        }

      }
      responsiveLandscap();


      // const squares = [];

      // for (let i = 0; i < 10; i++) {
      //   const square = new PIXI.Graphics();
      //   square.beginFill(0xff0000);
      //   square.drawRect(0, 0, 50, 50);
      //   square.endFill();

      //   square.x = Math.random() * 800;
      //   square.y = Math.random() * 600;
      //   square.vx = Math.random() * 5 - 2.5;
      //   square.vy = Math.random() * 5 - 2.5;

      //   squares.push(square);
      //   app.stage.addChild(square);
      // }

    })();






  }, []);

  const [loder, setLoder] = useState(false);
  const [show, setShow] = useState(false);


  useEffect(() => {
    // Function to simulate progress increment
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress >= 99) {
          clearInterval(interval); // Stop at 99
          setShow(localStorage.getItem("showModal"));
          if (prevProgress === 100) {
            return 100;
          }

          return 99;
        }
        return prevProgress + 1; // Increase progress by 1
      });
    }, 100); // Increment every 100ms


    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <>

      <div>
        <Preloader bgcolor='#19991d' progress={progress} height='10' loader={loder} logo={Foff} ></Preloader>
      </div>


      <div style={{ position: 'relative' }}>
        <div ref={pixiContainer} />
        {isHtmlVisible && (
          <div
            style={{
              position: 'absolute',
              top: '0px',
              left: '0px',
              color: 'black',
              backgroundColor: '#27272733',
              padding: '10px',
              borderRadius: '5px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
            }}
          >
            <GameHistoryTable onClick={() => setHtmlVisible(false)} />
          </div>
        )}
      </div>

      <div style={{ position: 'relative' }}>
        <div ref={pixiContainer} />
        {isPayoutTable && (
          <div className="gameInfoContainer"
            style={{
              position: 'absolute',
              top: '0px',
              left: '0px',
              color: 'black',
              backgroundColor: '#272727cc',
              borderRadius: '5px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
              height: '100vh',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden'
            }}
          >
            <SliderItems onClick={() => setPayoutTable(false)} />
          </div>
        )}
      </div>


      <div style={{ position: 'relative' }}>
        <div ref={pixiContainer} />
        {isSessionExpired && (
          <div className="sessionExpired"
            style={{
              position: 'absolute',
              top: '0px',
              left: '0px',
              color: 'black',
              backgroundColor: '#000',
              borderRadius: '5px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
              height: '100vh',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden'
            }}
          >

            <SessionExpired onClick={() => {
              setSessionExpired(false)
              localStorage.removeItem("token");
              localStorage.removeItem("playerId");
              navigate("/")
            }} />
          </div>
        )}
      </div>
    </>
  );
};

export default Slot;



