import axios from 'axios';
import React, { useState, useCallback, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import styled from "styled-components";
import closeBtn from "../assets/close.png";
import { RingLoader } from 'react-spinners';
import moment from 'moment';

// customs
const indices = [0, 3, 6, 9, 12, 1, 4, 7, 10, 13, 2, 5, 8, 11, 14];
const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const customStyles = {
  rows: {
    style: {
      minHeight: "52px",
    },
  },
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "bold",
      paddingLeft: "22px",
      FontStyle: "normal",
      justifyContent: "left",
      fontFamily: "'Nunito', sans-serif",
    },
  },
  cells: {
    style: {
      fontSize: "16px",
      fontWeight: "normal",
      paddingLeft: "22px",
      justifyContent: "left",
      fontFamily: "'Nunito', sans-serif",
    },
  },
};



const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filtrar por guía"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton onClick={onClear}>X</ClearButton>
  </>
);
export default (props) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [playerId, setPlayerId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [playerData, setPlayerData] = useState([]);
  const [reelComb,setReelComb]=useState([]);

  useEffect(() => {
    handlePlayerIdChange()
    getHistoryData();
    
  }, []);

  const token = localStorage.getItem("token");
  console.log(token, 'gamepage  ++ token')

  async function getHistoryData() {
    try {
      const response = await axios?.post(`${process.env.REACT_APP_API_URL}/GamePlay/PlayerSpinHistory?playerId=${playersId}`, false,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      const playerHistoryData = response?.data?.resultData;
      console.log(response?.data?.resultData, 'responseHist')
      setPlayerData(playerHistoryData)
      setReelComb(playerData?.winningCombination?.split(","));
      setIsLoading(false)

    } catch (error) {
      console.log(error.message)
    }
  }

let img = "/assets/Slots/Slot_Sprite/A_royal_dorado.png";

function imgHandle(img){
if(img === "HH"){
  return "/assets/Slots/Slot_Sprite/A_royal_dorado.png";
}
if(img === "KK"){
  return "/assets/Slots/Slot_Sprite/J_royal_dorado.png";
}
if(img === "II"){
  return "/assets/Slots/Slot_Sprite/K_royal_dorado.png";
}
if(img === "FF"){
  return "/assets/Slots/Slot_Sprite/AntorchaA.png";
}
if(img === "WW"){
  return "/assets/Slots/Slot_Sprite/Calavera.png";
}
if(img === "CC"){
  return "/assets/Slots/Slot_Sprite/Exploradora_Simbolo_2.png";
}
if(img === "DD"){
  return "/assets/Slots/Slot_Sprite/gema.png";
}
if(img === "GG"){
  return "/assets/Slots/Slot_Sprite/Moneda.png";
}
if(img === "SS"){
  return "/assets/Slots/Slot_Sprite/Piramide.png";
}
if(img === "JJ"){
  return "/assets/Slots/Slot_Sprite/Q_royal_dorado.png";
}
if(img === "EE"){
  return "/assets/Slots/Slot_Sprite/quetzal.png";
}
if(img === "TT"){
  return "/assets/Slots/Slot_Sprite/SunFire.png";
}

}



function convertUTCDateToArray(utcDateString) {
  // Create a Date object from the UTC date string
  var local = moment.utc(utcDateString).local().format();
   console.log(local, "- UTC now to local"); 
  // Create a Date object from the UTC date string
  const utcDate = new Date(local);

  
  // Extract components
  const year = utcDate.getFullYear();
  const month = utcDate.getMonth() + 1; // Months are zero-indexed
  const day = utcDate.getDate();
  const hours = utcDate.getHours();
  const minutes = utcDate.getMinutes();
  const seconds = utcDate.getSeconds();

function formatTimeUnit(unit) {
return String(unit).padStart(2, '0');
}

const formattedHours = formatTimeUnit(hours);
const formattedMinutes = formatTimeUnit(minutes);
const formattedSeconds = formatTimeUnit(seconds);
let curTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
let curDate = `${month}/${day}/${year}`;
  // Return as an array
  
  return (
    <>
    <p>Date: {curDate} </p>
    <p>Time: {curTime}</p>
    </>
  );
}


//  {convertUTCDateToArray(convertUTCDateToLocalDate(playerData.date))}

  console.log(playerData, 'playerDatasssssss')
  const columns = [
    {
      name: "Spin ID, Date & Time",
      sortable: true,
      minWidth: '180px',
      selector: (playerData) => {
        return(
        <>
        <div title={`${playerData.spinDetailsId}`}><span style={{fontWeight:'800', color:'#ca8c0f'}}>{playerData.spinDetailsId}</span> </div>
        <div title={`${playerData.date}`}>{convertUTCDateToArray(playerData.date)}</div>
        </>
        )
      }
    },
    {
      name: "Coin Denomination",
      sortable: true,
      minWidth: '165px',
      style:{
        display: 'flex',
        flexWrap:'wrap'
      },
      selector: (playerData) => <div title={`${playerData.coinDenomation}`}>{playerData.coinDenomation*100}/${playerData.coinDenomation}</div>
    },
    {
      name: "No. of Paylines",
      sortable: true,
      minWidth: '120px',
      selector: (playerData) => <div title={`${playerData.noOfPaylinePlayed}`}>{playerData.noOfPaylinePlayed}</div>
    },
    {
      name: "Bet Multiplier",
      sortable: true,
      minWidth: '130px',
      style:{
        display: 'flex',
        flexWrap:'wrap'
      },
      selector: (playerData) => <div title={`${playerData.betValue}`}>{playerData.betValue}</div>
    },
    {
      name: "Total Bet Amount",
      sortable: true,
      minWidth: '140px',
      style:{
        display: 'flex',
        flexWrap:'wrap'
      },
      selector: (playerData) => <div title={`${playerData.betMoney}`}>{playerData.betMoney/(playerData.coinDenomation*100)}/${(playerData.betMoney/100).toFixed(2)}</div>
    },
    {
      name: "Spin Type",
      sortable: true,
      maxWidth: '120px',
      selector: (playerData) => <div title={`${playerData.isFreeSpinWon}`}>{playerData.isFreeSpinWon === true ? 'Free' : 'Normal'}</div>
    },
    {
      name: "Previous Bal($)",
      sortable: true,
      minWidth: '140px',
      selector: (playerData) => <div title={`${playerData.playerBeforeBetBalance}`}>{playerData.playerBeforeBetBalance}/<br/>${playerData.playerBeforeBetBalance/100}</div>,
    },
    {
      name: "Previous Denom. Bal($)",
      sortable: true,
      minWidth: '140px',
      selector: (playerData) => <div title={`${playerData.playerBeforeBetBalance}`}>{parseInt((playerData.playerBeforeBetBalance/(playerData.coinDenomation*100)))}/<br/>${playerData.playerBeforeBetBalance/100}</div>,
      // selector: (playerData) => <div title={`${playerData.playerBeforeBetBalance}`}>{Math.floor(playerData.playerBeforeBetBalance/(playerData.coinDenomation*100))}/<br/>${playerData.playerBeforeBetBalance/100}</div>,
    },
    // {
    //   name: "Result (Loss($)/Win($))",
    //   sortable: true,
    //   minWidth: '200px',
    //   selector: (playerData) => <div title={`${playerData.playerBeforeBetBalance}`}><p><span style={{fontWeight:'800', color:'#ca8c0f'}}>Bet:</span>{playerData.betMoney}</p><p>
    //     <span style={{fontWeight:'800', color:'#ca8c0f'}}>Result:</span>{playerData.playerCurrentBalance-playerData.playerBeforeBetBalance} ({playerData.playerCurrentBalance > playerData.playerBeforeBetBalance ? 'Win' : 'Loss'}) </p></div>,
    // },
    // {
    //   name: "Result (Loss($)/Win($))",
    //   sortable: true,
    //   minWidth: '180px',
    //   selector: (playerData) => <div title={`${playerData.playerBeforeBetBalance}`}><p style={{width:'100%'}}><span style={{fontWeight:'800', color:'#ca8c0f'}}>Bet: </span>{playerData.isFreeSpinWon === true ? "0" : (playerData.betMoney/(playerData.coinDenomation*100))}</p>
    //   <p style={{width:'100%'}}><span style={{fontWeight:'800', color:'#ca8c0f'}}>Win: </span> { playerData.isFreeSpinWon === true ? (playerData.winValue) : playerData.betMoney + (playerData.playerCurrentBalance-playerData.playerBeforeBetBalance)}</p>
    //   <p style={{width:'100%'}}><span style={{fontWeight:'800', color:'#ca8c0f'}}>Result: </span>{(playerData.isFreeSpinWon === true ? `${playerData.winValue}` : (playerData.playerCurrentBalance > playerData.playerBeforeBetBalance) ? `${playerData.playerCurrentBalance - playerData.playerBeforeBetBalance}(Win)` : `${playerData.playerCurrentBalance - playerData.playerBeforeBetBalance}(Loss)`)} </p></div>,
    // },
    // {
    //   name: "Result (Loss($)/Win($))",
    //   sortable: true,
    //   minWidth: '200px',
    //   selector: (playerData) => <div title={`${playerData.playerBeforeBetBalance}`}><p><span style={{fontWeight:'800', color:'#ca8c0f'}}>Bet: </span>{playerData.isFreeSpinWon === true ? "0" : (playerData.betMoney/(playerData.coinDenomation*100))}<br/><span style={{fontWeight:'800', color:'#ca8c0f'}}>Win: </span> { playerData.isFreeSpinWon === true ? (playerData.playerCurrentBalance-playerData.playerBeforeBetBalance) : playerData.betMoney + (playerData.playerCurrentBalance-playerData.playerBeforeBetBalance)}</p>
    //   <p><span style={{fontWeight:'800', color:'#ca8c0f'}}>Result: </span>{(playerData.isFreeSpinWon === true ? (playerData.playerCurrentBalance-playerData.playerBeforeBetBalance) :
    //    playerData.betMoney + (playerData.playerCurrentBalance-playerData.playerBeforeBetBalance))-(playerData.betMoney/(playerData.coinDenomation*100))} ({
    //     playerData.playerCurrentBalance < playerData.playerBeforeBetBalance ? 'Loss' : ' Win'}) </p></div>,
    // },
    {
      name: "Result (Loss($)/Win($))",
      sortable: true,
      minWidth: '200px',
      selector: (playerData) => <div title={`${playerData.playerBeforeBetBalance}`}><p><span style={{fontWeight:'800', color:'#ca8c0f'}}>Bet: </span>{playerData.isFreeSpinWon === true ? "0" : (playerData.betMoney/(playerData.coinDenomation*100))}</p>
      <p><span style={{fontWeight:'800', color:'#ca8c0f'}}>Win: </span> { playerData.isFreeSpinWon === true ? (playerData.winValue) : playerData.betMoney + (playerData.playerCurrentBalance-playerData.playerBeforeBetBalance)}</p>
      <p><span style={{fontWeight:'800', color:'#ca8c0f'}}>Result: 
      </span>{(playerData.isFreeSpinWon === true ? `${playerData.winValue} ${playerData.winValue > 0 ?  'Win' : ''}`
      : `${(playerData.betMoney + (playerData.playerCurrentBalance-playerData.playerBeforeBetBalance))-(playerData.betMoney/(playerData.coinDenomation*100))} ${((playerData.betMoney + (playerData.playerCurrentBalance-playerData.playerBeforeBetBalance)) > (playerData.betMoney/(playerData.coinDenomation*100))) ? 'Win' : ' Loss'}` 
      
      )} 
        </p></div>,
    },


    {
      name: "Current Bal($)",
      sortable: true,
      minWidth: '140px',
      selector: (playerData) => <div title={`${playerData.playerCurrentBalance}`}>{Math.floor(playerData.playerCurrentBalance)}/<br/>${((playerData.playerCurrentBalance)/100).toFixed(2)}</div>,
      // selector: (playerData) => <div title={`${playerData.playerCurrentBalance}`}>{Math.floor(((playerData.playerCurrentBalance/(playerData.coinDenomation*100))-(playerData.betMoney)))+((playerData.betMoney + (playerData.playerCurrentBalance-playerData.playerBeforeBetBalance))-(playerData.betMoney/(playerData.coinDenomation*100)))}/<br/>${(((playerData.playerBeforeBetBalance/(playerData.coinDenomation*100))-(playerData.betMoney))/100).toFixed(2)}</div>,
      // selector: (playerData) => <div title={`${playerData.playerCurrentBalance}`}>{Math.floor((playerData.playerBeforeBetBalance/(playerData.coinDenomation*100))-(playerData.betMoney))}/<br/>${(((playerData.playerBeforeBetBalance/(playerData.coinDenomation*100))-(playerData.betMoney))/100).toFixed(2)}</div>,
      // selector: (playerData) => <div title={`${playerData.playerCurrentBalance}`}>{playerData.playerBeforeBetBalance + (playerData.betMoney + (playerData.playerCurrentBalance-playerData.playerBeforeBetBalance))-(playerData.betMoney/(playerData.coinDenomation*100))}/<br/>${(playerData.playerBeforeBetBalance + (playerData.betMoney + (playerData.playerCurrentBalance-playerData.playerBeforeBetBalance))-(playerData.betMoney/(playerData.coinDenomation*100)))/100}</div>,
    },
    // {
    //   name: "Current Bal($)",
    //   sortable: true,
    //   minWidth: '120px',
    //   selector: (playerData) => <div title={`${playerData.playerCurrentBalance}`}>{playerData.playerCurrentBalance}/<br/>${playerData.playerCurrentBalance/100}</div>,
    // },
    {
      name: "Current Denom. Bal($)",
      sortable: true,
      minWidth: '140px',
      selector: (playerData) => <div title={`${playerData.playerBeforeBetBalance}`}>{parseInt(((playerData.playerBeforeBetBalance/(playerData.coinDenomation*100))-(playerData.betMoney/(playerData.coinDenomation*100))+ (playerData.betMoney + (playerData.playerCurrentBalance-playerData.playerBeforeBetBalance))))}/<br/>${(((playerData.playerCurrentBalance)/(playerData.coinDenomation*100)))}</div>,
      // selector: (playerData) => <div title={`${playerData.playerBeforeBetBalance}`}>{parseInt(((playerData.playerCurrentBalance)/(playerData.coinDenomation*100))+(playerData.betMoney + (playerData.playerCurrentBalance-playerData.playerBeforeBetBalance)))}/<br/>${(((playerData.playerCurrentBalance)/(playerData.coinDenomation*100)))}</div>,
      // selector: (playerData) => <div title={`${playerData.playerCurrentBalance}`}>{Math.floor((playerData.playerBeforeBetBalance + (playerData.betMoney + (playerData.playerCurrentBalance-playerData.playerBeforeBetBalance))/(playerData.coinDenomation*100))-(playerData.betMoney/(playerData.coinDenomation*100)))}/<br/>${(((playerData.playerBeforeBetBalance + (playerData.betMoney + (playerData.playerCurrentBalance-playerData.playerBeforeBetBalance))-(playerData.betMoney/(playerData.coinDenomation*100)))/(playerData.coinDenomation*100))*5)/100}</div>,
    },
    // {
    //   name: "Current Denom. Bal($)",
    //   sortable: true,
    //   minWidth: '140px',
    //   selector: (playerData) => <div title={`${playerData.playerCurrentBalance}`}>{Math.floor(playerData.playerCurrentBalance/(playerData.coinDenomation*100))}/<br/>${playerData.playerCurrentBalance/100}</div>,
    // },
    {
      name: "Reel Combination",
      sortable: true,
      minWidth: '200px',
      style:{
        display: 'flex',
        flexWrap:'wrap'
      },
      

      selector: (playerData) => <div className='grid-container' title={`${playerData.winningCombination}`}>{ indices.map(index => <img className='grid-item' src={imgHandle(playerData?.winningCombination?.split(",")[index])} width={'30px'} alt={playerData?.winningCombination?.split(",")[index]} />)}
       
        </div>
    },
    {
      name: "Winning Payline",
      sortable: true,
      minWidth: '150px',
      selector: (playerData) => <div title={`${playerData.winningPayline}`}>{playerData.winningPayline}</div>
    },
    // {
    //   name: "Winning Symbols",
    //   sortable: true,
    //   minWidth: '160px',
    //   selector: (playerData) => <div title={`${playerData.winningSymbol}`}> {playerData?.winningSymbol?.split(",")?.map((el)=><img src={imgHandle(el)} width={'30px'} alt={el} />)}</div>
    // },
    
  ];

  createTheme('solarized', {
    text: {
      primary: '#fff',
      secondary: '#fff',
    },
    background: {
      default: '#00000066',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#4a4a4a',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const playersId = localStorage.getItem('playerId')
  console.log('playerId', playersId)
  const handlePlayerIdChange = (event) => {
    setPlayerId(playersId);
  };

  //   console.log(data, 'dataaa')
  useEffect(() => {
    console.log("state", selectedRows);
  }, [selectedRows]);

  const handleChange = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  return (
    <>
    <div className='playerHistoryPage'>
      {playerData.length > 0 ? <RingLoader className='loader' style={{display:'none'}} /> :  <RingLoader className='loader' />}
    {/* <RingLoader className='loader' /> */}

      {/* <h4 style={{textAlign:'center',}}>Player history</h4> */}
      <div style={{ width: '90%', margin: '0 auto', border: '1px solid #ffb700', padding: '8px', background: '#69696994', borderRadius: '10px', position:'relative' }}>
      <button style={{background:'none', border:'none', position: 'absolute', zIndex:'9', top:'-20px', right:'-20px', cursor:'pointer'}} onClick={props.onClick}><img style={{height:'40px', width:'40px'}} src={closeBtn} alt=""/></button>
       
       
        <DataTable
          title="Player Game History"
          columns={columns}
          className="testing"
          data={playerData}
          pagination
          customStyles={customStyles}
          highlightOnHover
          pointerOnHover
          progressPending={isLoading}
          theme="solarized"
          // sortIcon={<ArrowDownward />}
          />
      </div>
    </div>
          </>
  );
};
